import create from "zustand";

import iNotify, {
    SnackbarOptions,
    SnackbarType,
    SnackbarUserOptions,
} from "./iNotify";

/**
 * Usage:
 * - Make sure the component <Snackbar /> is set somewhere in the Template or in a parent of your page/component.
 * - import and register this store in the component you want to show the notification
 *
 *  const {confirm, notify} = useSnackbar();
 *
 *  //use the promise for confirmation:
 *
 *  confirm("Delete", "Are you sure?").then(confirm  => {
 *      if(confirm === true){
 *          ... delete something
 *      }
 *  })
 *
 */

export type SnackbarResolveFn = (isConfirmed: boolean) => void;

interface SnackbarStore {
    title: string;
    message: string;
    type: SnackbarType;
    isVisible: boolean;
    isConfirm: boolean;
    runningTimeout: NodeJS.Timeout | undefined;
    resolve: SnackbarResolveFn | undefined;
    options: SnackbarOptions;
    notify: iNotify;
    confirm: iNotify;
    mergeOptions: (userOptions: SnackbarUserOptions) => void;
    onResolve: (confirmed: boolean) => void;
    resetSnackbar: () => void;
}

const useStore = create<SnackbarStore>((set, get) => ({
    title: "",
    message: "",
    type: "default",
    isVisible: false,
    isConfirm: false,
    runningTimeout: undefined,
    resolve: undefined,
    options: {
        timeout: 4000,
        confirmText: "Ja",
        confirmCancelText: "Nein",
        notifyText: "Ok",
    },

    notify: async (
        title,
        message,
        type = "default",
        userOptions = undefined
    ) => {
        const { isVisible, runningTimeout, onResolve, options, mergeOptions } =
            get();

        if (isVisible || runningTimeout) {
            onResolve(false);
        }

        const timeout = setTimeout(() => {
            onResolve(true);
        }, userOptions?.timeout || options.timeout);

        if (userOptions) {
            mergeOptions(userOptions);
        }

        set({ title, message, type, isVisible: true, runningTimeout: timeout });

        return new Promise((resolve) => {
            set({ resolve: resolve });
        });
    },

    confirm: async (
        title,
        message,
        type = "default",
        userOptions = undefined
    ) => {
        const { isVisible, runningTimeout, mergeOptions, onResolve } = get();
        if (isVisible || runningTimeout) {
            onResolve(false);
        }

        if (userOptions) {
            mergeOptions(userOptions);
        }

        set({ title, message, type, isVisible: true, isConfirm: true });

        return new Promise((resolve) => {
            set({ resolve: resolve });
        });
    },

    mergeOptions: (userOptions) => {
        set({ options: { ...get().options, ...userOptions } });
    },

    onResolve: (confirmed) => {
        const { resolve, resetSnackbar } = get();
        if (resolve) {
            resolve(confirmed);
        }
        resetSnackbar();
    },

    resetSnackbar: () => {
        const { runningTimeout } = get();
        if (runningTimeout) {
            clearTimeout(runningTimeout);
        }
        set({
            isVisible: false,
            isConfirm: false,
            runningTimeout: undefined,
            title: "",
            message: "",
            type: "default",
            resolve: undefined,
            options: {
                timeout: 4000,
                confirmText: "Ja",
                confirmCancelText: "Nein",
                notifyText: "Ok",
            },
        });
    },
}));

export default useStore;
