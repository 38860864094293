import { useMemo } from "react";

import { graphql, navigate as navigate_, useStaticQuery } from "gatsby";
import { useLocation } from "@gatsbyjs/reach-router";

import { NavigateOptions } from "@reach/router";

export enum HrefType {
    sameOrigin = "sameOrigin",
    external = "external",
}

export type Href = {
    type: HrefType;
    value: string;
};

const mkHref = (
    ...args: [to_?: null] | [to_: string, base_: string]
): Href | null => {
    if (args[0] == null) return null;

    const [to_, base_] = args;

    const base = new URL(base_);
    const to = new URL(to_, base);

    if (to.host === base.host) {
        const path = to.pathname.endsWith("/")
            ? to.pathname
            : `${to.pathname}/`;

        return {
            type: HrefType.sameOrigin,
            value: `${path}${to.search}${to.hash}`,
        };
    }

    return {
        type: HrefType.external,
        value: to_,
    };
};

const useOrigin = (): string | null => {
    const { origin } = useLocation();

    try {
        const {
            site: {
                siteMetadata: { siteUrl },
            },
            // eslint-disable-next-line react-hooks/rules-of-hooks
        } = useStaticQuery<{ site: { siteMetadata: { siteUrl: string } } }>(
            graphql`
                query {
                    site {
                        siteMetadata {
                            siteUrl
                        }
                    }
                }
            `
        );
        return origin ?? siteUrl;
    } catch (e) {
        console.error("Link: Failed static query", e);
        return origin;
    }
};

export const useHref = (to: string): Href | null => {
    const origin = useOrigin();

    return useMemo(
        () => (origin == null ? null : mkHref(to, origin)),
        [to, origin]
    );
};

export const useSameOriginHref = (to_: string): string | null => {
    const to = useHref(to_);

    if (to == null) return null;

    if (to.type === HrefType.sameOrigin) return to.value;

    throw new Error("Not a sameOrigin link");
};

export const useSameOriginHrefAbs = (to_: string): string | null => {
    const to = useSameOriginHref(to_);
    const origin = useOrigin();

    return useMemo(
        () =>
            origin == null || to == null
                ? null
                : new URL(to, origin).toString(),
        [to, origin]
    );
};

export const navigate = (
    to_: string,
    options?: NavigateOptions<Record<string, never>>
) => {
    const to = mkHref(to_, window.location.origin);

    if (to == null) return Promise.resolve();

    if (to.type === HrefType.sameOrigin) return navigate_(to.value, options);

    window.location.href = to.value;
    return Promise.resolve();
};
