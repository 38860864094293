import React from "react";

import styled from "@emotion/styled";

const WarningIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M21.81147,21.76483H2.01167a1.3542,1.3542,0,0,1-1.20215-.65332,1.35632,1.35632,0,0,1,.04-1.36816v-.001L10.74506,2.779a1.3533,1.3533,0,0,1,2.333.001l9.89551,16.9624a1.30718,1.30718,0,0,1-1.16211,2.02246ZM2.57807,20.74921h0l.001-.002Zm.57422-.98438H20.67085L11.91108,4.74921Z" />
            <path d="M11,18V16h2v2Zm2-3H11V9h2Z" />
        </SVG>
    );
};

export default WarningIcon;
