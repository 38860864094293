import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { fadeInWithOpa } from "../../../../../../../../utils/AnimationHelpers";
import { blue } from "../../../../../../../../utils/colors";
import { Link } from "../../../../../../LinkComponent";

const Container = styled.div`
    display: none;
    flex-direction: column;
    padding-left: 50px;
    line-height: 2em;

    &.fade-in {
        ${({ theme }) => theme.breakpoints.upTo.medium.css`
            display: flex;
            animation: ease 0.5s ${fadeInWithOpa} forwards;
        `}
    }

    &.fade-out {
        display: none;
    }
`;

const LinkItem = css`
    color: ${blue};
`;

const SubItemContainer = ({ isActive, subsection }) => {
    return (
        <Container
            className={
                isActive === null ? "" : isActive ? "fade-in" : "fade-out"
            }
        >
            {subsection.items.map((item, i) => (
                <Link key={i} to={item.href} css={LinkItem}>
                    {item.text}
                </Link>
            ))}
        </Container>
    );
};

export default SubItemContainer;
