import React from "react";

import styled from "@emotion/styled";

import useComponentVisible from "../../../../../../../hooks/useComponentVisible";
import { blue } from "../../../../../../../utils/colors";
import ChevronIcon from "../../../../../icons/ChevronIcon";
import SubItemContainer from "./SubItemContainer";

const Item = styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    padding: 8px 0 8px 30px;
    font-weight: bold;
    background-color: white;
`;

const iconContainerState = (props) => ({
    transform: props.isActive ? "rotate(90deg)" : "rotate(0)",
});
const IconContainer = styled.div`
    ${iconContainerState};

    width: 20px;
    display: flex;
`;

const MobileSubDropdown = ({ subsection }) => {
    const {
        ref: componentVisibleRef,
        isVisible: isActive,
        setIsVisible: setIsActive,
    } = useComponentVisible(false);

    const handleClick = () => {
        setIsActive(!isActive);
    };

    return (
        <div ref={componentVisibleRef}>
            {subsection.name && (
                <Item onClick={handleClick}>
                    <div>{subsection.name}</div>
                    <div>
                        <IconContainer isActive={isActive}>
                            <ChevronIcon color={blue} />
                        </IconContainer>
                    </div>
                </Item>
            )}
            <SubItemContainer
                isActive={isActive || !subsection.name}
                subsection={subsection}
            />
        </div>
    );
};

export default MobileSubDropdown;
