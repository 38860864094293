import React from "react";

import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import useComponentVisible from "../../../../../../hooks/useComponentVisible";
import { fadeInWithOpa } from "../../../../../../utils/AnimationHelpers";
import { blue } from "../../../../../../utils/colors";
import ChevronIcon from "../../../../icons/ChevronIcon";
import { IconPredefined } from "../../../../icons/Icon";
import { PredefinedIconType } from "../../../../icons/Icon/Predefined";
import { Link } from "../../../../LinkComponent";
import MobileSubDropdown from "./MobileSubDropdown";

const Item = styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    font-weight: bold;
    background-color: white;
    border-bottom: 2px solid ${blue};
`;

const iconContainerState = (props) => ({
    transform: props.isActive ? "rotate(90deg)" : "rotate(0)",
});

const IconContainer = styled.div`
    ${iconContainerState};

    width: 20px;
    display: flex;
`;

const SubItemContainer = styled.div`
    display: none;
    flex-direction: column;
    border-bottom: 2px solid ${blue};

    &.fade-in {
        ${({ theme }) => theme.breakpoints.upTo.medium.css`
            display: flex;
            animation: ease 0.5s ${fadeInWithOpa} forwards;
        `}
    }

    &.fade-out {
        display: none;
    }
`;

const LinkItem = css`
    display: flex;
    justify-content: space-between;
    padding: 8px 0 8px 30px;
    font-weight: bold;
    color: ${blue};
`;

const MobileDropdown = ({ section, children }) => {
    const theme = useTheme();

    const {
        ref: componentVisibleRef,
        isVisible: isActive,
        setIsVisible: setIsActive,
    } = useComponentVisible(false);

    const handleClick = () => {
        setIsActive(!isActive);
    };

    return (
        <div ref={componentVisibleRef}>
            <Item onClick={handleClick}>
                <div>
                    {section.name.toLowerCase() === "translation" ? (
                        <div style={{ display: "flex", gap: "10px" }}>
                            International
                            <IconPredefined
                                color={theme.colors.blue.toString()}
                                type={PredefinedIconType.world}
                            />
                        </div>
                    ) : (
                        section.name
                    )}
                </div>
                <div>
                    <IconContainer isActive={isActive}>
                        <ChevronIcon color={blue} />
                    </IconContainer>
                </div>
            </Item>
            <SubItemContainer
                className={
                    isActive === null ? "" : isActive ? "fade-in" : "fade-out"
                }
            >
                {section.subsections.map((subsection, i) => {
                    return subsection.items && subsection.items.length > 0 ? (
                        <MobileSubDropdown key={i} subsection={subsection} />
                    ) : (
                        <Link key={i} to={subsection.href} css={LinkItem}>
                            <div>{subsection.name}</div>
                            <div>
                                <IconContainer>
                                    <ChevronIcon color={blue} />
                                </IconContainer>
                            </div>
                        </Link>
                    );
                })}
                {children}
            </SubItemContainer>
        </div>
    );
};

export default MobileDropdown;
