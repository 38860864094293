import React, { FC } from "react";

import { ThreeDots } from "react-loader-spinner";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
    .content {
        text-align: center;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export type LoadingIndicatorProps = {
    light?: boolean;
    inline?: boolean;
};

const LoadingIndicator: FC<LoadingIndicatorProps> = ({
    children,
    light = false,
    inline = false,
}) => {
    const inlineStyles = css`
        display: inline-flex;
    `;

    return (
        <Wrapper css={inline && inlineStyles}>
            {children && <div>{children}</div>}
            <ThreeDots
                color={light ? "#FFFFFF" : "#463f39"}
                height={16}
                width={33}
                style={{ textAlign: "center" }}
            />
        </Wrapper>
    );
};

export default LoadingIndicator;

const ContainerWrapper = styled.div`
    ${({ theme }) => theme.paddings.containerOuter.all}
`;
export const LoadingIndicatorContainer = ({
    children = "Daten werden geladen…",
    ...props
}) => (
    <ContainerWrapper>
        <LoadingIndicator {...props}>{children}</LoadingIndicator>
    </ContainerWrapper>
);
