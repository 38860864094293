import create from "zustand";

const useStore = create((set, get) => ({
    contactFormVisible: false,
    modalState: null,
    setModalState: (value) => set({ modalState: value }),
    setContactFormVisible: async (value) => {
        if (value === false) {
            set({ modalState: "close" });

            await new Promise((resolve) => setTimeout(() => resolve(), 250));
            set({ contactFormVisible: false, modalState: null });
        } else {
            set({ contactFormVisible: value });
        }
    },
    toggleContactForm: () => {
        const { setContactFormVisible, contactFormVisible } = get();
        return setContactFormVisible(!contactFormVisible);
    },
    openContactForm: () => get().setContactFormVisible(true),
    closeContactForm: () => get().setContactFormVisible(false),
}));

export default useStore;
