/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import Helmet from "react-helmet";
import { useIntl } from "react-intl";

import PropTypes from "prop-types";

import { env } from "../../../config";
import { useSameOriginHrefAbs } from "../LinkComponent";

function SEO({
    description,
    meta,
    keywords,
    title,
    slug,
    image,
    lang,
    altLangs,
    templateFields,
}) {
    let site;
    try {
        const res = useStaticQuery(
            graphql`
                query {
                    site {
                        siteMetadata {
                            title
                            description
                            author
                        }
                    }
                }
            `
        );
        site = res.site;
    } catch (e) {
        console.error("SEO: Failed static query", e);
    }

    // There must always be the same number of hooks
    const canonical = useSameOriginHrefAbs(
        slug == null ? null : slug.startsWith("/") ? slug : `/${slug}`
    );

    const intl = useIntl();

    if (!site) return null;

    const actualDescription = intl.formatMessage(
        {
            id: `SEO:${
                slug ?? description ?? site.siteMetadata.description
            }:description`,
            defaultMessage: description ?? site.siteMetadata.description,
        },
        templateFields
    );

    const actualTitle =
        title == null
            ? undefined
            : intl.formatMessage(
                  {
                      id: `SEO:${slug ?? title}:title`,
                      defaultMessage: title,
                  },
                  templateFields
              );

    const links = [];
    if (canonical != null) {
        links.push({
            rel: "canonical",
            href: canonical,
        });
    }
    if (altLangs != null && altLangs.length > 0) {
        altLangs.forEach((item) => {
            links.push({
                rel: "alternate",
                href: item.href,
                hreflang: item.hreflang,
            });
        });
    }
    return (
        <Helmet
            htmlAttributes={{ lang: lang ?? "de" }}
            title={actualTitle}
            titleTemplate={`%s`}
            meta={[
                {
                    name: `description`,
                    content: actualDescription,
                },
                ...(actualTitle == null
                    ? []
                    : [
                          {
                              property: `og:title`,
                              content: actualTitle,
                          },
                          {
                              name: `twitter:title`,
                              content: actualTitle,
                          },
                      ]),
                {
                    property: `og:description`,
                    content: actualDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:description`,
                    content: actualDescription,
                },
                ...(image
                    ? [
                          {
                              name: "twitter:image",
                              content: image,
                          },
                          {
                              property: "og:image",
                              content: image,
                          },
                      ]
                    : []),
                ...(keywords.length > 0
                    ? [
                          {
                              name: `keywords`,
                              content: keywords.join(`, `),
                          },
                      ]
                    : []),
                ...meta,
            ]}
            link={links}
        />
    );
}

SEO.defaultProps = {
    lang: `de`,
    meta: [],
    keywords: [],
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
};

class SEOWithoutErrors extends React.Component {
    componentDidCatch(error, errorInfo) {
        if (env === "development") {
            console.error("SEO encountered an error: %o %o", error, errorInfo);
        }
    }

    render() {
        return <SEO {...this.props} />;
    }
}

export default SEOWithoutErrors;
