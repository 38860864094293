import React from "react";

const JsonLd = ({ children, ...props }) => (
    <script
        {...props}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(children) }}
    />
);
export default JsonLd;
