import React from "react";

import styled from "@emotion/styled";

const BookmarkIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="15.001"
            height="20"
            viewBox="0 0 15.001 20"
        >
            <path
                id="bookmark-regular"
                d="M0,1.875A1.876,1.876,0,0,1,1.875,0V17.244l5.083-3.629a.933.933,0,0,1,1.09,0l5.079,3.629V1.875H1.875V0H13.126A1.876,1.876,0,0,1,15,1.875V19.064a.937.937,0,0,1-1.481.762l-6.02-4.3-6.02,4.3A.937.937,0,0,1,0,19.064Z"
                fill="#e61f27"
            />
        </SVG>
    );
};

export default BookmarkIcon;
