/**********************************************************************
 * cls - Construct className property
 **********************************************************************/
export default function cls(...args) {
    const result = [];
    for (const c of args) {
        // Add strings as class names
        if (typeof c === "string") {
            result.push(c);
        }
        // Process arrays recursively
        else if (Array.isArray(c)) {
            result.push(...cls(...c));
        }
        // Objects: Add the property name as a class name iff the property value
        // is truthy.
        else if (c) {
            for (const [k, v] of Object.entries(c)) {
                if (v) {
                    result.push(k);
                }
            }
        }
    }

    return result.join(" ");
}
