import React, { ReactElement, useMemo } from "react";

import { GatsbyLinkProps, Link as Link_ } from "gatsby";

import { Href, HrefType, useHref } from "../../../../hooks/navigation";

type KnownKeys<T> = {
    [K in keyof T as string extends K
        ? never
        : number extends K
        ? never
        : K]: T[K];
};
type OmitFromKnownKeys<T, K extends keyof T> = KnownKeys<T> extends infer U
    ? keyof U extends keyof T
        ? Pick<T, Exclude<keyof U, K>> &
              Pick<T, Exclude<keyof T, keyof KnownKeys<T>>>
        : never
    : never;

// There's an issue with the type of `ref` on GatsbyLink, so let's just omit it for now
type AnchorProps<state> = OmitFromKnownKeys<GatsbyLinkProps<state>, "ref">;

const Anchor = <state,>({
    activeClassName,
    activeStyle,
    partiallyActive,
    replace,
    state,
    to,
    children,
    ...props
}: AnchorProps<state>): ReactElement<any, any> | null => (
    <a href={to} {...props}>
        {children}
    </a>
);

const LinkFunc = <state,>(
    props: AnchorProps<state>
): ReactElement<any, any> | null => <Link_<state> {...props} />;

export type LinkProps<state> = AnchorProps<state>;
const Link = <state,>({
    to: to_,
    target,
    rel,
    ...props
}: LinkProps<state>): ReactElement<any, any> | null => {
    // The cms can't use static queries. So we have to use an "empty" object in this case.
    const to =
        useHref(to_) ?? ({ value: to_, type: HrefType.external } as Href);

    const { Component, extraProps } = useMemo(() => {
        if (to.type === HrefType.sameOrigin) {
            return {
                Component: target === "_blank" ? Anchor : LinkFunc,
                extraProps: {
                    to: to.value,
                    target,
                    rel,
                } as AnchorProps<state>,
            };
        }

        const isHTTP = /https?:/.test(to.value);

        return {
            Component: Anchor,
            extraProps: {
                to: to.value,
                target: isHTTP ? "_blank" : target,
                rel: isHTTP ? "noopener noreferrer noodp" : rel,
            } as AnchorProps<state>,
        };
    }, [to.type, to.value, target, rel]);

    return <Component<state> {...{ ...extraProps, ...props }} />;
};
export default Link;
