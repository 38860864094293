import React, { FC } from "react";

import * as Ord_ from "fp-ts/lib/Ord";
import * as Mp from "fp-ts/lib/ReadonlyMap";

import { Columns } from "react-bulma-components";

import type { PropsOf } from "@emotion/react";
import { css, useTheme } from "@emotion/react";

import cls from "../../../../utils/cls";
import {
    MultiSelectInputContainer,
    MultiSelectInputOption,
} from "../../../shared/forms/MultiSelectInput";
import { Theme } from "../../../Theme";
import * as styles from "./style.module.scss";

type ColumnProps = PropsOf<typeof Columns.Column>;

const columnIsCentered = css`
    display: flex !important;
    justify-content: center !important;
`;

const columnStyles = (theme: Theme) => css`
    ${theme.breakpoints.upTo.small.css`
        padding: 0.5rem !important;
    `}
`;

const OptionWithoutInput = (props: PropsOf<typeof MultiSelectInputOption>) => {
    return (
        <MultiSelectInputOption {...props} noInput>
            {props.children}
        </MultiSelectInputOption>
    );
};

export type ColMultiSelectProps<a> = {
    Option?: FC<{ entry: [a, string]; targetId: string }>;
    className?: string;
    id: string;
    colSize: ColumnProps["size"];
    mobileColSize: ColumnProps["size"];
    value: ReadonlySet<a>;
    values: ReadonlyMap<a, string>;
    onChange: (a: ReadonlySet<a>) => void;
    optionClassName?: string;
    gtag: string;
    mkOptionValue?: (a: a) => string;
    centeredColumns?: boolean;
    noInput?: boolean;
    Ord: Ord_.Ord<a>;
    isNarrow: boolean;
};

const ColMultiSelect = <a,>({
    className,
    colSize,
    mobileColSize = colSize,
    id,
    value,
    values,
    onChange,
    Option = ({ entry: [, name] }) => <>{name}</>,
    optionClassName = "",
    mkOptionValue = <a,>(a: a) => String(a),
    gtag,
    centeredColumns = false,
    noInput = false,
    Ord,
    isNarrow = false,
}: ColMultiSelectProps<a>) => {
    const themes = useTheme();

    return (
        <Columns
            breakpoint="mobile"
            renderAs={MultiSelectInputContainer}
            {...{ className, id, value, onChange, Ord }}
        >
            {Mp.toReadonlyArray(Ord)(values).map(([v, text], i) => (
                <Columns.Column
                    key={i}
                    renderAs={
                        noInput ? OptionWithoutInput : MultiSelectInputOption
                    }
                    mobile={{ size: mobileColSize }}
                    tablet={{ size: colSize }}
                    id={`${id}-${mkOptionValue(v)}`}
                    value={v}
                    narrow={isNarrow}
                    textValue={mkOptionValue(v)}
                    className={cls(optionClassName, styles.MultiSelectOption)}
                    gtag={`${gtag}:${mkOptionValue(v)}`}
                    css={css`
                        ${columnStyles(themes)}
                        ${centeredColumns && columnIsCentered}
                    `}
                >
                    <Option
                        entry={[v, text]}
                        targetId={`${id}-${mkOptionValue(v)}`}
                    />
                </Columns.Column>
            ))}
        </Columns>
    );
};

export default ColMultiSelect;
