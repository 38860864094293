import React from "react";

import * as styles from "./style.module.scss";

const Container = ({
    children,
    desktopFullWidth = false,
    className = "",
    noPadding = false,
    slim = false,
    ultraSlim = false,
    style = {},
    onClick = undefined,
    ...props
}) => {
    return (
        <div
            style={style}
            className={`container ${styles.container} ${
                desktopFullWidth ? styles.desktopFullWidth : ""
            } ${className} ${noPadding ? styles.noPadding : ""} ${
                slim ? styles.slim : ""
            } ${ultraSlim ? styles.ultraSlim : ""}`}
            onClick={onClick}
            {...props}
        >
            {children}
        </div>
    );
};

export default Container;
