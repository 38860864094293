/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from "react";

import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

import { blur, Blurred } from "../Blur";
import scrollToTopImage from "./scroll-to-top.svg";

const slideIn = keyframes`
    0 % {
        right: -90px;
    }

    50 % {
        right: -15px;
    }

    100 % {
        right: -35px;
    }
`;

const slideOut = keyframes`
    0 % {
        right: -35px;
    }

    50 % {
        right: -15px;
    }

    100 % {
        right: -90px;
    }
`;

const Scroller = styled(Blurred)`
    position: fixed;
    right: -35px;
    bottom: 15vh;
    z-index: 200;
    width: 90px;
    height: 60px;
    background-image: url(${scrollToTopImage});
    background-repeat: no-repeat;
    background-size: contain;
    animation: ${slideIn} 0.3s;

    &.closing {
        animation: ${slideOut} 0.3s;
    }

    &:hover {
        cursor: pointer;
        filter: brightness(85%);
    }
`;

const ScrollToTop = () => {
    // Don't include in SSR
    if (typeof window === "undefined") return null;

    const [visible, setVisible] = useState(false);
    const [closing, setClosing] = useState(false);
    const ref = useRef(undefined);

    const onScroll = () => {
        if (window.pageYOffset >= 300) {
            if (ref.current) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                clearTimeout(ref.current);
                ref.current = undefined;
            }
            setClosing(false);
            setVisible(true);
        } else {
            setClosing(true);
            if (!ref.current) {
                ref.current = setTimeout(() => {
                    setClosing(false);
                    setVisible(false);
                }, 300);
            }
        }
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        onScroll();
        window.addEventListener("scroll", onScroll, { passive: true });
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    if (!visible) {
        return null;
    }

    return (
        <Scroller
            eles={["ScrollToTop"]}
            className={closing ? "closing" : ""}
            onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
        />
    );
};

export default ScrollToTop;
