import React from "react";

import styled from "@emotion/styled";

import { iSvgIconProps } from "../types";

const CopyIcon = ({ color = "#f0f0f0", hoverColor = color }: iSvgIconProps) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG
            id="Ebene_2"
            data-name="Ebene 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 42.35 42.35"
        >
            <g id="Gruppe_5639" data-name="Gruppe 5639">
                <path
                    id="Pfad_8703"
                    data-name="Pfad 8703"
                    className="cls-1"
                    d="m21.17,42.35C9.48,42.35,0,32.87,0,21.17S9.48,0,21.17,0s21.17,9.48,21.17,21.17c-.01,11.69-9.49,21.16-21.17,21.17m0-38.79C11.44,3.56,3.56,11.44,3.56,21.17s7.89,17.62,17.62,17.62,17.62-7.89,17.62-17.62c-.01-9.73-7.89-17.61-17.62-17.62"
                />
                <rect
                    id="Rechteck_1961"
                    data-name="Rechteck 1961"
                    className="cls-1"
                    x="18.77"
                    y="17.98"
                    width="4.85"
                    height="13.72"
                />
                <rect
                    id="Rechteck_1962"
                    data-name="Rechteck 1962"
                    className="cls-1"
                    x="18.75"
                    y="10.65"
                    width="4.85"
                    height="4.42"
                    transform="translate(-.15 .25) rotate(-.69)"
                />
            </g>
        </SVG>
    );
};

export default CopyIcon;
