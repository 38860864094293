export const consentKeys = {
    general: "app:consent:general",
    analytics: "app:consent:analytics",
    analyticsPreselect: "app:consent:analytics:preselect",
    marketing: "app:consent:marketing",
    marketingPreselect: "app:consent:marketing:preselect",
    youtube: "app:consent:youtube",
    youtubePreselect: "app:consent:youtube:preselect",
    googleMapsAndFonts: "app:consent:gmaps-gfonts",
    googleMapsAndFontsPreselect: "app:consent:gmaps-gfonts:preselect",
    analyticsChangedAt: "app:analytics:changed-at",
    marketingChangedAt: "app:marketing:changed-at",
    consentSubmittedAt: "app:consent:submitted-at",
};
