import React, { useEffect, useRef, useState } from "react";

import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { Link } from "../../../../LinkComponent";

const SubNavWrapper = styled.div`
    position: absolute;
    padding: 30px;
    background-color: white;
    border-bottom-right-radius: 30px 30px;
    border-bottom-left-radius: 30px 30px;

    &:hover {
        visibility: visible;
    }
`;

const SubNavContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 80px;
`;

const SubNavItems = styled.div`
    display: flex;
    flex-direction: column;
`;

const SubNavHeadline = styled.div`
    margin-bottom: 20px;
    font-weight: 500;
    white-space: nowrap;
`;

const SubContent = styled.div`
    grid-column-start: 1;
`;

const SubNav = ({ subsections, children, isOpen }) => {
    const wrapperRef = useRef(null);
    const [cssCorrection, setCssCorrection] = useState("");
    const theme = useTheme();
    const subNavLinkItem = css`
        width: 100%;
        font-size: 1rem;
        line-height: 2rem;
        color: ${theme.colors.red.toString()};
        text-decoration: underline;
        white-space: nowrap;

        &:hover {
            color: ${theme.colors.red.toString()};
        }
    `;

    useEffect(() => {
        // Workaround for Mein Konto, where the SubNav will float outside of the screen.
        if (isOpen) {
            const viewportWidth = Math.max(
                document.documentElement.clientWidth || 0,
                window.innerWidth || 0
            );
            const rect = wrapperRef.current.getBoundingClientRect();
            if (rect.right > viewportWidth) {
                setCssCorrection(
                    css`
                        right: 0;
                    `
                );
            }
        }
    }, [isOpen]);

    return (
        <SubNavWrapper ref={wrapperRef} css={cssCorrection}>
            <SubNavContainer>
                {subsections.map((subsection, i) => {
                    return (
                        <SubNavItems key={i}>
                            {subsection.name && (
                                <SubNavHeadline>
                                    {subsection.name}
                                </SubNavHeadline>
                            )}
                            {subsection.items.map((item, i) => (
                                <Link
                                    key={i}
                                    to={item.href}
                                    css={subNavLinkItem}
                                >
                                    {item.text}
                                </Link>
                            ))}
                        </SubNavItems>
                    );
                })}
            </SubNavContainer>
            <SubContent>{children}</SubContent>
        </SubNavWrapper>
    );
};

export default SubNav;
