import React from "react";

import styled from "@emotion/styled";

export const ErrorWrapper = styled.div`
    font-size: small;
    color: ${({ theme }) => theme.colors.red.toString()};
`;

interface iErrorMessage {
    error: any;
    name: string;
    rules: any;
}

const ErrorMessage = ({ error, name, rules }: iErrorMessage) => {
    const getMessage = (type: string): string => {
        if (
            rules.maxLength &&
            rules.minLength &&
            rules.maxLength === rules.minLength &&
            (type === "maxLength" || type === "minLength")
        ) {
            return `${name} sollte exakt ${rules.maxLength} Zeichen haben.`;
        }
        if (name === "App ID*" && rules.required && type === "required") {
            return `Bitte wähle eine App ID aus.`;
        }
        const messages: { [key: string]: string } = {
            required: `${name} muss ausgefüllt werden.`,
            maxLength: `${name} darf maximal ${rules.maxLength} Zeichen lang sein.`,
            minLength: `${name} muss minimal ${rules.minLength} Zeichen lang sein.`,
        };
        if (messages[type]) {
            return messages[type];
        }
        //Pass backend Errors
        if (error.type === "custom") {
            return error.message;
        }
        if (error.type === "validate") {
            return error.message;
        }

        return "Fehler";
    };

    return (
        <ErrorWrapper>{error && <p>{getMessage(error.type)}</p>}</ErrorWrapper>
    );
};

export default ErrorMessage;
