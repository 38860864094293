import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const contentContainer = ({ theme }) => css`
    padding: 0;
    ${theme.paddings.containerOuter.right}
    ${theme.paddings.containerOuter.left}

    width: 100vw;
`;

const ContentContainer = styled.div`
    ${contentContainer}
`;
export default ContentContainer;
