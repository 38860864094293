import React from "react";

import styled from "@emotion/styled";

const CloseIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18.7 22"
            width="18.7"
            height="22"
        >
            <g transform="translate(8.5 10)">
                <path
                    d="M17.6,19.8h-.9L10.781,1.049A1.6,1.6,0,0,0,9.35,0,1.6,1.6,0,0,0,7.919,1.049L2,19.8H1.1a1.1,1.1,0,1,0,0,2.2H17.6a1.1,1.1,0,1,0,0-2.2ZM6.736,12.1h5.228l.695,2.2H6.041Zm6.617,4.4.695,2.2h-9.4l.695-2.2Zm-4-12.678L11.269,9.9H7.431Z"
                    transform="translate(-8.5 -10)"
                />
            </g>
        </SVG>
    );
};

export default CloseIcon;
