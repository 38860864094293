import React, { FC } from "react";

import {
    UserManager,
    UserManagerSettings,
    WebStorageStateStore,
} from "oidc-client-ts";

import { keycloak as config } from "./config";
import { useIsAuthenticated } from "./providers/AuthProvider";

const isBrowser = typeof window !== "undefined";

export const authority = `${String(config.baseUri)}realms/${config.realm}/`;
export const clientId = config.clientId;
export const redirectUri = isBrowser
    ? `${window.location.origin}/login-redirect/`
    : "";

const userConfig: UserManagerSettings = {
    authority,
    client_id: clientId,
    redirect_uri: redirectUri,
    silent_redirect_uri: "",
};

const oidcConfig = {
    userManager: new UserManager(userConfig),
    automaticSilentRenew: true,
    ...(isBrowser
        ? {
              userStore: new WebStorageStateStore({
                  store: window?.localStorage,
              }),
          }
        : {}),
};

export default oidcConfig;

export const IfLoggedIn: FC<Record<string, never>> = ({ children }) => {
    const isAuthenticated = useIsAuthenticated();

    return <>{isAuthenticated ? children : null}</>;
};

export const IfNotLoggedIn: FC<Record<string, never>> = ({ children }) => {
    const isAuthenticated = useIsAuthenticated();

    return <>{isAuthenticated ? null : children}</>;
};
