import React, { useContext, useState } from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { DataContext } from "../../../providers/CookieConsentProvider";
import Backdrop from "../Backdrop";
import Blur from "../Blur";
import Checkbox from "../forms/NewCheckbox";
import Margin from "../Margin";
import Button from "../NewForms/Button";

const ConsentModal = styled.div`
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    background-color: white;
    box-shadow: 0 -5px 5px 0 rgb(0 0 0 / 20%);
    left: 20px;
    right: 20px;
    bottom: 50%;
    transform: translateY(50%);
    max-height: 80vh;
    overflow-y: scroll;
    padding: 20px;
    position: fixed;
    z-index: 1000;

    ${({ theme }) => theme.breakpoints.from.medium.css`
        right: 0;
        bottom: 0;
        left: auto;
        width: 50%;
        max-width: 600px;
        max-height: 75vh;
        overflow-y: scroll;
        border-radius: unset;
        border-top-left-radius: 4px;
        transform: unset;
    `}
`;

const Details = styled.div`
    font-size: 12px;

    input {
        margin-right: 10px;
    }
`;

const CookieConsent = () => {
    const dataContext = useContext(DataContext);

    const [showDetails, setShowDetails] = useState(false);

    const {
        open,
        relevantCookies,
        acceptedAnalytics,
        acceptedYoutube,
        backdropCanClose,
        acceptedMarketing,
        acceptedGoogleMapsAndFonts,
    } = dataContext.data;
    const {
        acceptAll,
        save,
        setAcceptedAnalytics,
        setAcceptedYoutube,
        setOpen,
        setAcceptedMarketing,
        setAcceptedGoogleMapsAndFonts,
    } = dataContext.actions;

    const saveButtonCss = css`
        margin-top: 20px;
    `;

    /**
     * On the click on the backdrop.
     */
    const onClickBackdrop = (event) => {
        if (event.target.className.includes("backdrop") && backdropCanClose) {
            setOpen(false);
        }
    };

    return (
        <>
            {open && <Blur />}

            <Backdrop
                {...{ open }}
                onClick={onClickBackdrop}
                className="backdrop"
            >
                {open && (
                    <ConsentModal>
                        <p>
                            Wir verwenden Cookies/Trackingelemente, um Inhalte
                            anzeigen zu können, Anzeigen zu personalisieren,
                            Funktionen für soziale Medien anbieten zu können und
                            die Zugriffe auf unsere Website zu analysieren.
                            Außerdem geben wir Informationen zu Ihrer Verwendung
                            unserer Website an unsere Partner für soziale
                            Medien, Werbung und Analysen weiter. Unsere Partner
                            führen diese Informationen möglicherweise mit
                            weiteren Daten zusammen, die Sie ihnen
                            bereitgestellt haben oder die sie im Rahmen Ihrer
                            Nutzung der Dienste gesammelt haben. Sie geben
                            Einwilligung zu unseren Cookies/Trackingelementen,
                            die Sie im Auswahlfenster auswählen können. In der
                            Datenschutzerklärung können sie die Einwilligungen
                            widerrufen.
                        </p>
                        <Margin top="10" className="columns">
                            <div className="column is-multiline">
                                <Button
                                    color="blue"
                                    data-gtag="consent:save-all"
                                    fullWidth
                                    onClick={acceptAll}
                                >
                                    Alle zulassen
                                </Button>
                            </div>
                            <div className="column">
                                <Button
                                    onClick={() => {
                                        setShowDetails(!showDetails);
                                    }}
                                    data-gtag="consent:show-details"
                                    fullWidth
                                    color="grey"
                                >
                                    Details
                                </Button>
                            </div>
                        </Margin>
                        {showDetails && (
                            <Details>
                                <div className="card">
                                    <div className="card-content">
                                        <Checkbox
                                            label="System relevante Cookies
                                            (erforderlich für die korrekte
                                            Ausführung der Website)"
                                            checked={relevantCookies}
                                            withField
                                            readOnly
                                        />
                                        <Checkbox
                                            label="Youtube Videos"
                                            checked={acceptedYoutube}
                                            onChange={() => {
                                                setAcceptedYoutube(
                                                    !acceptedYoutube
                                                );
                                            }}
                                            withField
                                        />
                                        <Checkbox
                                            label="Analyse"
                                            checked={acceptedAnalytics}
                                            onChange={() => {
                                                setAcceptedAnalytics(
                                                    !acceptedAnalytics
                                                );
                                            }}
                                            withField
                                        />
                                        <Checkbox
                                            label="Marketing"
                                            checked={acceptedMarketing}
                                            onChange={() => {
                                                setAcceptedMarketing(
                                                    !acceptedMarketing
                                                );
                                            }}
                                            withField
                                        />
                                        <Checkbox
                                            label="Google Maps inkl. Google Fonts"
                                            checked={acceptedGoogleMapsAndFonts}
                                            onChange={() => {
                                                setAcceptedGoogleMapsAndFonts(
                                                    !acceptedGoogleMapsAndFonts
                                                );
                                            }}
                                            withField
                                        />
                                        <Button
                                            onClick={save}
                                            color="blue"
                                            data-gtag="consent:save-selection"
                                            css={saveButtonCss}
                                        >
                                            Speichern
                                        </Button>
                                    </div>
                                </div>
                            </Details>
                        )}
                    </ConsentModal>
                )}
            </Backdrop>
        </>
    );
};

export default CookieConsent;
