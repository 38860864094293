import React from "react";

import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";

import { Permission, useHoyerAcl } from "../../../../../../hooks/useHoyerAcl";
import {
    useIsAuthenticated,
    useKeycloakOpt,
} from "../../../../../../providers/AuthProvider";
import AnimationHelpers from "../../../../../../utils/AnimationHelpers";
import { blue } from "../../../../../../utils/colors";
import { storageLocal as storage } from "../../../../../../utils/StorageHandler";
import Backdrop from "../../../../Backdrop";
import { BlurOther } from "../../../../Blur";
import ChevronIcon from "../../../../icons/ChevronIcon";
import LinkComponent, { Link } from "../../../../LinkComponent";
import ShapeCorner from "../../../../ShapeContainer/ShapeCorner";
import useStore from "../../../ContactForm/store";
import { GetAccountSection } from "../../../Desktop/LinkItems";
import MobileDropdown from "../MobileDropdown";

const iconContainerState = (props) => ({
    width: props.small ? "20px" : "25px",
});

const IconContainer = styled.div`
    ${iconContainerState};

    display: flex;
`;

const ShapeContainer = styled.div`
    display: flex;
    align-items: stretch;
    padding-top: -1px;
    transform: scaleX(-1) scaleY(-1);

    svg {
        width: auto;
        height: 100%;
    }
`;

const ContactWrapper = styled.div`
    display: flex;
`;

const ContactDiv = styled.div`
    flex: 1;
    background-color: white;
`;

const ContactLink = styled.div`
    color: ${blue};
    font-weight: bold;
    padding: 8px 20px;

    ${({ theme }) => css`
        ${theme.paddings.containerOuter.left}
        ${theme.paddings.containerOuter.right}
    `}
`;

const MobileNav = styled.div`
    display: flex;
    flex-direction: column;
    ${({ theme }) => css`
        ${theme.paddings.containerOuter.all}

        padding-bottom: 0 !important;
    `}

    background-color: white;
    color: ${blue};
    ${({ theme }) => theme.breakpoints.from.medium.css`
      overflow-x: hidden;
    `}
`;

const Modal = styled.div`
    position: fixed;
    top: 8vh;
    left: 0;
    z-index: 50;
    display: none;
    width: 100%;
    height: 93svh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &.fade-in {
        ${({ theme }) => theme.breakpoints.upTo.large.css`
            display: block;
            animation: ease 0.5s ${AnimationHelpers.fadeIn} forwards;
        `}
    }

    &.fade-out {
        ${({ theme }) => theme.breakpoints.upTo.large.css`
            display: block;
            animation: ease 0.5s ${AnimationHelpers.fadeOut} forwards;
        `}
    }
`;

const LinkItem = css`
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    font-weight: bold;
    color: ${blue};
    border-bottom: 2px solid ${blue};
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: initial;
`;

const NavBackdrop = styled(Backdrop)`
    top: 8vh;
    z-index: 50;
`;

const LogoutSection = () => {
    const theme = useTheme();
    const containerStyles = css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 20px;
        background-color: ${theme.colors.grey.toString()};
        border-top: 1px solid ${theme.colors.grey.toString()};
    `;

    const { authenticated, email } = useKeycloakOpt();

    if (!authenticated) {
        return null;
    }

    return (
        <div css={containerStyles}>
            {email}
            <LinkComponent
                color="blue"
                button
                link={{ text: "Logout", href: "/logout" }}
            />
        </div>
    );
};

const MobileModal = ({ modalState = null, handleClick, sections, nav }) => {
    const theme = useTheme();
    const { setContactFormVisible } = useStore();
    const isAuthenticated = useIsAuthenticated();
    const showTechnicList = storage.get("technic:saved-products");
    const accountSection = GetAccountSection();

    return (
        <>
            {modalState && <BlurOther except={["Nav"]} />}

            <NavBackdrop onClick={handleClick} open={modalState} />

            <Modal
                className={classNames(
                    modalState != null && {
                        "fade-in": modalState,
                        "fade-out": !modalState,
                    }
                )}
            >
                <Wrapper>
                    <MobileNav>
                        {sections.map((section, i) => {
                            return section.subsections &&
                                section.subsections.length > 0 ? (
                                <MobileDropdown key={i} section={section} />
                            ) : (
                                <Link key={i} to={section.href} css={LinkItem}>
                                    <div>{section.name}</div>
                                    <div>
                                        <IconContainer small>
                                            <ChevronIcon color={blue} />
                                        </IconContainer>
                                    </div>
                                </Link>
                            );
                        })}
                        {isAuthenticated && (
                            <MobileDropdown section={accountSection}>
                                <LogoutSection />
                            </MobileDropdown>
                        )}
                        {storage.get("technic:saved-products") && (
                            <div
                                css={css`
                                    display: block;
                                    ${theme.breakpoints.from.medium.css`
                                   display: none;
                                `}
                                `}
                            >
                                <Link to="/technik/list/" css={LinkItem}>
                                    <div>Meine Merkliste</div>
                                    <div>
                                        <IconContainer small>
                                            <ChevronIcon color={blue} />
                                        </IconContainer>
                                    </div>
                                </Link>
                            </div>
                        )}
                    </MobileNav>
                    <ContactWrapper>
                        <ContactDiv>
                            <ContactLink
                                onClick={() => {
                                    handleClick();
                                    void setContactFormVisible(true);
                                }}
                            >
                                {nav.contactFormName}
                            </ContactLink>
                        </ContactDiv>
                        <ShapeContainer>
                            <ShapeCorner color="white" />
                        </ShapeContainer>
                    </ContactWrapper>
                </Wrapper>
            </Modal>
        </>
    );
};

export default MobileModal;
