import React from "react";

import styled from "@emotion/styled";

const CloseIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18.7 22"
            width="18.7"
            height="22"
        >
            <g transform="translate(8.5 10)">
                <g transform="translate(-8.5 -10)">
                    <path
                        d="M9.4,2H7.6a.632.632,0,0,0-.6.66L5,23.34a.632.632,0,0,0,.6.66H8.4a.632.632,0,0,0,.6-.66L10,2.66A.632.632,0,0,0,9.4,2Z"
                        transform="translate(-5 -2)"
                    />
                    <path
                        d="M21.857,7.471h0l0-.006a1.554,1.554,0,0,0-.784-.822L18.29,5.382a.844.844,0,0,0-.733,0,.98.98,0,0,0-.5.588,1.174,1.174,0,0,0,.582,1.412l.992.45A8.756,8.756,0,0,0,15.788,10.8L15,2.66A.632.632,0,0,0,14.4,2H12.6a.632.632,0,0,0-.6.66l1,20.68a.632.632,0,0,0,.6.66h2.8a.632.632,0,0,0,.6-.66l-.535-5.529c-.279-3.77.726-6.4,3.06-8l-.35,1.176a1.17,1.17,0,0,0,.616,1.4.859.859,0,0,0,.724-.018.978.978,0,0,0,.493-.608l.915-3.08a1.744,1.744,0,0,0-.067-1.2Z"
                        transform="translate(-3.3 -2)"
                    />
                </g>
            </g>
        </SVG>
    );
};

export default CloseIcon;
