import React, {
    createContext,
    FocusEventHandler,
    ReactChildren,
    useContext,
    useState,
} from "react";

import { pipe } from "fp-ts/lib/function";
import * as Ord_ from "fp-ts/lib/Ord";
import * as St from "fp-ts/lib/ReadonlySet";

import { Form } from "react-bulma-components";

import type { PropsOf } from "@emotion/react";
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";

const Label = styled.label`
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;

    abbr {
        text-decoration: none;
    }
`;

type MultiSelectInputContext<a> = {
    value: ReadonlySet<a>;
    name: string;
    onChange: (value: ReadonlySet<a>) => void;
    focus?: a;
    onOptionFocus: (option: a) => void;
    onOptionBlur: () => void;
    Ord: Ord_.Ord<a>;
};

const MultiSelectInputContext = createContext<
    MultiSelectInputContext<any> | undefined
>(undefined);
MultiSelectInputContext.displayName = "MultiSelectInputContext";

export const useMultiSelectInputContext = <a,>():
    | MultiSelectInputContext<a>
    | undefined => useContext(MultiSelectInputContext);

export type MultiSelectInputContainerProps<a> = {
    value: ReadonlySet<a>;
    name: string;
    onChange: (value: ReadonlySet<a>) => void;
    Ord: Ord_.Ord<a>;
} & PropsOf<typeof Form.Field>;

export const MultiSelectInputContainer = <a,>({
    value = new Set<a>(),
    name,
    onChange,
    Ord,
    ...props
}: MultiSelectInputContainerProps<a>) => {
    const [focus, setFocus] = useState<a>();

    return (
        <MultiSelectInputContext.Provider
            value={{
                value,
                name,
                onChange,
                focus,
                onOptionFocus: setFocus,
                onOptionBlur: () => setFocus(undefined),
                Ord,
            }}
        >
            <Form.Field {...props} />
        </MultiSelectInputContext.Provider>
    );
};

const setToggle = <a,>(
    elem: a,
    set: ReadonlySet<a>,
    has: boolean,
    Ord: Ord_.Ord<a>
): ReadonlySet<a> =>
    pipe(set, has ? St.insert(Ord)(elem) : St.remove(Ord)(elem));

export type MultiSelectInputOptionProps<a> = {
    id: string;
    value: a;
    textValue?: a | string;
    children: ReactChildren;
    onFocus: FocusEventHandler<HTMLInputElement>;
    onBlur: FocusEventHandler<HTMLInputElement>;
    gtag: string;
    noInput?: boolean;
} & PropsOf<typeof Form.Control>;

export const MultiSelectInputOption = <a,>({
    id,
    value,
    textValue = value,
    children,
    onFocus,
    onBlur,
    gtag,
    noInput = false,
    ...props
}: MultiSelectInputOptionProps<a>) => {
    const theme = useTheme();

    const active = css`
        font-weight: 500;
        color: ${theme.colors.red.toString()};
    `;

    return (
        <MultiSelectInputContext.Consumer>
            {(ctx: MultiSelectInputContext<a> | undefined) => {
                if (!ctx) return null;

                const {
                    value: multiSelectValue,
                    name,
                    onChange,
                    focus,
                    onOptionFocus,
                    onOptionBlur,
                    Ord,
                } = ctx;

                return (
                    <Form.Control {...{ id }} {...props}>
                        <Label
                            tabIndex={-1}
                            htmlFor={`${id}-key`}
                            className={classNames({
                                "is-focused":
                                    focus != null && Ord.equals(focus, value),
                                "is-selected":
                                    St.elem(Ord)(value, multiSelectValue) &&
                                    active,
                            })}
                            css={
                                St.elem(Ord)(value, multiSelectValue) && active
                            }
                        >
                            <input
                                id={`${id}-key`}
                                type="checkbox"
                                {...{ name }}
                                checked={St.elem(Ord)(value, multiSelectValue)}
                                onChange={(e) =>
                                    onChange(
                                        setToggle(
                                            value,
                                            multiSelectValue,
                                            e.target.checked,
                                            Ord
                                        )
                                    )
                                }
                                onFocus={(e) => {
                                    if (onFocus) {
                                        onFocus(e);
                                    }
                                    if (!e.defaultPrevented) {
                                        onOptionFocus(value);
                                    }
                                }}
                                onBlur={(e) => {
                                    if (onBlur) {
                                        onBlur(e);
                                    }
                                    if (!e.defaultPrevented) {
                                        onOptionBlur();
                                    }
                                }}
                                css={css`
                                    ${noInput && "display: none;"}
                                `}
                                value={
                                    typeof textValue === "string" ||
                                    typeof textValue === "number"
                                        ? textValue
                                        : String(textValue)
                                }
                                data-gtag={gtag}
                            />
                            <span>{children}</span>
                        </Label>
                    </Form.Control>
                );
            }}
        </MultiSelectInputContext.Consumer>
    );
};
