import React, { useCallback, useEffect, useMemo } from "react";

import { css, Global } from "@emotion/react";
import styled from "@emotion/styled";

import { white } from "../../../../utils/colors";
import { FooterContainer } from "../../Footer";
import Icon from "../../icons/Icon";
import useStore from "../store";
import shapeBlue from "./shape-blue.svg";
import shapeGrey from "./shape-grey.svg";
import shapeRed from "./shape-red.svg";

const closedShapeButtonHeight = "45px";

const ShapeButton = ({ name, children, color, offsetLeft, full, ...props }) => {
    const { open, setOpen, close } = useStore();

    const closeStopBubbling = useCallback(
        (e) => {
            e.stopPropagation();
            close();
        },
        [close]
    );

    // eslint-disable-next-line no-restricted-globals
    const screenWidth = screen.width;

    const isOpen = useMemo(() => open === name, [open, name]);

    const colors = {
        red: shapeRed,
        blue: shapeBlue,
    };

    const button = css`
        position: absolute;
        left: -100px;
        display: flex;
        justify-content: center;
        width: 524px;
        height: 290px;
        cursor: pointer;
        filter: drop-shadow(0 2px 4px rgb(0 0 0 / 50%));
        background-repeat: no-repeat;
        background-size: contain;
        transition: all 0.3s ease-in;
        transform: translate(0, 0);

        &.open {
            cursor: initial;
            transform: translate(154px, -215px);
        }

        &:not(.open):hover {
            cursor: pointer;
            filter: brightness(85%) drop-shadow(0 2px 4px rgb(0 0 0 / 50%));
        }
    `;

    const Close = styled.a`
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 100;
        padding: 5px;
        color: white;
        text-decoration: none;

        &:hover {
            color: white;
            filter: brightness(85%);
        }

        svg {
            width: 14px;
            height: 14px;
        }
    `;

    const Filler = styled.div`
        position: absolute;
        right: 170px;
        width: ${screenWidth / 2 + 420}px;
        height: 100%;
        background-color: #e60028;
        transition: none;
    `;

    const Content = styled.div`
        width: 100%;
    `;

    useEffect(() => {
        if (open === "search") {
            setTimeout(() => {
                const element = document.getElementById("global-search");
                if (element) {
                    element.focus();
                }
            }, 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <div
            css={button}
            style={{
                backgroundImage: `url('${colors[color] || shapeGrey}')`,
                ...(offsetLeft ? { left: `${offsetLeft}px` } : {}),
            }}
            className={isOpen ? "open" : ""}
            onClick={() => {
                open !== name && setOpen(name);
            }}
            role={isOpen ? undefined : "button"}
            {...props}
        >
            <Global
                styles={css`
                    ${FooterContainer} {
                        && {
                            padding-bottom: calc(
                                ${closedShapeButtonHeight} + 20px
                            );
                        }
                    }
                `}
            />
            {isOpen && (
                <Close onClick={closeStopBubbling}>
                    <Icon iconName="close" cursor="pointer" color={white} />
                </Close>
            )}
            {full && <Filler />}
            <Content>{children}</Content>
        </div>
    );
};

ShapeButton.Content = styled.div`
    position: absolute;
    top: 55px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
`;
ShapeButton.Content.displayName = "ShapeButton.Content";

ShapeButton.Headline = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${closedShapeButtonHeight};
    padding-left: 120px;
    font-size: 1.3rem;
    font-weight: 900;
    color: white;
`;
ShapeButton.Headline.displayName = "ShapeButton.Headline";

ShapeButton.ContentItem = styled.div`
    right: 0;
    display: flex;
    width: 230px;
    min-height: 30px;
    padding: 5px;
    color: white;
`;
ShapeButton.ContentItem.displayName = "ShapeButton.ContentItem";

export default ShapeButton;
