import React from "react";

import styled from "@emotion/styled";

const PhoneIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            xmlSpace="preserve"
        >
            <g id="Ebene_1-2">
                <path
                    className="st0"
                    d="M16.6,23c-0.3,0-5.5-0.2-10.1-8.8C3.1,6.6,6.7,1.5,6.7,1.5C6.9,1.2,7.2,1,7.6,1c0.9,0,1.5,0.1,2.6,0.4
		C11,1.7,11.7,2,12.3,2.5c0.3,0.2,0.4,0.5,0.4,0.9C12.6,5,12.4,6.6,12,8.1l0,0c-0.2,0.4-0.5,0.5-1.6,0.9c-0.9,0.3-1.8,0.6-2.7,1.1
		c-0.2,0.1-0.3,0.4-0.3,0.6c0.2,0.9,0.5,1.7,0.9,2.5c0.5,1.3,1.3,2.4,2.1,3.5c0.2,0.2,0.4,0.3,0.7,0.1c1-0.5,1.9-1.1,2.8-1.7
		c0.5-0.4,0.7-0.5,1-0.5c0.4,0,4.6,2.1,4.9,2.8c0.2,0.4,0.3,0.6-1.3,4.5C18.3,22.8,17.5,23,16.6,23z M13.1,18.2
		c-0.3,0.2-0.4,0.5-0.2,0.8c0.1,0.3,1.1,0.8,1.7,1.1s1.2,0.5,1.9,0.7c0.3,0.1,0.6-0.1,0.7-0.4c0.2-0.6,0.5-1.2,0.6-1.7
		c0.1-0.3,0-0.6-0.3-0.7c-0.6-0.3-1.4-0.7-2-1.1c-0.2-0.1-0.4,0-0.6,0.1C14.2,17.5,13.6,17.9,13.1,18.2z M8.6,3.1
		C8.3,3,8,3.2,7.9,3.5C7.5,4.3,7.3,5.1,7.1,5.9C7,6.4,7,6.8,7,7.3c0,0.3,0.3,0.6,0.6,0.6c0.2,0,1.8-0.5,2.2-0.7
		C10,7.1,10.2,7,10.2,6.7c0.1-0.6,0.3-1.6,0.4-2.3c0-0.3-0.1-0.4-0.4-0.6C9.5,3.4,9.2,3.3,8.6,3.1z"
                />
            </g>
        </SVG>
    );
};

export default PhoneIcon;
