import React from "react";

import styled from "@emotion/styled";

const ArrowRightIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M6.57863,16.70709a.99437.99437,0,0,0,0-1.41418L4.26764,13H21.99212a1,1,0,1,0,0-2H4.26764L6.57869,8.70709a.99443.99443,0,0,0-.00006-1.41418,1.01342,1.01342,0,0,0-1.42534,0L1.52246,10.74633a1.7258,1.7258,0,0,0-.5208,1.24549L1,12l.00166.00818a1.78335,1.78335,0,0,0,.51463,1.2392l3.637,3.45971A1.0134,1.0134,0,0,0,6.57863,16.70709Z" />
        </SVG>
    );
};

export default ArrowRightIcon;
