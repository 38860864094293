import React from "react";

import styled from "@emotion/styled";

const CafeIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M20.60535,13.27821a2.35611,2.35611,0,0,0-1.62775-.2212c.0024-.3755.00206-1.31794.00133-2.058a1,1,0,0,0-1-.999H3.01784a.99634.99634,0,0,0-1,1.00146c.00757,1.31375.01957,3.6142.01957,4.999,0,1.81579,1.93762,4.58271,2.70185,5.60341A.99367.99367,0,0,0,5.53639,22h9.95812a1.00091,1.00091,0,0,0,.76431-.355,13.4936,13.4936,0,0,0,2.4415-3.78816A2.46794,2.46794,0,0,0,19.50049,18a2.50362,2.50362,0,0,0,2.46627-2.91236A2.5409,2.5409,0,0,0,20.60535,13.27821ZM16.951,15.97491a6.62542,6.62542,0,0,1-1.31974,3.60457.98681.98681,0,0,1-.8064.40825H6.23632a.99387.99387,0,0,1-.8556-.48149A8.32985,8.32985,0,0,1,4.0549,16.00049c0-1.33536-.00922-2.58066-.01665-3.38538a.59785.59785,0,0,1,.6-.60287l11.72511,0a.6.6,0,0,1,.6001.60442C16.95765,13.36536,16.951,14.532,16.951,15.97491Zm2.54949.02234a.49725.49725,0,1,1,.49676-.49774A.49789.49789,0,0,1,19.50049,15.99725Z" />
            <path d="M11.02249,8a.49979.49979,0,0,1-.42131-.76716c.00875-.01319.82123-1.32972.10022-1.94062a2.1734,2.1734,0,0,1-.06228-3.11162.499.499,0,1,1,.76674.63873c-.08562.10548-.82609,1.06163-.06227,1.70817,1.1073.93759.58284,2.47875.09925,3.24152A.49759.49759,0,0,1,11.02249,8Z" />
            <path d="M7.02249,9a.49979.49979,0,0,1-.42131-.76716c.00875-.01319.82123-1.32972.10022-1.94062A2.1734,2.1734,0,0,1,6.63912,3.1806a.499.499,0,1,1,.76674.63873c-.08562.10548-.82609,1.06163-.06227,1.70817,1.1073.93759.58284,2.47875.09925,3.24152A.49759.49759,0,0,1,7.02249,9Z" />
            <path d="M15.02249,9a.49979.49979,0,0,1-.42131-.76716c.00875-.01319.82123-1.32972.10022-1.94062a2.1734,2.1734,0,0,1-.06228-3.11162.499.499,0,1,1,.76674.63873c-.08562.10548-.82609,1.06163-.06227,1.70817,1.1073.93759.58284,2.47875.09925,3.24152A.49759.49759,0,0,1,15.02249,9Z" />
        </SVG>
    );
};

export default CafeIcon;
