import React, { FC } from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

export type IconContainerProps = {
    cursor?: string;
    size?: string;
    sExtraLarge?: string;
    sLarge?: string;
    sNormal?: string;
    sMedium?: string;
    sSmall?: string;
    sExtraSmall?: string;
    onClick?: () => void;
    role?: string;
    title?: string;
};

export const defaultSize = "25px";
const IconContainer = styled<FC<IconContainerProps>>(
    ({
        cursor,
        size,
        sExtraLarge,
        sLarge,
        sNormal,
        sMedium,
        sSmall,
        sExtraSmall,
        onClick,
        role = onClick ? "button" : undefined,
        ...props
    }) => <div {...{ onClick, role, ...props }} />
)`
    cursor: ${({ cursor = "normal" }) => cursor};
    ${({
        theme,
        size = defaultSize,
        sExtraLarge: extraLarge = size,
        sLarge: large = size,
        sNormal: normal = size,
        sMedium: medium = size,
        sSmall: small = size,
        sExtraSmall: extraSmall = size,
    }) => css`
        ${theme.breakpoints.propPerEachFrom("width", {
            extraLarge,
            large,
            normal,
            medium,
            small,
            extraSmall,
        })}
        ${theme.breakpoints.propPerEachFrom("height", {
            extraLarge,
            large,
            normal,
            medium,
            small,
            extraSmall,
        })}
    `}
`;
export default IconContainer;
