import React from "react";

import styled from "@emotion/styled";

import { iSvgIconProps } from "../types";

const CopyIcon = ({ color = "#f0f0f0", hoverColor = color }: iSvgIconProps) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG
            id="Ebene_2"
            data-name="Ebene 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 34.52 26"
        >
            <defs></defs>
            <path
                id="Pfad_8697"
                data-name="Pfad 8697"
                className="cls-1"
                d="m0,8.36v17.64h24.72v-5.91h-2.77v3.14H2.77v-12.1h4.55v-2.78H0Z"
            />
            <path
                id="Pfad_8698"
                data-name="Pfad 8698"
                className="cls-1"
                d="m12.57,2.77h15.09l4.09,4.1v8H12.57V2.77Zm-2.77-2.77v17.64h24.72V5.73L28.81,0H9.8Z"
            />
        </SVG>
    );
};

export default CopyIcon;
