import React, { FC, HTMLAttributes } from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Theme } from "../../../Theme";

export type Level = 1 | 2 | 3 | 4 | 5;

export type HeadlineProps = {
    level?: Level;
    levelStyle?: Level | "default" | null;
    center?: boolean;
    light?: boolean;
    underline?: boolean;
    color?: keyof Theme["colors"];
} & HTMLAttributes<HTMLDivElement | HTMLHeadingElement>;

const RawHeadline: FC<HeadlineProps> = ({
    level,
    levelStyle,
    center,
    light,
    underline,
    color,
    ...props
}) => {
    const Tag: "div" | `h${Level}` = level == null ? "div" : `h${level}`;

    return <Tag {...props} />;
};
const defaultColor: keyof Theme["colors"] = "red";
RawHeadline.defaultProps = {
    levelStyle: "default",
    center: false,
    light: false,
    underline: false,
    color: defaultColor,
};

const Headline = styled(RawHeadline)`
    margin-bottom: 10px;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 30px;
    color: ${({ theme, color = defaultColor }) =>
        theme.colors[color].toString()};

    &.def {
        font-size: 1.5em !important;
        font-weight: 700 !important;
    }

    ${({ center }) =>
        center &&
        css`
            text-align: center;
        `}

    ${({ light }) =>
        light &&
        css`
            font-weight: 100 !important;
        `}

    ${({ theme, underline, color = defaultColor }) =>
        underline &&
        css`
            border-bottom: 4px solid ${theme.colors[color].toString()};
        `}

    ${({ levelStyle }) =>
        levelStyle != null &&
        css`
            font-weight: 900 !important;
            ${levelStyle !== "default" &&
            css`
                font-size: ${["2em", "1.5em", "1.2em", "1.1em", "1em"][
                    levelStyle - 1
                ]} !important;
            `}
        `}
`;
export default Headline;
