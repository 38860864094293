import React from "react";

import styled from "@emotion/styled";

const BistroIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <rect x="1" y="16" width="15" height="2" rx="0.6" />
            <path d="M15.4,15H1.6a.57062.57062,0,0,1-.6-.53576v-.71435C1,13.22491,3.56105,11,8.5,11S16,13.22491,16,13.74989v.71435A.57062.57062,0,0,1,15.4,15Z" />
            <path d="M1.6,19H15.4a.6.6,0,0,1,.6.6V20a1,1,0,0,1-1,1H2a1,1,0,0,1-1-1v-.4A.6.6,0,0,1,1.6,19Z" />
            <path d="M22.76013,6.32684A.96384.96384,0,0,0,22.03723,6H18.5V3.92792a1.0028,1.0028,0,0,0-2,0V6H12.96271A.96367.96367,0,0,0,12.017,7.14465l.39953,2.08246a.963.963,0,1,0,1.89142-.363l-.18048-.93774H20.943L19.515,19.07367H18.27789a.96317.96317,0,0,0,0,1.92633H20.362a.96381.96381,0,0,0,.95514-.84088L22.99231,7.08545A.96653.96653,0,0,0,22.76013,6.32684Z" />
        </SVG>
    );
};

export default BistroIcon;
