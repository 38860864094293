import React, { useEffect, useState } from "react";

import { useLocation } from "@gatsbyjs/reach-router";

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import FadeIn from "../../../animations/fadeInAnimation";
import { NewsletterPopupManager } from "../../../utils/NewsletterPopupManager";
import { IconPredefined } from "../icons/Icon";
import { PredefinedIconType } from "../icons/Icon/Predefined";
import NewHeadline from "../NewHeadline";
import NewsletterForm from "./NewsletterForm";
import useNewsletterModalStore from "./store";

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0 0 0 / 50%);
    transition: opacity 0.3s ease-out;
`;

const PopUpContainer = styled.div`
    z-index: 100;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto; /* Make content vertically scrollable */
    background: ${({ theme }) => theme.colors.white.toString()};
    border-radius: 8px;
    ${({ theme }) => theme.breakpoints.upTo.medium.css`
        margin: 5em 0.5em 0.5em;
    `}

    animation: slide-in 0.3s ease-out;

    @keyframes slide-in {
        from {
            opacity: 0;
            transform: translateY(20px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
`;

const Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2em 2em 1em;
`;

const HeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
`;

const Content = styled.div`
    padding: 0 2em 1em;
`;

const Footer = styled.section`
    padding: 0 2em 1em;
`;

const FooterLink = styled.a`
    color: ${({ theme }) => theme.colors.darkGrey.toString()};
    text-decoration: underline;
`;

const SmallText = styled.small`
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.darkGrey.toString()};
`;

const CloseButton = styled.button`
    flex-shrink: 0;
    margin-left: 1em; // Add some space between the headline and the button
    font-size: 42px;
    color: ${({ theme }) => theme.colors.blue.toString()};
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0; // Remove default padding
`;

const NewsletterModal: React.FC = () => {
    const theme = useTheme();
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const location = useLocation();
    const { closeNewsletterModal, isNewsletterModalOpen, openNewsletterModal } =
        useNewsletterModalStore();

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined;
        // Register the escape key to close the Modal.
        // Does currently not work due to limitations in Gatsby.
        /*if (typeof window !== "undefined") {
            window.addEventListener("keydown", handleKeyDown);
        }*/

        // The modal shows after a delay.
        // It checks values from the localStorage:
        // 1. If the user has closed the modal, it will not re-appear for two weeks.
        // 2. If the user registered for the newsletter, it will be permanently closed.
        if (NewsletterPopupManager.shouldShowPopup(location)) {
            timer = setTimeout(() => {
                openNewsletterModal();
            }, 10000); // show popup after 10 Seconds.
        }

        // Unregister on dismount
        return () => {
            /*if (typeof window !== "undefined") {
                window.removeEventListener("keydown", handleKeyDown);
            }*/
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, []);

    if (!isNewsletterModalOpen) return null;

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            closeNewsletterModal();
        }
    };

    return (
        <FadeIn duration="0.5s" className="newsletter-popup-modal">
            <Backdrop
                onClick={() => closeNewsletterModal()}
                role="dialog"
                aria-modal="true"
                aria-labelledby="newsletter-popup-titel"
            >
                <PopUpContainer
                    onClick={(e) => e.stopPropagation()}
                    role="document"
                >
                    <Header>
                        <HeaderContent>
                            <NewHeadline
                                id="newsletter-popup-titel"
                                lookLike={1}
                            >
                                {successMessage
                                    ? "Vielen Dank für Deine Anmeldung"
                                    : "Jetzt zum neuen Newsletter anmelden"}
                            </NewHeadline>
                            <CloseButton
                                onClick={() => closeNewsletterModal()}
                                aria-label="Popup Schließen"
                            >
                                <IconPredefined
                                    color={theme.colors.blue.toString()}
                                    type={PredefinedIconType.close}
                                    size="28px"
                                />
                            </CloseButton>
                        </HeaderContent>
                    </Header>
                    <Content>
                        {successMessage && <section>{successMessage}</section>}
                        {!successMessage && (
                            <>
                                <p>
                                    Erhalte regelmäßig Neuigkeiten und Angebote
                                    der Hoyer Welt direkt in Dein Postfach.
                                </p>
                                <NewsletterForm
                                    onSuccess={(msg) => setSuccessMessage(msg)}
                                />
                            </>
                        )}
                    </Content>

                    {!successMessage && (
                        <Footer>
                            <SmallText>
                                Für den Versand unserer Newsletter nutzen wir
                                CleverReach. Mit Deiner Anmeldung stimmst Du zu,
                                dass die eingegebenen Daten an{" "}
                                <FooterLink
                                    href="https://www.cleverreach.com/"
                                    target="_blank"
                                >
                                    CleverReach GmbH & Co. KG.
                                </FooterLink>{" "}
                                übermittelt werden. Beachte auch bitte auch die{" "}
                                <FooterLink href="/agb" target="_blank">
                                    AGB
                                </FooterLink>{" "}
                                und{" "}
                                <FooterLink href="/datenschutz" target="_blank">
                                    Datenschutzbestimmungen
                                </FooterLink>
                                .
                            </SmallText>
                        </Footer>
                    )}
                </PopUpContainer>
            </Backdrop>
        </FadeIn>
    );
};
export default NewsletterModal;
