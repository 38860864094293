import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const margin = ({ top, right, bottom, left }) => css`
    ${top != null &&
    css`
        margin-top: ${top}px;
    `}
    ${right != null &&
    css`
        margin-right: ${right}px;
    `}
    ${bottom != null &&
    css`
        margin-bottom: ${bottom}px;
    `}
    ${left != null &&
    css`
        margin-left: ${left}px;
    `}
`;
const Margin = styled(
    ({ top, right, bottom, left, Component = "div", ...props }) => (
        <Component {...props} />
    )
)`
    ${margin}
`;

export default Margin;
