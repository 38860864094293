import { apiFetch } from "../../../apiBindings";
import { storageSession } from "../../../utils/StorageHandler";
import { iCheckoutForm } from "./Checkout/store";
import { cartStorageKey, iShopwareCart } from "./ShoppingCart/store";
import {
    iShopwareCategory,
    iShopwareProduct,
    iShopwareProductListing,
    iShopwareSort,
} from "./store";

export const fetchCategories = async (
    shopwareMainCategoryId: string
): Promise<Array<iShopwareCategory>> => {
    const response = await apiFetch(
        `/shop/categories/${shopwareMainCategoryId}/children`
    );
    return await response.json();
};

export const fetchProducts = async (
    categoryId: string,
    sort: iShopwareSort,
    search?: string
): Promise<iShopwareProductListing> => {
    let url = `/shop/products/category/${categoryId}`;
    const params = [];
    if (sort && sort.key) {
        params.push(`sort=${sort.key}`);
    }

    if (search) {
        params.push(`search=${search}`);
    }

    if (params.length > 0) {
        url += `?${params.join("&")}`;
    }
    const response = await apiFetch(url);
    return await response.json();
};

export const fetchProduct = async (
    productId: string
): Promise<iShopwareProduct> => {
    const response = await apiFetch(`/shop/products/${productId}`);
    return await response.json();
};

export const fetchCart = async (): Promise<iShopwareCart> => {
    const cartToken = storageSession.get(cartStorageKey);
    const response = await apiFetch(`/shop/cart/${cartToken || ""}`);
    const data = await response.json();
    storageSession.set(cartStorageKey, data.token);
    return data;
};

export const addProductToCart = async (
    productId: string,
    quantity = 1
): Promise<iShopwareCart> => {
    const cartToken = storageSession.get(cartStorageKey);
    const response = await apiFetch(`/shop/cart/${cartToken}/add-product`, {
        method: "POST",
        body: JSON.stringify({ productId, quantity }),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    return await response.json();
};

export const updateProductInCart = async (
    cartItemId: string,
    quantity: number
): Promise<iShopwareCart> => {
    const cartToken = storageSession.get(cartStorageKey);
    const response = await apiFetch(`/shop/cart/${cartToken}/update-product`, {
        method: "PUT",
        body: JSON.stringify({ cartItemId, quantity }),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    return await response.json();
};

export const deleteProductFromCart = async (
    cartItemId: string
): Promise<iShopwareCart> => {
    const cartToken = storageSession.get(cartStorageKey);
    const response = await apiFetch(`/shop/cart/${cartToken}/remove-product`, {
        method: "DELETE",
        body: JSON.stringify({ cartItemId }),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    return await response.json();
};

export const fetchRelatedProducts = async (
    productId: string
): Promise<Array<iShopwareProduct>> => {
    const response = await apiFetch(`/shop/products/${productId}/suggestions`);
    return await response.json();
};

export const fetchPaymentMethods = async () => {
    const response = await apiFetch("/shop/payment-methods");
    return await response.json();
};

export const fetchShippingMethods = async () => {
    const response = await apiFetch("/shop/shipping-methods");
    return await response.json();
};

export const fetchSalutations = async () => {
    const response = await apiFetch("/shop/salutations");
    return await response.json();
};

export const fetchCountries = async () => {
    const response = await apiFetch("/shop/countries");
    return await response.json();
};

interface iContextTokenOptions {
    paymentMethodId?: string;
    shippingMethodId?: string;
}

export const updateContextOptions = async (options: iContextTokenOptions) => {
    const cartToken = storageSession.get(cartStorageKey);
    const response = await apiFetch(`/shop/${cartToken}/update`, {
        method: "PUT",
        body: JSON.stringify(options),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    return await response.json();
};

export const createOrder = async (form: iCheckoutForm) => {
    const cartToken = storageSession.get(cartStorageKey);
    const response = await apiFetch(`/shop/${cartToken}/order`, {
        method: "POST",
        body: JSON.stringify(form),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    const data = await response.json();
    if (response.ok) {
        //Update the Context-Token for the freshly registered guest customer.
        storageSession.set(cartStorageKey, data.token);
        return data;
    }
    throw data;
};

export const confirmNewsletterDoubleOptIn = async (
    emailHash: string,
    hash: string
) => {
    const response = await apiFetch("/shop/newsletter/confirm-double-opt-in", {
        method: "POST",
        body: JSON.stringify({ emailHash, hash }),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    const data = await response.json();
    if (response.ok) {
        return data;
    }
    throw data;
};
