import React, { useEffect, useState } from "react";

import { css, keyframes, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { apiFetch } from "../../../../apiBindings";
import pushToAnalytics from "../../../../utils/analytics";
import StorageHandler from "../../../../utils/StorageHandler";
import Backdrop from "../../Backdrop";
import { BlurOther } from "../../Blur";
import FormError from "../../forms/FormError";
import NewCheckbox from "../../forms/NewCheckbox";
import NewInput from "../../forms/NewInput";
import Icon from "../../icons/Icon";
import LoadingIndicator from "../../LoadingIndicator";
import Margin from "../../Margin";
import Button from "../../NewForms/Button";
import NewHeadline from "../../NewHeadline";
import useStore from "./store";

const slideIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-100%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const slideOut = keyframes`
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-100%);
    }
`;

const Container = styled.div`
    border-bottom-left-radius: 30px 30px;
    padding: 30px;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 77px;
    z-index: 111;
    transform: translateY(-100%);
    opacity: 0;

    &.open {
        opacity: 0;
        transform: translateY(-100%);
        animation: ease 0.25s ${slideIn} forwards;
    }

    &.close {
        opacity: 1;
        transform: translateY(0);
        animation: ease 0.25s ${slideOut} forwards;
    }

    ${({ theme }) => theme.breakpoints.upTo.small.css`
        right: 10px;
        left: 10px;
        max-height: 580px;
        overflow: scroll;
        border-radius: 4px;
    `}

    &:hover {
        visibility: visible;
    }
`;

const ContactFormBackdrop = styled(Backdrop)`
    z-index: 101;
`;

const PrivacyLink = styled.a`
    text-decoration: none;
`;

const HeadlineWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const CloseIcon = styled(({ onClick, ...props }) => {
    const theme = useTheme();
    return (
        <button {...props}>
            <Icon
                size="16px"
                iconName="close"
                color={theme.colors.red.toString()}
                handleClick={onClick}
            />
        </button>
    );
})`
    all: unset;
    display: flex;
    cursor: pointer;
    opacity: 1;
    transition: opacity 100ms;

    ${({ theme }) => theme.selectors.hover.css`
        opacity: 0.70;
    `}
`;

const ContactForm = () => {
    const { modalState, setModalState, setContactFormVisible } = useStore();
    const storage = new StorageHandler();

    useEffect(() => {
        setModalState("open");
    }, [setModalState]);

    /**
     * If the user didn't sent something or the last sending ist older or equals 5 minutes ago,
     * he can send again.
     */
    const canSend = (timestamp) => {
        if (timestamp === null) {
            return true;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return Math.floor((Date.now() - parseInt(timestamp)) / 1000 / 60) >= 5;
    };

    const [sending, setSending] = useState(false);
    const [sendingError, setSendingError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [data, setData] = useState({
        name: "",
        phone: "",
        email: "",
        subject: "",
        message: "",
        privacy: false,
    });
    const [emailSent, setEmailSent] = useState(
        canSend(storage.get("general:contact-form:sent-at", null))
            ? false
            : true
    );

    const getZipcode = () => {
        const lastCalculator = storage.get("last-calculator");
        if (lastCalculator === null) {
            return null;
        }
        const zipcodes = {
            holzpellets: storage.get("pel:zipcode"),
            heizoel: storage.get("hel:zipcode"),
            erdgas: storage.get("gas:zipcode"),
            strom: storage.get("ele:zipcode"),
        };
        return zipcodes[lastCalculator] || null;
    };

    /**
     * When the user clicks on the button.
     */
    const onSubmit = (event) => {
        event.preventDefault();

        // Check if the fields are valid
        const localErrors = [];
        // eslint-disable-next-line array-callback-return
        Object.keys(data).map((item) => {
            if (data[item] === "" || data[item] === false) {
                localErrors.push(item);
            }
        });

        if (localErrors.length > 0) {
            setErrors([...errors, ...localErrors]);
            return;
        }

        if (errors.length === 0) {
            setSending(true);
            const payload = { ...data, zipcode: getZipcode() };
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            apiFetch("/contact/general", {
                method: "post",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((responseData) => {
                    if (responseData.success) {
                        storage.set("general:contact-form:sent-at", Date.now());
                        storage.set("app:uid", responseData.appUid);
                        setEmailSent(true);
                        setSendingError(false);
                        pushToAnalytics("contact-form:sent", {
                            form: "general:contact-form",
                        });
                    } else {
                        setSendingError(true);
                    }
                    setSending(false);
                });
        }
    };

    /**
     * On change of the input fields.
     */
    const onChange = (event) => {
        setData({ ...data, ...{ [event.target.name]: event.target.value } });
    };

    const addError = (field) => {
        if (!errors.includes(String(field))) {
            setErrors([...errors, field]);
        }
    };

    const hasErrorAt = (field) => {
        return errors.filter((item) => item === field).length > 0;
    };

    const clearError = (field) => {
        setErrors(errors.filter((item) => item !== field));
    };

    const theme = useTheme();
    const isMedium = theme.breakpoints.from.medium.useMatches();

    return (
        <>
            <ContactFormBackdrop
                open={modalState === "open"}
                onClick={() => setContactFormVisible(false)}
            />
            {modalState === "open" && (
                <BlurOther except={isMedium ? ["Nav"] : []} />
            )}

            <Container className={modalState}>
                {sendingError ? (
                    <>
                        <NewHeadline lookLike="2">Entschulding</NewHeadline>
                        <div>
                            Es ist leider ein Fehler aufgetreten. Versuche es
                            bitte erneut.
                        </div>
                        <Margin
                            Component={Button}
                            top="10"
                            fullWidth
                            onClick={() => setSendingError(false)}
                        >
                            Zum Formular
                        </Margin>
                    </>
                ) : sending ? (
                    <LoadingIndicator>
                        Deine Nachricht wird übermittelt. Habe bitte einen
                        Moment Geduld.
                    </LoadingIndicator>
                ) : emailSent ? (
                    <>
                        <HeadlineWrapper>
                            <NewHeadline lookLike="2">
                                Vielen Dank für deine Anfrage
                            </NewHeadline>
                            <CloseIcon
                                onClick={() => setContactFormVisible(false)}
                            />
                        </HeadlineWrapper>
                        <div>
                            Wir haben deine Anfrage erhalten und melden uns in
                            Kürze bei dir.
                        </div>
                    </>
                ) : (
                    <>
                        <HeadlineWrapper>
                            <NewHeadline lookLike="2">Nachricht</NewHeadline>
                            <CloseIcon
                                onClick={() => setContactFormVisible(false)}
                            />
                        </HeadlineWrapper>

                        <form onSubmit={onSubmit}>
                            <NewInput
                                value={data.name}
                                onChange={onChange}
                                placeholder="Name"
                                validation="name"
                                name="name"
                                onError={() => addError("name")}
                                onErrorSolved={() => clearError("name")}
                                forceError={hasErrorAt("name")}
                            />
                            <NewInput
                                value={data.phone}
                                onChange={onChange}
                                placeholder="Telefon"
                                validation="phone"
                                name="phone"
                                onError={() => addError("phone")}
                                onErrorSolved={() => clearError("phone")}
                                forceError={hasErrorAt("phone")}
                            />
                            <NewInput
                                value={data.email}
                                onChange={onChange}
                                placeholder="E-Mail"
                                validation="email"
                                name="email"
                                onError={() => addError("email")}
                                onErrorSolved={() => clearError("email")}
                                forceError={hasErrorAt("email")}
                            />
                            <NewInput
                                value={data.subject}
                                onChange={onChange}
                                placeholder="Betreff"
                                validation="subject"
                                name="subject"
                                onError={() => addError("subject")}
                                onErrorSolved={() => clearError("subject")}
                                forceError={hasErrorAt("subject")}
                            />
                            <NewInput
                                css={css`
                                    height: auto !important;
                                `}
                                type="textarea"
                                placeholder="Ihre Nachricht"
                                validation="message"
                                name="message"
                                onChange={onChange}
                                value={data.message}
                                onError={() => addError("message")}
                                onErrorSolved={() => clearError("message")}
                                forceError={hasErrorAt("message")}
                            />
                            <div>
                                <NewCheckbox
                                    checked={data.privacy}
                                    onClick={() => {
                                        if (!data.privacy) {
                                            clearError("privacy");
                                        } else {
                                            addError("privacy");
                                        }
                                        setData({
                                            ...data,
                                            privacy: !data.privacy,
                                        });
                                    }}
                                    label={
                                        <span>
                                            Ich habe die{" "}
                                            <PrivacyLink
                                                href="/datenschutz"
                                                target="blank"
                                            >
                                                Datenschutzerklärung
                                            </PrivacyLink>{" "}
                                            zur Kenntnis genommen.
                                        </span>
                                    }
                                />
                                {hasErrorAt("privacy") && (
                                    <FormError message="Akzeptiere bitte die Datenschutzerklärung." />
                                )}
                            </div>
                            <Margin top="20">
                                <Button
                                    fullWidth
                                    data-gtag="main:contact-form:button"
                                >
                                    Anfrage Senden
                                </Button>
                            </Margin>
                        </form>
                    </>
                )}
            </Container>
        </>
    );
};

export default ContactForm;
