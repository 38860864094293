import React, { createContext, useEffect, useState } from "react";

import dayjs from "dayjs";

import { apiFetch } from "../apiBindings";
import { consentKeys } from "../utils/consentHelper";
import StorageHandler from "../utils/StorageHandler";

const storage = new StorageHandler();

export const DataContext = createContext();
DataContext.displayName = "CookieConsentContext";

/**
 * Check if the consent shoud be visible.
 */
const shouldBeVisible = () => {
    // Didn't confirm at all.
    if (!storage.getBoolean(consentKeys.general)) {
        return true;
    }

    // app:consent:submitted-at is a new field. So everyone who not accepted all cookies will see the consent again.
    if (
        storage.get(consentKeys.consentSubmittedAt) === null &&
        (!storage.getBoolean(consentKeys.analytics) ||
            !storage.getBoolean(consentKeys.marketing) ||
            !storage.getBoolean(consentKeys.youtube))
    ) {
        return true;
    }

    // If the user didn't confirm the analytics consent, we will ask him 1 month later again.
    if (
        (!storage.getBoolean(consentKeys.analytics) ||
            !storage.getBoolean(consentKeys.marketing)) &&
        (!storage.get(consentKeys.analyticsChangedAt) ||
            !storage.get(consentKeys.marketingChangedAt) ||
            dayjs
                .unix(storage.get(consentKeys.analyticsChangedAt))
                .isBefore(dayjs().subtract(1, "months")) ||
            dayjs
                .unix(storage.get(consentKeys.marketingChangedAt))
                .isBefore(dayjs().subtract(1, "months")))
    ) {
        return true;
    }

    return false;
};

const CookieConsentProvider = ({ children }) => {
    const [accepted, setAccepted] = useState(
        storage.getBoolean(consentKeys.general)
    );
    const [open, setOpen] = useState(shouldBeVisible());
    const [acceptedAnalytics, setAcceptedAnalytics] = useState(
        storage.getBoolean(consentKeys.analytics)
    );
    const [acceptedMarketing, setAcceptedMarketing] = useState(
        storage.getBoolean(consentKeys.marketing)
    );
    const [acceptedYoutube, setAcceptedYoutube] = useState(
        storage.getBoolean(consentKeys.youtube)
    );
    const [acceptedGoogleMapsAndFonts, setAcceptedGoogleMapsAndFonts] =
        useState(storage.getBoolean(consentKeys.googleMapsAndFonts));
    const [backdropCanClose, setBackdropCanClose] = useState(
        storage.getBoolean(consentKeys.general) &&
            storage.get(consentKeys.consentSubmittedAt) === null &&
            storage.getBoolean(consentKeys.analytics) &&
            storage.getBoolean(consentKeys.marketing) &&
            storage.getBoolean(consentKeys.youtube)
    );

    const relevantCookies = true;

    /**
     * When the user changes the checkbox value. Just for information, has no influence on the analytics.
     */
    useEffect(() => {
        storage.set(consentKeys.analyticsPreselect, acceptedAnalytics);
    }, [acceptedAnalytics]);
    useEffect(() => {
        storage.set(consentKeys.youtubePreselect, acceptedYoutube);
    }, [acceptedYoutube]);
    useEffect(() => {
        storage.set(consentKeys.marketingPreselect, acceptedMarketing);
    }, [acceptedMarketing]);
    useEffect(() => {
        storage.set(
            consentKeys.googleMapsAndFontsPreselect,
            acceptedGoogleMapsAndFonts
        );
    }, [acceptedGoogleMapsAndFonts]);

    const storeToServer = async () => {
        const payload = {
            client_id: storage.get("client_id"),
            consent: {
                general: storage.getBoolean(consentKeys.general),
                analytics: storage.getBoolean(consentKeys.analytics),
                marketing: storage.getBoolean(consentKeys.marketing),
                youtube: storage.getBoolean(consentKeys.youtube),
                analyticsPresellect: storage.getBoolean(
                    consentKeys.analyticsPreselect
                ),
                marketingPresellect: storage.getBoolean(
                    consentKeys.marketingPresellect
                ),
                youtubePresellect: storage.getBoolean(
                    consentKeys.youtubePreselect
                ),
            },
        };
        apiFetch("/app/client", {
            method: "post",
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((data) => storage.set("client_id", data.client_id));
    };

    /**
     * Accept the consent and close it. It won't appear again.
     */
    const acceptAll = () => {
        storage.set(consentKeys.general, true);
        storage.set(consentKeys.analytics, true);
        storage.set(consentKeys.marketing, true);
        storage.set(consentKeys.youtube, true);
        storage.set(consentKeys.googleMapsAndFonts, true);
        storage.set(consentKeys.consentSubmittedAt, dayjs().unix());

        storeToServer();

        setAccepted(true);
        setAcceptedAnalytics(true);
        setAcceptedMarketing(true);
        setAcceptedYoutube(true);
        setOpen(false);
        setBackdropCanClose(true);
    };

    /**
     * Save the selected options.
     */
    const save = () => {
        storage.set(consentKeys.general, true);
        storage.set(consentKeys.analytics, acceptedAnalytics);
        storage.set(consentKeys.marketing, acceptedMarketing);
        storage.set(consentKeys.youtube, acceptedYoutube);
        storage.set(consentKeys.googleMapsAndFonts, acceptedGoogleMapsAndFonts);
        storage.set(consentKeys.analyticsChangedAt, dayjs().unix());
        storage.set(consentKeys.marketingChangedAt, dayjs().unix());
        storage.set(consentKeys.consentSubmittedAt, dayjs().unix());

        storeToServer();

        setAccepted(true);
        setOpen(false);
        setBackdropCanClose(true);
    };

    const data = {
        open,
        accepted,
        acceptedAnalytics,
        acceptedYoutube,
        relevantCookies,
        backdropCanClose,
        acceptedMarketing,
        acceptedGoogleMapsAndFonts,
    };

    const actions = {
        setOpen,
        acceptAll,
        save,
        setAcceptedAnalytics,
        setAcceptedYoutube,
        setBackdropCanClose,
        setAcceptedMarketing,
        setAcceptedGoogleMapsAndFonts,
    };

    return (
        <DataContext.Provider
            value={{
                data,
                actions,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};

export default CookieConsentProvider;
