import { renderChildrenWithProps } from "../../../utils/renderChildren";
import StorageHandler from "../../../utils/StorageHandler";

const storage = new StorageHandler();

/**
 * Adds an event listener to the children, which set a value in the local
 * storage to identify which content was clicked on the site.
 *
 * IMPORTANT:
 * The child / children must allow the onClick property.
 * Otherwise the event handler can't be attached.
 */
const TrackableContent = ({ name, children }) => {
    const onClick = () => {
        storage.set("app:current-section", name);
    };

    if (children) {
        return renderChildrenWithProps(children, {
            onClick: onClick,
        });
    }

    return null;
};

export default TrackableContent;
