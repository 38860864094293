import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

const Backdrop = styled(({ open, ...props }) => (
    <div aria-hidden={!open} {...props} />
))`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background-color: rgb(0 0 0 / 60%);
    transition: opacity 500ms;
    opacity: 0;
    pointer-events: none;

    ${({ open }) =>
        open &&
        css`
            pointer-events: initial;
            opacity: 1;
        `};
`;
export default Backdrop;
