import React, { useEffect } from "react";

import { navigate } from "../../../../hooks/navigation";

export type RedirectProps = { to: string };

const Redirect = ({ to }: RedirectProps) => {
    useEffect(() => void navigate(to, { replace: true }), [to]);

    return null;
};
export default Redirect;
