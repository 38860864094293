import React from "react";

import styled from "@emotion/styled";

const ArrowRightIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M17.42137,7.29291a.99437.99437,0,0,0,0,1.41418L19.73236,11H2.00788a1,1,0,1,0,0,2H19.73236l-2.31105,2.29291a.99443.99443,0,0,0,.00006,1.41418,1.0134,1.0134,0,0,0,1.42534,0l3.63083-3.45342a1.7258,1.7258,0,0,0,.5208-1.24549L23,12l-.00166-.00818a1.78335,1.78335,0,0,0-.51463-1.2392l-3.637-3.45971A1.0134,1.0134,0,0,0,17.42137,7.29291Z" />
        </SVG>
    );
};

export default ArrowRightIcon;
