import React from "react";

export const renderChildrenWithProps = (children, props) => {
    if (children) {
        if (Array.isArray(children)) {
            return children.map((child, index) => {
                return renderWithProps(child, props, index);
            });
        }
        return renderWithProps(children, props);
    }
};

const renderWithProps = (child, props, index = 0) =>
    React.cloneElement(child, {
        ...props,
        ...{ key: index },
    });
