import React from "react";

import styled from "@emotion/styled";

const MailIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
        >
            <path d="M20.93051,20H3.06949A2.04032,2.04032,0,0,1,1,17.9957V6.00477A2.04041,2.04041,0,0,1,3.06949,4h17.861A2.04041,2.04041,0,0,1,23,6.00477V17.9957A2.04032,2.04032,0,0,1,20.93051,20ZM3.06949,5.90478a.10138.10138,0,0,0-.10275.1V17.9957a.10129.10129,0,0,0,.10275.09952h17.861a.10129.10129,0,0,0,.10275-.09952V6.00477a.10138.10138,0,0,0-.10275-.1Z" />
            <path d="M12.43376,13.39046a.91516.91516,0,0,1-.86835,0L5.01782,9.88178a1.01782,1.01782,0,0,1-.41277-1.33994.93386.93386,0,0,1,1.28107-.43206l5.853,3.1366a.5491.5491,0,0,0,.521,0L18.114,8.10978a.93281.93281,0,0,1,1.28107.43206,1.01781,1.01781,0,0,1-.41276,1.33994Z" />
        </SVG>
    );
};

export default MailIcon;
