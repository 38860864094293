// extracted by mini-css-extract-plugin
export var headline = "style-module--headline--wG2LG";
export var def = "style-module--def--WwS7x";
export var center = "style-module--center--TsgA6";
export var light = "style-module--light--C-JYF";
export var lookLike = "style-module--lookLike--SBOzi";
export var one = "style-module--one--cpXau";
export var two = "style-module--two--9ygae";
export var three = "style-module--three--ge3Vy";
export var four = "style-module--four--3c8Gw";
export var five = "style-module--five--kQ2Kl";