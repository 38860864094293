import React from "react";

const Field = ({ children, ...props }) => {
    return (
        <div className="field" {...props}>
            <div className="control">{children}</div>
        </div>
    );
};

export const withField = (component) => <Field>{component}</Field>;

export default Field;
