import React from "react";

import cls from "../../../utils/cls";
import * as styles from "./style.module.scss";

/** @deprecated Use components/base/atoms/Headline instead */
const Headline = ({
    is = null,
    lookLike = "default",
    children,
    className = "",
    center = false,
    light = false,
    ...headlineProps
}) => {
    let Tag = "div";
    if (is && is >= 1 && is <= 5) {
        Tag = `h${parseInt(is)}`;
    }

    let localClassName = cls(
        styles.headline,
        className,
        center && styles.center,
        light && styles.light
    );

    if (lookLike !== null) {
        const headlines = {
            1: "one",
            2: "two",
            3: "three",
            4: "four",
            5: "five",
            default: "default",
        };
        if (headlines[lookLike]) {
            localClassName = cls(
                localClassName,
                styles.lookLike,
                styles[
                    headlines[lookLike] === "default"
                        ? "def"
                        : headlines[lookLike]
                ],
                "headline"
            );
        }
    }

    return (
        <Tag className={localClassName} {...headlineProps}>
            {children}
        </Tag>
    );
};

export default Headline;
