import React from "react";

import styled from "@emotion/styled";

const ShopIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M21.4,10H16.69153L14.01062,2.88226a.993.993,0,0,0-.23041-.35687,1.82117,1.82117,0,0,0-2.55945,0,.98886.98886,0,0,0-.2304.35687L8.30853,10H2.6a.6.6,0,0,0-.6.6L3,21.4a.6.6,0,0,0,.6.6H20.4a.6.6,0,0,0,.6-.6l1-10.8A.6.6,0,0,0,21.4,10ZM12.5,4.54547,14.55487,10H10.44513ZM19.33314,19.4a.6.6,0,0,1-.6.6H5.23317a.6.6,0,0,1-.6-.6L4,12.6a.6.6,0,0,1,.6-.6H19.4a.6.6,0,0,1,.6.6Z" />
        </SVG>
    );
};

export default ShopIcon;
