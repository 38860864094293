import React from "react";

import styled from "@emotion/styled";

const StyledP = styled.p`
    scroll-margin-top: calc((8vh * 1.1) + 50px);
`;

const FormError = ({ className = "", message, gtag }) => {
    return (
        <StyledP
            className={`help ${className !== "" ? className : "is-danger"}`}
            {...(gtag ? { "data-gtag": gtag } : {})}
        >
            {message}
        </StyledP>
    );
};

export default FormError;
