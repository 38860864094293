import React, { useContext, useEffect, useState } from "react";

import StorageHandler from "../../../utils/StorageHandler";
import { Blurred } from "../Blur";

export const PageContext = React.createContext();
PageContext.displayName = "PageContext";

export const RESPONSIBLE_COMPANY_HOYER_GMBH = "RESPONSIBLE_COMPANY_HOYER_GMBH";
export const RESPONSIBLE_COMPANY_HOYER_STROM_GAS =
    "RESPONSIBLE_COMPANY_HOYER_STROM_GAS";
export const RESPONSIBLE_COMPANY_HOYER_G_M_B_H =
    "RESPONSIBLE_COMPANY_HOYER_G_M_B_H";

export const PRODUCT_STROM = "PRODUCT_STROM";
export const PRODUCT_GAS = "PRODUCT_GAS";

const storage = new StorageHandler();

export const PageDataProvider = React.memo(({ children }) => {
    const [responsibleCompany, setResponsibleCompany] = useState(
        RESPONSIBLE_COMPANY_HOYER_GMBH
    );
    const [product, setProduct] = useState(null);
    const [lastCalculator, setLastCalculator] = useState(
        storage.get("last-calculator", null)
    );
    const [helZipcode, setHelZipcode] = useState(
        storage.get("hel:zipcode", null)
    );

    const updateLastCalculator = (lastCalculator) => {
        storage.set("last-calculator", lastCalculator);
        setLastCalculator(lastCalculator);
    };

    const data = {
        responsibleCompany,
        product,
        lastCalculator,
        helZipcode,
    };

    const actions = {
        setResponsibleCompany,
        setProduct,
        updateLastCalculator,
        setHelZipcode,
    };

    return (
        <PageContext.Provider
            value={{
                data,
                actions,
            }}
        >
            {children}
        </PageContext.Provider>
    );
});
PageDataProvider.displayName = "PageDataProvider";

export const usePageData = () =>
    useContext(PageDataProvider) ?? {
        setResponsibleCompany: () => undefined,
        setProduct: () => undefined,
    };

const Page = React.memo(
    ({
        responsibleCompany = RESPONSIBLE_COMPANY_HOYER_GMBH,
        product = null,
        ...props
    }) => {
        const pageContext = React.useContext(PageContext);
        const { setResponsibleCompany, setProduct } = pageContext.actions;

        useEffect(() => {
            setResponsibleCompany(responsibleCompany);
        }, [responsibleCompany]);

        useEffect(() => {
            setProduct(product);
        }, [product]);

        return <Blurred eles={["Page"]} {...props} />;
    }
);
Page.displayName = "Page";
export default Page;
