export interface iUrlParam {
    name: string;
    value: string;
}

export const getUrlParam = (name: string) => {
    if (typeof window !== "undefined") {
        const url = new URL(window.location.toString());
        return url.searchParams.get(name);
    }
    return "";
};

export const setUrlParam = (name: string, value: string) => {
    if (typeof window !== "undefined") {
        const url = new URL(window.location.toString());
        url.searchParams.set(name, value);
        window.history.pushState(null, "", url.toString());
    }
};

export const setUrlParams = (arr: iUrlParam[]) => {
    if (typeof window !== "undefined") {
        const url = new URL(window.location.toString());
        arr.forEach((item) => {
            url.searchParams.set(item.name, item.value);
        });
        window.history.pushState(null, "", url.toString());
    }
};

export const deleteUrlParam = (name: string) => {
    if (typeof window !== "undefined") {
        const url = new URL(window.location.toString());
        url.searchParams.delete(name);
        window.history.pushState(null, "", url.toString());
    }
};

export const getCurrentParamString = () => {
    if (typeof window !== "undefined") {
        return window.location.search;
    }
    return "";
};
