import { css, keyframes } from "@emotion/react";

export const fadeIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const fadeOut = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-200%);
  }
`;

export const fadeInWithOpa = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-40%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const fadeOutWithOpa = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-40%);
  }
`;

const loadingAnimation = keyframes`
  0%{
    background-color: hsl(200, 20%, 70%);
  }
  100%{
    background-color: hsl(200, 20%, 95%);
  }
`;

export const loading = css`
    opacity: 0.7;
    animation: ${loadingAnimation} 1s linear infinite alternate;
`;

const AnimationHelpers = {
    fadeIn,
    fadeOut,
    fadeInWithOpa,
    fadeOutWithOpa,
    loading,
};

export default AnimationHelpers;
