import React from "react";

import styled from "@emotion/styled";

const CloseIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
                d="M5.62666,1.12h0a1.5022,1.5022,0,0,1,1.5022,1.5022V8.63108a1.50222,1.50222,0,0,1-1.50222,1.50222h0a1.5022,1.5022,0,0,1-1.5022-1.5022V2.62224A1.50221,1.50221,0,0,1,5.62666,1.12Z"
                transform="translate(-2.33064 5.62666) rotate(-44.99999)"
            />
            <path
                d="M-.01768,10.49779H24.01769A1.5022,1.5022,0,0,1,25.5199,12v0a1.50221,1.50221,0,0,1-1.50221,1.50221H-.01769A1.5022,1.5022,0,0,1-1.51989,12v0A1.50222,1.50222,0,0,1-.01768,10.49779Z"
                transform="translate(-4.97056 12.00012) rotate(-45.00042)"
            />
            <path
                d="M18.37334,13.86671h0a1.5022,1.5022,0,0,1,1.5022,1.5022v6.00884A1.50222,1.50222,0,0,1,18.37333,22.88h0a1.5022,1.5022,0,0,1-1.5022-1.5022V15.36892A1.50221,1.50221,0,0,1,18.37334,13.86671Z"
                transform="translate(-7.61049 18.37341) rotate(-45.00015)"
            />
        </SVG>
    );
};

export default CloseIcon;
