import React from "react";

import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import Backdrop from "../Backdrop";
import { BlurOther, Blurred } from "../Blur";
import Container from "../Container";
import LinkComponent from "../LinkComponent";
import TrackableContent from "../TrackableContent";
import SearchInput from "./SearchInput";
import ShapeButton from "./ShapeButton";
import ShapeButtonMobile from "./ShapeButtonMobile";
import useStore from "./store";

const Buttons = styled(Blurred)`
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    height: 45px;
    color: white;

    .legacyPage & {
        font-family: var(--font-family-vito);
    }
`;

const BottomButtonsBackdrop = styled(Backdrop)`
    z-index: 100;
`;

const BottomButtons = ({ location }) => {
    const { open, close } = useStore();

    const theme = useTheme();

    const calculatorLinks = [
        {
            text: "Heizöl",
            href: "/heizoel",
        },
        {
            text: "Pellets",
            href: "/holzpellets",
        },
        {
            text: "AdBlue®",
            href: "/adblue",
        },
        {
            text: "Technik",
            href: "/technik",
        },
        /*{
            text: "Oilfox",
            href: "/oilfox",
        },*/
        /*{
            text: "Strom",
            href: "/strom",
        },
        {
            text: "Erdgas",
            href: "/gas",
        },*/
    ];

    const searchLinks = [
        {
            text: "Tankstellenfinder",
            href: "/tankstellen/finder",
        },
        {
            text: "Heizöl",
            href: "/heizoel",
        },
        {
            text: "Hoyer Card",
            href: "/hoyer-card",
        },
    ];

    const container = css`
        position: relative;
        height: 100%;
    `;

    const linkCssDesktop = css`
        display: flex;
        justify-content: space-between;
        width: 100%;
    `;

    const linkCssMobile = css`
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
    `;

    const leftItemBorder = css`
        :not(:last-of-type) {
            border-bottom: 1px solid white;
        }
    `;

    const rightItemBorder = css`
        :not(:first-of-type, :last-of-type) {
            border-bottom: 1px solid white;
        }
    `;

    if (
        location.pathname.includes("/bestellung/") ||
        location.pathname.includes("/finder") ||
        location.pathname.includes("/shop/checkout/")
    ) {
        return null;
    }

    return (
        <>
            {open && <BlurOther except={["BottomButtons"]} />}
            <BottomButtonsBackdrop open={!!open} onClick={close} />

            <Buttons eles={["BottomButtons"]}>
                <TrackableContent name="Bottom-Bar">
                    <Container noPadding css={container}>
                        <theme.breakpoints.upTo.small.Matches>
                            <ShapeButtonMobile
                                name="calculator"
                                color="red"
                                iconName="calculator"
                            >
                                <ShapeButtonMobile.Headline>
                                    Preisrechner
                                </ShapeButtonMobile.Headline>
                                <ShapeButtonMobile.Content>
                                    {calculatorLinks.map((item, index) => {
                                        return (
                                            <ShapeButtonMobile.ContentItem
                                                key={index}
                                                css={leftItemBorder}
                                            >
                                                <LinkComponent
                                                    link={item}
                                                    icon="arrow-right"
                                                    color={"white"}
                                                    linkItemCss={linkCssMobile}
                                                    moveIcon={false}
                                                    onClick={close}
                                                />
                                            </ShapeButtonMobile.ContentItem>
                                        );
                                    })}
                                </ShapeButtonMobile.Content>
                            </ShapeButtonMobile>
                            <ShapeButtonMobile
                                name="search"
                                color="blue"
                                position={ShapeButtonMobile.positions.right}
                                iconName="search"
                            >
                                <ShapeButtonMobile.Headline>
                                    Suche
                                </ShapeButtonMobile.Headline>
                                <ShapeButtonMobile.Content
                                    css={rightItemBorder}
                                >
                                    <ShapeButtonMobile.ContentItem>
                                        <SearchInput />
                                    </ShapeButtonMobile.ContentItem>
                                    {searchLinks.map((item, index) => {
                                        return (
                                            <ShapeButtonMobile.ContentItem
                                                key={index}
                                                css={rightItemBorder}
                                            >
                                                <LinkComponent
                                                    linkItemCss={linkCssMobile}
                                                    link={item}
                                                    icon="arrow-right"
                                                    color={"white"}
                                                    moveIcon={false}
                                                    onClick={close}
                                                />
                                            </ShapeButtonMobile.ContentItem>
                                        );
                                    })}
                                </ShapeButtonMobile.Content>
                            </ShapeButtonMobile>
                        </theme.breakpoints.upTo.small.Matches>

                        <theme.breakpoints.from.medium.Matches>
                            <ShapeButton
                                name="search"
                                color="blue"
                                offsetLeft="290"
                            >
                                <ShapeButton.Headline>
                                    Suche
                                </ShapeButton.Headline>
                                <ShapeButton.Content>
                                    <ShapeButton.ContentItem
                                        css={css`
                                            ${rightItemBorder};

                                            margin-right: 100px;
                                        `}
                                    >
                                        <SearchInput />
                                    </ShapeButton.ContentItem>
                                    {searchLinks.map((item, index) => {
                                        return (
                                            <ShapeButton.ContentItem
                                                key={index}
                                                css={css`
                                                    ${rightItemBorder};

                                                    margin-right: ${100 +
                                                    25 * (index + 1)}px;
                                                `}
                                            >
                                                <LinkComponent
                                                    linkItemCss={linkCssDesktop}
                                                    link={item}
                                                    icon="arrow-right"
                                                    color={"white"}
                                                    onClick={close}
                                                />
                                            </ShapeButton.ContentItem>
                                        );
                                    })}
                                </ShapeButton.Content>
                            </ShapeButton>
                            <ShapeButton name="calculator" color="red" full>
                                <ShapeButton.Headline>
                                    Preisrechner
                                </ShapeButton.Headline>
                                <ShapeButton.Content>
                                    {calculatorLinks.map((item, index) => {
                                        return (
                                            <ShapeButton.ContentItem
                                                key={index}
                                                css={css`
                                                    margin-right: ${100 +
                                                    30 * index}px;
                                                    ${leftItemBorder}
                                                `}
                                            >
                                                <LinkComponent
                                                    linkItemCss={linkCssDesktop}
                                                    link={item}
                                                    icon="arrow-right"
                                                    color={"white"}
                                                    onClick={close}
                                                />
                                            </ShapeButton.ContentItem>
                                        );
                                    })}
                                </ShapeButton.Content>
                            </ShapeButton>
                        </theme.breakpoints.from.medium.Matches>
                    </Container>
                </TrackableContent>
            </Buttons>
        </>
    );
};

export default BottomButtons;
