import React from "react";

export const shapeCornerDimensions = {
    width: 107,
    height: 93,
};

const ShapeCorner = ({ color = "#f0f0f0" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={shapeCornerDimensions.width}
            height={shapeCornerDimensions.height}
            viewBox="0 0 107 93"
        >
            <path
                id="Schnittmenge_22"
                data-name="Schnittmenge 22"
                d="M-0.1,93v-0.1l48.6-67.7C59,10.6,75.4,1.4,93.2,0h13.8v93H-0.1z"
                fill={color}
            />
        </svg>
    );
};

export default ShapeCorner;
