import create from "zustand";

const useNavigationStore = create((set, get) => ({
    mobileModalIsActive: false,
    setMobileModalIsActive: (mobileModalIsActive) =>
        set({ mobileModalIsActive }),
    toggleMobileModal: () => {
        const { setMobileModalIsActive, mobileModalIsActive } = get();
        return setMobileModalIsActive(!mobileModalIsActive);
    },
    openMobileModal: () => get().setMobileModalIsActive(true),
    closeMobileModal: () => get().setMobileModalIsActive(false),
}));
export default useNavigationStore;
