import React from "react";

import styled from "@emotion/styled";

const SearchIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
                fill={color}
                d="M9.00048,17a7.99964,7.99964,0,1,1,5.65874-2.34A7.97922,7.97922,0,0,1,9.00048,17Zm0-14.029A6.02824,6.02824,0,1,0,13.2631,4.73684,5.98947,5.98947,0,0,0,9.00048,2.971Z"
            />
            <path
                fill={color}
                d="M19,15.17157h0a1,1,0,0,1,1,1v5.65686a1,1,0,0,1-1,1h0a1,1,0,0,1-1-1V16.17157A1,1,0,0,1,19,15.17157Z"
                transform="translate(-7.87005 19.00002) rotate(-45.00004)"
            />
        </SVG>
    );
};

export default SearchIcon;
