import React from "react";

import styled from "@emotion/styled";

const RouteIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M16,10.49792l-.00134-.00628a1.47078,1.47078,0,0,0-.387-1.00336L13.60974,7.30151A.89955.89955,0,0,0,12.28363,7.282a1.04265,1.04265,0,0,0-.01831,1.40979l.767.838H9.02319A.99653.99653,0,0,0,8,10.49792V14.0318a1.02476,1.02476,0,0,0,2.04639,0V11.46613h2.98938l-.77045.84173a1.04266,1.04266,0,0,0,.01831,1.40979.89893.89893,0,0,0,1.32611-.01947l1.99188-2.176A1.484,1.484,0,0,0,16,10.4989l-.00012-.00055Z" />
            <path d="M12.0005,23a1.62905,1.62905,0,0,1-1.15808-.47863L1.47887,13.15861a1.63924,1.63924,0,0,1,0-2.31572l9.36355-9.36426a1.63913,1.63913,0,0,1,2.31416-.001l9.36455,9.36526a1.63924,1.63924,0,0,1,0,2.31572l-9.36355,9.36276A1.63266,1.63266,0,0,1,12.0005,23ZM4.06573,12.56612l7.36908,7.368a.8.8,0,0,0,1.13132,0l7.36818-7.368a.8.8,0,0,0,0-1.13137L12.56524,4.06549a.8.8,0,0,0-1.13142,0L4.06565,11.43475A.8.8,0,0,0,4.06573,12.56612Zm17.00855-.27634h0Z" />
        </SVG>
    );
};

export default RouteIcon;
