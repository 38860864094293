import React from "react";

import styled from "@emotion/styled";

const EditIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            style={{ enableBackground: "new 0 0 24 24" }}
            xmlSpace="preserve"
        >
            <g
                id="Raster_00000047777513039354868010000010403163422970268849_"
                className="st0"
            >
                <g className="st1">
                    <g className="st2">
                        <line className="st3" x1="0" y1="0" x2="0" y2="24" />
                        <line
                            className="st3"
                            x1="0.8"
                            y1="0"
                            x2="0.8"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="1.6"
                            y1="0"
                            x2="1.6"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="2.4"
                            y1="0"
                            x2="2.4"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="3.2"
                            y1="0"
                            x2="3.2"
                            y2="24"
                        />
                        <line className="st3" x1="4" y1="0" x2="4" y2="24" />
                        <line
                            className="st3"
                            x1="4.8"
                            y1="0"
                            x2="4.8"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="5.6"
                            y1="0"
                            x2="5.6"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="6.4"
                            y1="0"
                            x2="6.4"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="7.2"
                            y1="0"
                            x2="7.2"
                            y2="24"
                        />
                        <line className="st3" x1="8" y1="0" x2="8" y2="24" />
                        <line
                            className="st3"
                            x1="8.8"
                            y1="0"
                            x2="8.8"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="9.6"
                            y1="0"
                            x2="9.6"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="10.4"
                            y1="0"
                            x2="10.4"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="11.2"
                            y1="0"
                            x2="11.2"
                            y2="24"
                        />
                        <line className="st3" x1="12" y1="0" x2="12" y2="24" />
                        <line
                            className="st3"
                            x1="12.8"
                            y1="0"
                            x2="12.8"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="13.6"
                            y1="0"
                            x2="13.6"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="14.4"
                            y1="0"
                            x2="14.4"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="15.2"
                            y1="0"
                            x2="15.2"
                            y2="24"
                        />
                        <line className="st3" x1="16" y1="0" x2="16" y2="24" />
                        <line
                            className="st3"
                            x1="16.8"
                            y1="0"
                            x2="16.8"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="17.6"
                            y1="0"
                            x2="17.6"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="18.4"
                            y1="0"
                            x2="18.4"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="19.2"
                            y1="0"
                            x2="19.2"
                            y2="24"
                        />
                        <line className="st3" x1="20" y1="0" x2="20" y2="24" />
                        <line
                            className="st3"
                            x1="20.8"
                            y1="0"
                            x2="20.8"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="21.6"
                            y1="0"
                            x2="21.6"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="22.4"
                            y1="0"
                            x2="22.4"
                            y2="24"
                        />
                        <line
                            className="st3"
                            x1="23.2"
                            y1="0"
                            x2="23.2"
                            y2="24"
                        />
                        <line className="st3" x1="24" y1="0" x2="24" y2="24" />
                    </g>
                    <g className="st2">
                        <path
                            className="st4"
                            d="M24,0v24H0V0H24 M24,0H0v24h24V0L24,0z"
                        />
                    </g>
                    <g className="st2">
                        <line
                            className="st5"
                            x1="0.4"
                            y1="0"
                            x2="0.4"
                            y2="24"
                        />
                        <line
                            className="st5"
                            x1="1.2"
                            y1="0"
                            x2="1.2"
                            y2="24"
                        />
                        <line className="st5" x1="2" y1="0" x2="2" y2="24" />
                        <line
                            className="st5"
                            x1="2.8"
                            y1="0"
                            x2="2.8"
                            y2="24"
                        />
                        <line
                            className="st5"
                            x1="3.6"
                            y1="0"
                            x2="3.6"
                            y2="24"
                        />
                        <line
                            className="st5"
                            x1="4.4"
                            y1="0"
                            x2="4.4"
                            y2="24"
                        />
                        <line
                            className="st5"
                            x1="5.2"
                            y1="0"
                            x2="5.2"
                            y2="24"
                        />
                        <line className="st5" x1="6" y1="0" x2="6" y2="24" />
                        <line
                            className="st5"
                            x1="6.8"
                            y1="0"
                            x2="6.8"
                            y2="24"
                        />
                        <line
                            className="st5"
                            x1="7.6"
                            y1="0"
                            x2="7.6"
                            y2="24"
                        />
                        <line
                            className="st5"
                            x1="8.4"
                            y1="0"
                            x2="8.4"
                            y2="24"
                        />
                        <line
                            className="st5"
                            x1="9.2"
                            y1="0"
                            x2="9.2"
                            y2="24"
                        />
                        <line className="st5" x1="10" y1="0" x2="10" y2="24" />
                        <line
                            className="st5"
                            x1="10.8"
                            y1="0"
                            x2="10.8"
                            y2="24"
                        />
                        <line
                            className="st5"
                            x1="11.6"
                            y1="0"
                            x2="11.6"
                            y2="24"
                        />
                        <line
                            className="st5"
                            x1="12.4"
                            y1="0"
                            x2="12.4"
                            y2="24"
                        />
                        <line
                            className="st5"
                            x1="13.2"
                            y1="0"
                            x2="13.2"
                            y2="24"
                        />
                        <line className="st5" x1="14" y1="0" x2="14" y2="24" />
                        <line
                            className="st5"
                            x1="14.8"
                            y1="0"
                            x2="14.8"
                            y2="24"
                        />
                        <line
                            className="st5"
                            x1="15.6"
                            y1="0"
                            x2="15.6"
                            y2="24"
                        />
                        <line
                            className="st5"
                            x1="16.4"
                            y1="0"
                            x2="16.4"
                            y2="24"
                        />
                        <line
                            className="st5"
                            x1="17.2"
                            y1="0"
                            x2="17.2"
                            y2="24"
                        />
                        <line className="st5" x1="18" y1="0" x2="18" y2="24" />
                        <line
                            className="st5"
                            x1="18.8"
                            y1="0"
                            x2="18.8"
                            y2="24"
                        />
                        <line
                            className="st5"
                            x1="19.6"
                            y1="0"
                            x2="19.6"
                            y2="24"
                        />
                        <line
                            className="st5"
                            x1="20.4"
                            y1="0"
                            x2="20.4"
                            y2="24"
                        />
                        <line
                            className="st5"
                            x1="21.2"
                            y1="0"
                            x2="21.2"
                            y2="24"
                        />
                        <line className="st5" x1="22" y1="0" x2="22" y2="24" />
                        <line
                            className="st5"
                            x1="22.8"
                            y1="0"
                            x2="22.8"
                            y2="24"
                        />
                        <line
                            className="st5"
                            x1="23.6"
                            y1="0"
                            x2="23.6"
                            y2="24"
                        />
                    </g>
                    <g className="st2">
                        <line
                            className="st5"
                            x1="24"
                            y1="0.4"
                            x2="0"
                            y2="0.4"
                        />
                        <line
                            className="st5"
                            x1="24"
                            y1="1.2"
                            x2="0"
                            y2="1.2"
                        />
                        <line className="st5" x1="24" y1="2" x2="0" y2="2" />
                        <line
                            className="st5"
                            x1="24"
                            y1="2.8"
                            x2="0"
                            y2="2.8"
                        />
                        <line
                            className="st5"
                            x1="24"
                            y1="3.6"
                            x2="0"
                            y2="3.6"
                        />
                        <line
                            className="st5"
                            x1="24"
                            y1="4.4"
                            x2="0"
                            y2="4.4"
                        />
                        <line
                            className="st5"
                            x1="24"
                            y1="5.2"
                            x2="0"
                            y2="5.2"
                        />
                        <line className="st5" x1="24" y1="6" x2="0" y2="6" />
                        <line
                            className="st5"
                            x1="24"
                            y1="6.8"
                            x2="0"
                            y2="6.8"
                        />
                        <line
                            className="st5"
                            x1="24"
                            y1="7.6"
                            x2="0"
                            y2="7.6"
                        />
                        <line
                            className="st5"
                            x1="24"
                            y1="8.4"
                            x2="0"
                            y2="8.4"
                        />
                        <line
                            className="st5"
                            x1="24"
                            y1="9.2"
                            x2="0"
                            y2="9.2"
                        />
                        <line className="st5" x1="24" y1="10" x2="0" y2="10" />
                        <line
                            className="st5"
                            x1="24"
                            y1="10.8"
                            x2="0"
                            y2="10.8"
                        />
                        <line
                            className="st5"
                            x1="24"
                            y1="11.6"
                            x2="0"
                            y2="11.6"
                        />
                        <line
                            className="st5"
                            x1="24"
                            y1="12.4"
                            x2="0"
                            y2="12.4"
                        />
                        <line
                            className="st5"
                            x1="24"
                            y1="13.2"
                            x2="0"
                            y2="13.2"
                        />
                        <line className="st5" x1="24" y1="14" x2="0" y2="14" />
                        <line
                            className="st5"
                            x1="24"
                            y1="14.8"
                            x2="0"
                            y2="14.8"
                        />
                        <line
                            className="st5"
                            x1="24"
                            y1="15.6"
                            x2="0"
                            y2="15.6"
                        />
                        <line
                            className="st5"
                            x1="24"
                            y1="16.4"
                            x2="0"
                            y2="16.4"
                        />
                        <line
                            className="st5"
                            x1="24"
                            y1="17.2"
                            x2="0"
                            y2="17.2"
                        />
                        <line className="st5" x1="24" y1="18" x2="0" y2="18" />
                        <line
                            className="st5"
                            x1="24"
                            y1="18.8"
                            x2="0"
                            y2="18.8"
                        />
                        <line
                            className="st5"
                            x1="24"
                            y1="19.6"
                            x2="0"
                            y2="19.6"
                        />
                        <line
                            className="st5"
                            x1="24"
                            y1="20.4"
                            x2="0"
                            y2="20.4"
                        />
                        <line
                            className="st5"
                            x1="24"
                            y1="21.2"
                            x2="0"
                            y2="21.2"
                        />
                        <line className="st5" x1="24" y1="22" x2="0" y2="22" />
                        <line
                            className="st5"
                            x1="24"
                            y1="22.8"
                            x2="0"
                            y2="22.8"
                        />
                        <line
                            className="st5"
                            x1="24"
                            y1="23.6"
                            x2="0"
                            y2="23.6"
                        />
                    </g>
                    <g className="st2">
                        <line className="st3" x1="24" y1="0" x2="0" y2="0" />
                        <line
                            className="st3"
                            x1="24"
                            y1="0.8"
                            x2="0"
                            y2="0.8"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="1.6"
                            x2="0"
                            y2="1.6"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="2.4"
                            x2="0"
                            y2="2.4"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="3.2"
                            x2="0"
                            y2="3.2"
                        />
                        <line className="st3" x1="24" y1="4" x2="0" y2="4" />
                        <line
                            className="st3"
                            x1="24"
                            y1="4.8"
                            x2="0"
                            y2="4.8"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="5.6"
                            x2="0"
                            y2="5.6"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="6.4"
                            x2="0"
                            y2="6.4"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="7.2"
                            x2="0"
                            y2="7.2"
                        />
                        <line className="st3" x1="24" y1="8" x2="0" y2="8" />
                        <line
                            className="st3"
                            x1="24"
                            y1="8.8"
                            x2="0"
                            y2="8.8"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="9.6"
                            x2="0"
                            y2="9.6"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="10.4"
                            x2="0"
                            y2="10.4"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="11.2"
                            x2="0"
                            y2="11.2"
                        />
                        <line className="st3" x1="24" y1="12" x2="0" y2="12" />
                        <line
                            className="st3"
                            x1="24"
                            y1="12.8"
                            x2="0"
                            y2="12.8"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="13.6"
                            x2="0"
                            y2="13.6"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="14.4"
                            x2="0"
                            y2="14.4"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="15.2"
                            x2="0"
                            y2="15.2"
                        />
                        <line className="st3" x1="24" y1="16" x2="0" y2="16" />
                        <line
                            className="st3"
                            x1="24"
                            y1="16.8"
                            x2="0"
                            y2="16.8"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="17.6"
                            x2="0"
                            y2="17.6"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="18.4"
                            x2="0"
                            y2="18.4"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="19.2"
                            x2="0"
                            y2="19.2"
                        />
                        <line className="st3" x1="24" y1="20" x2="0" y2="20" />
                        <line
                            className="st3"
                            x1="24"
                            y1="20.8"
                            x2="0"
                            y2="20.8"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="21.6"
                            x2="0"
                            y2="21.6"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="22.4"
                            x2="0"
                            y2="22.4"
                        />
                        <line
                            className="st3"
                            x1="24"
                            y1="23.2"
                            x2="0"
                            y2="23.2"
                        />
                    </g>
                    <g className="st2">
                        <path
                            className="st6"
                            d="M23.2,0.8v22.4H0.8V0.8H23.2 M23.2,0.8H0.8v22.4h22.4V0.8L23.2,0.8z"
                        />
                    </g>
                    <line className="st7" x1="0" y1="12" x2="24" y2="12" />
                    <line className="st7" x1="12" y1="24" x2="12" y2="0" />
                </g>
            </g>
            <g id="Ebene_1">
                <g>
                    <path
                        className="st8"
                        d="M6.3,20.4C6.4,21.3,7.2,22,8.2,22h7.7c1,0,1.8-0.7,1.9-1.6L19.7,8H4.3L6.3,20.4z M17.3,10l-1.5,10H8.2L6.7,10
			H17.3z"
                    />
                    <path
                        className="st8"
                        d="M19.2,5h-3.7V3.8c0-0.7-0.6-1.3-1.3-1.3H9.8c-0.7,0-1.3,0.6-1.3,1.3V5H4.8C4.4,5,4,5.4,4,5.8V7h16V5.8
			C20,5.4,19.6,5,19.2,5z M9.5,5V3.8c0-0.2,0.1-0.3,0.3-0.3h4.4c0.2,0,0.3,0.1,0.3,0.3V5H9.5z"
                    />
                    <path
                        className="st8"
                        d="M12,18.5c0.3,0,0.5-0.2,0.5-0.5v-6c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v6C11.5,18.3,11.7,18.5,12,18.5z"
                    />
                    <path
                        className="st8"
                        d="M14,18.5C14,18.5,14,18.5,14,18.5c0.3,0,0.5-0.2,0.5-0.5l0.3-6c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5
			l-0.3,6C13.5,18.3,13.7,18.5,14,18.5z"
                    />
                    <path
                        className="st8"
                        d="M10,18.5C10,18.5,10,18.5,10,18.5c0.3,0,0.5-0.2,0.5-0.5l-0.3-6c0-0.3-0.3-0.5-0.5-0.5
			c-0.3,0-0.5,0.2-0.5,0.5l0.3,6C9.5,18.3,9.7,18.5,10,18.5z"
                    />
                </g>
            </g>
        </SVG>
    );
};

export default EditIcon;
