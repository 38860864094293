import React, { FC } from "react";

import { css } from "@emotion/react";
import { type SerializedStyles } from "@emotion/serialize";

import HeadlineV2, {
    type HeadlineProps as HeadlineV2Props,
    type Level,
} from "../../base/atoms/Headline";

export {
    /** @deprecated Use components/base/atoms/Headline instead */
    type HeadlineProps as HeadlineV2Props,
    /** @deprecated Use components/base/atoms/Headline instead */
    default as HeadlineV2,
} from "../../base/atoms/Headline";

type LegacyLevel = `${Level}`;
const isLegacyLevel = (a: unknown): a is LegacyLevel =>
    (["1", "2", "3", "4", "5"] as ReadonlyArray<any>).includes(a);
const fromLegacyLevel = <
    a extends Level | LegacyLevel | "default" | undefined | null
>(
    a: a
): Exclude<a, LegacyLevel> | Level =>
    isLegacyLevel(a) ? (Number(a) as Level) : (a as Exclude<a, LegacyLevel>);

/** @deprecated Use components/base/atoms/Headline instead */
export type HeadlineProps = HeadlineV2Props & {
    is?: Level | LegacyLevel;
    lookLike?: Level | LegacyLevel | "default" | null;
    cssStyle?: string | SerializedStyles | null;
};

/** @deprecated Use components/base/atoms/Headline instead */
const Headline: FC<HeadlineProps> = ({
    is,
    level = fromLegacyLevel(is),
    lookLike,
    levelStyle = fromLegacyLevel(lookLike),
    cssStyle,
    ...props
}) => (
    <HeadlineV2
        css={css`
            ${cssStyle}
        `}
        {...{ level, levelStyle, ...props }}
    />
);
export default Headline;
