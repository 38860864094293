import React, { useMemo, useState } from "react";

import { SubmitHandler, useController, useForm } from "react-hook-form";

import styled from "@emotion/styled";

import { ApiResponse, errorAwareApiFetch } from "../../../../apiBindings";
import pushToAnalytics from "../../../../utils/analytics";
import { NewsletterPopupManager } from "../../../../utils/NewsletterPopupManager";
import { mapLaravelErrors } from "../../../../utils/reactHookFormsHelper";
import ReactHookInput from "../../forms/ReactHookInput";
import Button from "../../NewForms/Button";
import NewsletterTopicPicker, {
    NewsletterTopic,
} from "./NewsletterTopicPicker";

interface Form {
    email: string;
    firstname: string | null;
    lastname: string | null;
    topics: NewsletterTopic[];
}

interface Props {
    onSuccess: (message: string) => void;
}

interface NewsletterApiResponse {
    message: string;
}

const ErrorText = styled.div`
    color: ${({ theme }) => theme.colors.red.toString()};
`;

const FormLayout = styled.section`
    display: flex;
    flex-direction: column;
`;

const Topics = styled.div`
    padding-top: 1.5em;
    padding-bottom: 1.5em;
`;

const defaultForm: Form = {
    email: "",
    firstname: "",
    lastname: "",
    topics: [NewsletterTopic.PRIVATKUNDE],
};

const NewsletterForm = ({ onSuccess }: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [serverError, setServerError] = useState<string | null>(null);

    const {
        handleSubmit,
        control,
        setError,
        clearErrors,
        reset,
        setValue,
        formState: { errors },
    } = useForm<Form>({
        defaultValues: defaultForm,
    });

    const controller = useController({
        name: "topics",
        control,
    });

    const handleTopics = (topics: ReadonlySet<NewsletterTopic>) => {
        setValue("topics", Array.from(topics), { shouldValidate: true });
    };

    const onSubmit: SubmitHandler<Form> = async (data: Form) => {
        clearErrors();
        setServerError(null);

        try {
            setIsLoading(true);
            const response: ApiResponse<NewsletterApiResponse> =
                await errorAwareApiFetch("/newsletter", {
                    method: "POST",
                    body: JSON.stringify(data),
                });

            onSuccess(response.message);
            NewsletterPopupManager.markAsRegistered();
            pushToAnalytics("PopupNewsletter:success", {
                topics: data.topics,
            });
            reset();
        } catch (error: any) {
            // Record the validation errors in react-hook-form
            if (error.response.status === 422) {
                const response = mapLaravelErrors(error);
                response.errors.forEach((err: any) => {
                    setError(err.field, {
                        type: "custom",
                        message: err.message,
                    });
                });
                return;
            }

            if (error.response.status === 409) {
                // If the API returns a 409 error,
                // that means that the user is already registered in the CleverReach list.
                // In this case, set the registered flag in the local storage,
                // so the user won't get bothered by the popup again.
                NewsletterPopupManager.markAsRegistered();
                setError("email", {
                    type: "custom",
                    message: error.message,
                });
                return;
            }

            // Register a generic error message from the server with a fallback in case the service is not available
            // or something else went wrong.
            setServerError(
                error.message ??
                    "Deine Newsletter-Anmeldung konnte nicht verarbeitet werden. Bitte versuche es zu einem späteren Zeitpunkt erneut."
            );
        } finally {
            setIsLoading(false);
        }
    };

    const topicsValue = useMemo(() => {
        return new Set(controller.field.value);
    }, [controller.field.value]);

    return (
        <section>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormLayout>
                    <ReactHookInput
                        name="email"
                        placeholder="E-Mail"
                        rules={{ required: true }}
                        control={control}
                    />

                    <ReactHookInput
                        name="firstname"
                        placeholder="Vorname (Optional)"
                        control={control}
                        ifNullish=""
                        rules={{ required: false }}
                    />

                    <ReactHookInput
                        name="lastname"
                        placeholder="Nachname (Optional)"
                        control={control}
                        ifNullish=""
                        rules={{ required: false }}
                    />

                    <span>
                        Wir möchten Dich bestens informieren. Wähle aus, welche
                        Bereiche Dich besonders interessieren.
                    </span>

                    <Topics>
                        <NewsletterTopicPicker
                            selectedTopics={topicsValue}
                            onTopicsChange={handleTopics}
                        />
                    </Topics>

                    {errors.topics && (
                        <ErrorText>{errors.topics.message}</ErrorText>
                    )}

                    <Button
                        color="blue"
                        fullWidth
                        type="submit"
                        disabled={isLoading}
                    >
                        Anmelden
                    </Button>
                </FormLayout>
            </form>
            {serverError && <ErrorText>{serverError}</ErrorText>}
        </section>
    );
};
export default NewsletterForm;
