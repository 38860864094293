/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useContext, useEffect, useState } from "react";

import { useLocation } from "@gatsbyjs/reach-router";

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { apiFetch } from "../../../../apiBindings";
import StorageHandler from "../../../../utils/StorageHandler";
import { activeBorderBottom } from "../../activeLink";
import PhoneIcon from "../../icons/PhoneIcon";
import { PageContext } from "../../Page";
import useHeatingOilCalculatorStore from "./../../../pages/HeatingOil/CalculatorPage/store";

const storage = new StorageHandler();

const phones = [
    { url: "", phone: "+49 4262 797" },
    { url: "/baustellenversorgung/", phone: "+49 4262 799661" },
    { url: "/gas/", phone: "+49 4262 799830" },
    { url: "/holzpellets/", phone: "+49 4262 799979" },
    { url: "/hoyer-card/", phone: "+49 4262 799199" },
    { url: "/fluessiggas/", phone: "+49 4262 799418" },
    { url: "/fluessiggas/grillgas/", phone: "+49 4262 799418" },
    { url: "/gasflaschen/", phone: "+49 4262 799418" },
    { url: "/produkte/gewerbe-erdgas/", phone: "+49 4262 799830" },
    { url: "/produkte/gewerbe-strom/", phone: "+49 4262 799830" },
    { url: "/strom/", phone: "+49 4262 799830" },
    { url: "/tankstellen/", phone: "+49 4262 799199" },
    { url: "/briketts/", phone: "+49 4262 799979" },
    { url: "/powertrust/", phone: "+49 4262 79 9834" },
];

const PhoneNumberContainer = styled.span`
    ${({ theme }) => theme.breakpoints.upTo.small.css`
        display: none;
    `}
`;

const PhoneLink = styled.a`
    font-weight: 500;
    color: ${({ theme }) => theme.colors.red.toString()} !important;
    ${({ theme }) => theme.breakpoints.from.medium.css`
        ${activeBorderBottom({ size: "4px" })({ theme })};
    `}
`;

const PhoneWrapper = styled.div`
    ${({ theme }) => theme.breakpoints.from.medium.css`
        min-width: 150px;
        text-align: right;
    `}
`;

const IconContainer = styled.div`
    display: flex;
    width: 20px;
    ${({ theme }) => theme.breakpoints.from.medium.css`
        display: none;
    `}
`;

const Phone = ({ defaultPhoneNumber, telName }) => {
    const location = useLocation();
    const theme = useTheme();
    const pageContext = useContext(PageContext);
    const { helZipcode } = pageContext.data;
    const [currentLocation, setCurrentLocation] = useState(location.pathname);
    const [phone, setPhone] = useState(defaultPhoneNumber);
    const [zipcodeLocalstorage, setZipcodeLocalstorage] = useState(
        storage.get("hel:zipcode")
    );
    const [checkedZipcode] = useHeatingOilCalculatorStore((state) => [
        state.checkedZipcode,
    ]);

    useEffect(() => {
        // Trigger only if it is not a heating oil page. Heating oil will take care of itself.
        if (!locationIsHeatingOil(location.pathname)) {
            setPhone(getPhoneForLocation());
            setCurrentLocation(location.pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        handleLocationChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    /**
     * When the local zipcode changes, fetch the correspoding phone.
     */
    useEffect(() => {
        if (locationIsHeatingOil(currentLocation)) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            handleHeatingOilChange(currentLocation);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipcodeLocalstorage, checkedZipcode]);

    /**
     * When the zipcode in the PageDataProvider changes, trigger a local zipcode change
     * to fetch the corresponding phone number for the given zipcode.
     */
    useEffect(() => {
        setZipcodeLocalstorage(storage.get("hel:zipcode"));
    }, [helZipcode]);

    /**
     * Checks if the given location belongs to heating oil.
     */
    const locationIsHeatingOil = (locationToCheck) => {
        return locationToCheck.includes("heizoel");
    };

    /**
     * Returns the phone for the current location.
     */
    const getPhoneForLocation = () => {
        // On the rood node, return the default phone number
        if (location.pathname === "/") {
            return defaultPhoneNumber;
        }
        const found = phones.find((element) =>
            element.url.includes(String(location.pathname))
        );
        if (found === undefined) {
            return defaultPhoneNumber;
        }

        return found.phone;
    };

    /**
     * Set the new phone number if neccessary
     */
    // eslint-disable-next-line @typescript-eslint/require-await
    const handleLocationChange = async () => {
        if (currentLocation !== location.pathname) {
            if (locationIsHeatingOil(location.pathname)) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                handleHeatingOilChange();
            } else {
                setPhone(getPhoneForLocation());
            }
            setCurrentLocation(location.pathname);
        } else if (location.pathname.includes("heizoel")) {
            if (zipcodeLocalstorage !== storage.get("hel:zipcode")) {
                setZipcodeLocalstorage(storage.get("hel:zipcode"));
            }
        }
    };

    /**
     * Set the corresponding heating oil phone number.
     */
    const handleHeatingOilChange = async () => {
        const phoneForZipcode = await getPhoneForZipcode();
        if (phoneForZipcode === null) {
            setPhone(defaultPhoneNumber);
        } else {
            setPhone(String(phoneForZipcode));
        }
        setCurrentLocation(location.pathname);
    };

    /**
     * Fetch the phone number from the backend based on the given zipcode.
     */
    const getPhoneForZipcode = async () => {
        const zipcode = checkedZipcode || zipcodeLocalstorage;
        if (zipcode === null) {
            return null;
        }

        const response = await apiFetch(`/heatingoil/phone/${zipcode}`);
        if (response.ok) {
            const data = await response.json();
            return data.phone;
        }

        return null;
    };

    const formatPhoneForLink = (phone) => {
        return phone.split(" ").join("");
    };

    return (
        <PhoneWrapper>
            <PhoneLink
                href={`tel:${formatPhoneForLink(phone)}`}
                title={telName}
            >
                <IconContainer>
                    <PhoneIcon color={theme.colors.red.toString()} />
                </IconContainer>
                <PhoneNumberContainer>{phone}</PhoneNumberContainer>
            </PhoneLink>
        </PhoneWrapper>
    );
};

export default Phone;
