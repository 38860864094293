import create from "zustand";

import pushToAnalytics from "../../../../utils/analytics";
import {
    addProductToCart as apiAddProductToCart,
    deleteProductFromCart,
    fetchCart as apiFetchCart,
    updateProductInCart as apiUpdateProductInCart,
} from "../api";
import { iShopwareDeliveryTime, iShopwareMedia } from "../store";

export const cartStorageKey = "shop-cart-token";
export const lastVisitedStorePathKey = "lastVisitedStoreFrontPath";
export const technicCategoryId = "";

export interface iShopwareCartPrice {
    netPrice: number;
    totalPrice: number;
    calculatedTaxes: Array<iShopwareCartTaxCalculated>;
    taxRules: iShopwareCartTaxRule;
    positionPrice: number;
    taxStatus: string;
    rawTotal: number;
    apiAlias: string;
}

export interface iShopwareCartTransaction {
    amount: iShopwareCalculatedPrice;
    paymentMethodId: string;
    apiAlias: string;
}

export interface iShopwareCartPriceReference {
    price: number;
    purchaseUnit: number;
    referenceUnit: number;
    unitName: string;
    apiAlias: string;
}

export interface iShopwareCartPriceRegulation {
    price: number;
}

export interface iShopwareCartListPrice {
    price: number;
    discount: number;
    percentage: number;
    apiAlias: string;
}

export interface iShopwareCalculatedPrice {
    unitPrice: number;
    quantity: number;
    totalPrice: number;
    calculatedTaxes: iShopwareCartTaxCalculated;
    taxRules: iShopwareCartTaxRule;
    referencePrice?: iShopwareCartPriceReference;
    listPrice: iShopwareCartListPrice | null;
    regulationPrice?: any;
    apiAlias: string;
}

export interface iShopwarePrice {
    currencyId: string;
    net: number | null;
    gross: number | null;
    apiAlias: string;
}

export interface iShopwareShippingMethodPrice {
    id: string;
    quantityStart: number;
    quantityEnd: number;
    currencyPrice?: iShopwarePrice[];
    apiAlias: string;
}

export interface iShopwareShippingMethod {
    id: string;
    active: boolean;
    name: string;
    description: string;
    deliveryTime: iShopwareDeliveryTime;
    prices?: iShopwareShippingMethodPrice[];
    apiAlias: string;
}

export interface iShopwareCartDelivery {
    apiAlias: string;
    shippingCosts: iShopwareCalculatedPrice;
    shippingMethod: iShopwareShippingMethod;
}

export interface iShopwareCartTaxCalculated {
    price: number;
    tax: number;
    taxRate: number;
    apiAlias: string;
}

export interface iShopwareCartTaxRule {
    percentage: number;
    taxRate: number;
    apiAlias: string;
}

export interface iShopwareCart {
    name: string;
    token: string;
    price: iShopwareCartPrice;
    lineItems: Array<iShopwareCartLineItem>;
    errors: Array<never>;
    deliveries: Array<iShopwareCartDelivery>;
    transactions: iShopwareCartTransaction[];
    modified: boolean;
    customerComment: string | null;
    affiliateCode: string | null;
    campaignCode: string | null;
    apiAlias: string;
}

export interface iShopwareCartDeliveryTime {
    name: string;
    min: number;
    max: number;
    unit: string;
    apiAlias: string;
}

export interface iShopwareCartDeliveryInformation {
    deliveryTime: iShopwareCartDeliveryTime | null;
    weight: number;
    width: number;
    height: number;
    length: number;
    freeDelivery: boolean;
    stock: number;
    apiAlias: string;
}

export interface iShopwareCartLineItem {
    id: string;
    referencedId: string;
    label: string;
    quantity: number;
    cover: iShopwareMedia;
    deliveryInformation: iShopwareCartDeliveryInformation;
    price: iShopwareCalculatedPrice;
}

interface iShoppingCart {
    loading: boolean;
    cart: iShopwareCart | null;
    isTechnicCart: boolean;
    setLoading: (loading: boolean) => void;
    cartQuantityOptions: Array<any>;
    fetchCart: () => void;
    addProductToCart: (
        cartItemId: string,
        amount: number
    ) => Promise<iShopwareCart>;
    setCart: (cart: iShopwareCart | null) => void;
    deleteProduct: (cartItemId: string) => void;
    changeQuantity: (cartItemId: string, amount: number) => void;
}
//Generates the Amount options for the Product Amount Select
const generateCartQuantityOptions = (max = 99) => {
    const options = [];
    for (let i = 1; i <= max; i++) {
        options.push({ text: i, value: i });
    }
    return options;
};

const useStore = create<iShoppingCart>((set, get) => ({
    loading: false,
    cart: null,
    isTechnicCart: false,
    cartQuantityOptions: generateCartQuantityOptions(),
    setLoading: (loading) => set({ loading }),
    setCart: (cart) => {
        if (cart) {
            if (
                cart.lineItems.some((item) => {
                    return item.payload.categoryIds.some(
                        (id: string) => id === technicCategoryId
                    );
                })
            ) {
                set({ isTechnicCart: true });
            } else {
                set({ isTechnicCart: false });
            }
        }
        set({ cart });
    },
    fetchCart: async () => {
        const { setLoading, setCart } = get();
        setLoading(true);
        const cart = await apiFetchCart();
        setLoading(false);
        setCart(cart);
    },
    addProductToCart: async (productId, quantity) => {
        let updatedCart: iShopwareCart | null;
        const { setLoading, setCart, cart } = get();
        setLoading(true);
        const itemAlreadyInCart = cart?.lineItems.find((item) => {
            return item.referencedId === productId;
        });
        pushToAnalytics("shop:basket", { productId: productId });
        // Manually check if a product already exists and update the quantity as shopware always
        // adds a new CartItem and does not group them:
        if (itemAlreadyInCart) {
            const cartItemId = itemAlreadyInCart.id;
            const newQuantity = itemAlreadyInCart.quantity + quantity;
            updatedCart = await apiUpdateProductInCart(cartItemId, newQuantity);
        } else {
            updatedCart = await apiAddProductToCart(productId, quantity);
        }
        setLoading(false);

        setCart(updatedCart);
        return updatedCart;
    },
    changeQuantity: async (cartItemId: string, quantity: number) => {
        const { setLoading, setCart } = get();
        setLoading(true);
        const updatedCart = await apiUpdateProductInCart(cartItemId, quantity);
        setLoading(false);
        setCart(updatedCart);
    },
    deleteProduct: async (cartItemId) => {
        const { setLoading, setCart } = get();
        setLoading(true);
        const updatedCart = await deleteProductFromCart(cartItemId);
        setLoading(false);
        setCart(updatedCart);
    },
}));

export default useStore;
