/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from "react";

import { useId } from "react-aria";

import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import errorMessages from "../../../../utils/validation/errorMessages";
import Validator from "../../../../utils/validation/Validator";
import { withField as withFieldWrapper } from "../Field";
import FormError from "../FormError";

const validator = new Validator();

export const StyledInput = styled.input`
    position: relative;
    top: 0.2em;
    width: 1.3em;
    height: 1.3em;
    margin-right: 0.5em;
    vertical-align: middle;
    background-color: ${({ theme }) => theme.colors.white.toString()};
    outline: none;

    :checked {
        background-color: ${({ theme }) => theme.colors.blue.toString()};
    }

    &:hover {
        cursor: pointer;
    }
`;

export const StyledLabel = styled.label`
    display: flex;
    gap: 0.25em;

    &:hover {
        cursor: pointer;
    }
`;

const Wrapper = styled.span`
    display: inline-flex;
    align-items: center;
`;

/**
 * You can either set a label property for normal text - or set a child, if you want html in your label e.g.
 * <NewCheckbox checked={false} name="isAccepted">
 *     <a href="/page">click me</a>
 * </NewCheckbox>
 */
const Checkbox = ({
    checked = false,
    name = "",
    isDisabled = false,
    required = false,
    forceError = false,
    className = "",
    id = null,
    validation = null,
    label,
    errorClassName = "",
    children = undefined,
    onChange = () => {},
    onError = () => {},
    onErrorSolved = () => {},
    withField = false,
    readOnly = false,
    ...props
}) => {
    const theme = useTheme();

    const [hasError, setHasError] = useState(false);
    const isValid = validator.getValidator(validation, checked, required);

    const validate = () => {
        if (validation && isValid()) {
            setHasError(true);
            onError();
        } else {
            setHasError(false);
            onErrorSolved();
        }
    };

    const onLocalChange = (event) => {
        event.stopPropagation();
        if (!readOnly) {
            validate();
            onChange({
                target: {
                    name,
                    value: !checked,
                },
            });
        }
    };

    const disabledStyles = css`
        color: ${theme.colors.darkGrey.toString()} !important;
    `;

    const getErrorMessage = () => {
        // Strip a prefix, if we have one.
        const parts = validation.split(":");
        const key = parts[parts.length - 1];
        return errorMessages[key];
    };

    useEffect(() => {
        if (forceError === true) {
            setHasError(true);
        }
    }, [forceError]);

    const fallbackId = useId();
    id ??= fallbackId;

    const component = (
        <>
            <Wrapper {...(props.title ? { title: props.title } : {})}>
                <StyledLabel
                    htmlFor={id}
                    onChange={onLocalChange}
                    css={isDisabled && disabledStyles}
                >
                    <StyledInput
                        checked={checked}
                        name={name}
                        onChange={onLocalChange}
                        id={id}
                        required={required}
                        className={className}
                        type="checkbox"
                        disabled={isDisabled}
                        {...props}
                    />
                    {children || label}
                </StyledLabel>
            </Wrapper>
            {hasError && (
                <FormError
                    className={errorClassName}
                    gtag={`error:${name}`}
                    message={getErrorMessage()}
                />
            )}
        </>
    );

    return withField ? withFieldWrapper(component) : component;
};

export default Checkbox;
