import React from "react";

import { Helmet } from "react-helmet";

import { allowIndexing } from "../../../../config";

const Robots = ({
    index = true,
    follow = true,
    archive = true,
    snippet = true,
    imageIndex = true,
}) => (
    <Helmet>
        <meta
            name="robots"
            content={(allowIndexing
                ? [
                      index ? "index" : "noindex",
                      follow ? "follow" : "nofollow",
                      archive || "noarchive",
                      snippet || "nosnippet",
                      imageIndex || "noimageindex",
                  ].filter((v) => typeof v === "string")
                : [
                      "noindex",
                      "nofollow",
                      "noarchive",
                      "nosnippet",
                      "noimageindex",
                  ]
            ).join(", ")}
        />
    </Helmet>
);
export default Robots;
