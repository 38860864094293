import React from "react";

import "../fonts/Vito/Vito.css";
import { css, Global, useTheme } from "@emotion/react";

const GlobalStyle = () => {
    const theme = useTheme();

    return (
        <Global
            styles={css`
                :root {
                    --font-family-vito: "Vito", verdana, arial, helvetica,
                        roboto, sans-serif;
                    --focus-ring-color: ${theme.colors.blue
                        .lighten(2)
                        .toString()};
                    --focus-ring-outline: 2px solid var(--focus-ring-color);
                }

                body {
                    font-family: var(--font-family-vito);
                    font-size: 1.1em;
                    background-color: white;
                }

                * {
                    box-sizing: border-box;
                }

                *,
                button,
                input,
                select,
                textarea {
                    font-family: inherit;
                }

                .has-text-right {
                    text-align: right;
                }

                .has-text-center {
                    text-align: center;
                }

                .is-flex {
                    display: flex;
                }

                textarea::placeholder,
                input::placeholder {
                    color: ${theme.colors.blue.toString()} !important;
                    opacity: 0.55 !important;
                }

                .box {
                    color: unset;
                }

                .button {
                    font-weight: 500;
                    text-transform: uppercase;
                }

                .is-light {
                    color: white;
                }

                strong {
                    font-weight: 500;
                    color: inherit;
                }

                .select {
                    &::after {
                        top: 17px !important;
                        right: 10px !important;
                        border-color: ${theme.colors.grey.toString()} !important;
                    }
                }

                .is-full-width {
                    width: 100%;
                }

                ul {
                    margin-top: 1em;
                    margin-bottom: 1em;
                    margin-left: 2em;
                    list-style-type: disc;
                }

                p:not(:last-child) {
                    margin-bottom: 1em;
                }

                .product-details {
                    flex: 1 1 0;

                    .columns {
                        align-items: flex-end;
                    }

                    .column {
                        padding-top: 2px;
                        padding-bottom: 2px;

                        &.details-value {
                            font-weight: 500;
                        }
                    }

                    .total-amount {
                        display: block;
                        margin-top: 20px;
                        margin-bottom: 20px;
                        font-size: 24px;
                        font-weight: 500;

                        .column {
                            display: inline-block;
                        }

                        .column:last-child {
                            float: right;
                        }
                    }

                    ul {
                        padding-left: 1.1em;
                        list-style-type: disc;
                    }

                    @media (min-width: 1024px) {
                        padding-top: 40px;
                        padding-bottom: 40px;
                        margin-top: -20px;
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                }
            `}
        />
    );
};
export default GlobalStyle;
