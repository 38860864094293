import React from "react";

import styled from "@emotion/styled";

const ChevronIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M8.033,23a1.04719,1.04719,0,0,1-.73033-.293.97729.97729,0,0,1,0-1.41409l9.20509-8.91116a.6.6,0,0,0-.0022-.86429L7.3117,2.70707a.97727.97727,0,0,1,0-1.41409,1.05676,1.05676,0,0,1,1.46066,0L18.416,10.62919a1.89523,1.89523,0,0,1-.00007,2.73388L8.76328,22.707A1.04719,1.04719,0,0,1,8.033,23Z" />
        </SVG>
    );
};

export default ChevronIcon;
