import React from "react";

import KeycloakProviderBrowser from "../../providers/AuthProvider/Browser";
import ContextShared from "./Shared";

const ContextBrowser = ({ children }) => (
    <KeycloakProviderBrowser>
        <ContextShared>{children}</ContextShared>
    </KeycloakProviderBrowser>
);
export default ContextBrowser;
