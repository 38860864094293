import React from "react";

import styled from "@emotion/styled";

const CalculatorIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M19,8.46952A.6352.6352,0,0,1,18.29988,9H5.70012A.6352.6352,0,0,1,5,8.46952v-2.939A.6352.6352,0,0,1,5.70012,5H18.29988A.6352.6352,0,0,1,19,5.53048Z" />
            <rect x="5.99996" y="11" width="3" height="3" rx="0.74999" />
            <rect x="10" y="11" width="3" height="3" rx="0.74999" />
            <path d="M18,18.33335A.71412.71412,0,0,1,17.25,19h-1.5a.71412.71412,0,0,1-.75-.66665V11.66664A.71412.71412,0,0,1,15.75,11h1.5a.71412.71412,0,0,1,.75.66664Z" />
            <rect x="5.99996" y="16" width="3" height="3" rx="0.74999" />
            <rect x="10" y="16" width="3" height="3" rx="0.74999" />
            <path d="M21,1H2.99992A1.01229,1.01229,0,0,0,2,2.0184V21.98157a1.01229,1.01229,0,0,0,1,1.0184H21a1.0123,1.0123,0,0,0,1-1.0184V2.0184A1.01229,1.01229,0,0,0,21,1ZM20,20.35157a.60743.60743,0,0,1-.6.6111H4.5999A.60733.60733,0,0,1,4,20.35157V3.62879A.60734.60734,0,0,1,4.6,3.01777H19.4a.60739.60739,0,0,1,.6.611Z" />
        </SVG>
    );
};

export default CalculatorIcon;
