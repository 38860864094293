import React from "react";

import styled from "@emotion/styled";

const ZielIcon = ({
    color = "#f0f0f0",
    hoverColor = color,
    innerColor = "white",
}) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
        >
            <path
                fill={color}
                d="M17.50452,3.26672a6.65844,6.65844,0,0,0-10.00995.00049A6.30808,6.30808,0,0,0,6.09943,8.50592c.85827,5.02667,5.5307,9.95386,5.729,10.16089A1.07928,1.07928,0,0,0,12.60431,19h.01117a1.082,1.082,0,0,0,.77991-.35138c.18914-.206,4.64807-5.12207,5.50524-10.1427A6.30965,6.30965,0,0,0,17.50452,3.26672Z"
            />
            <circle cx="12.5" cy="7.50001" r="2.5" fill={innerColor} />
        </SVG>
    );
};

export default ZielIcon;
