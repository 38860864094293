import { useEffect, useState } from "react";

import { AuthContextProps, hasAuthParams, useAuth } from "react-oidc-context";

// eslint-disable-next-line import/named
import { jwtDecode, JwtPayload } from "jwt-decode";
import { User } from "oidc-client-ts";

import { authority, clientId, redirectUri } from "../../keycloak";
import { storageSession } from "../../utils/StorageHandler";

const fallbackPreviousAuthPath = "/hoyercard-portal";
const previousPathStorageKey = "auth:previous-path";

export const useKeycloakOpt = (): {
    authenticated: boolean;
    email?: string;
} => {
    const auth = useAuth();
    const isAuthenticated = useIsAuthenticated();
    if (isAuthenticated) {
        const decodedToken = decodeAccessToken(auth);
        if (decodedToken !== null) {
            return {
                authenticated: true,
                email: decodedToken.email,
            };
        }
    }

    return {
        authenticated: false,
        email: undefined,
    };
};

export const decodeAccessToken = (
    auth: AuthContextProps
): KeycloakJwtToken | null => {
    if (auth.user) {
        return jwtDecode(auth.user.access_token) as KeycloakJwtToken;
    }
    return null;
};

export const useLogout = () => {
    const auth = useAuth();

    return () => {
        void auth.removeUser();
        cleanOidcEntriesFromStorage();
        void auth.signoutRedirect({
            post_logout_redirect_uri: window.location.origin,
        });
    };
};

export const useAutomaticallySignIn = () => {
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    // automatically sign-in
    useEffect(() => {
        if (
            !hasAuthParams() &&
            !auth.isAuthenticated &&
            !auth.activeNavigator &&
            !auth.isLoading &&
            !hasTriedSignin
        ) {
            setPreviousAuthPath();
            void auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);
};

export const useIsAuthenticated = (): boolean | undefined => {
    const auth = useAuth();

    return auth && auth.isAuthenticated && !auth.error;
};

export interface KeycloakJwtToken extends JwtPayload {
    email: string;
    realm_access?: {
        roles?: Array<string>;
    };
}

export const cleanOidcEntriesFromStorage = () => {
    const allKeys = Object.keys(localStorage);
    allKeys.forEach((key) => {
        if (key.startsWith("oidc.")) {
            localStorage.removeItem(key);
        }
    });
};

export const getAccessToken = () => {
    const oidcStorage = storageSession.get(
        `oidc.user:${authority}:${clientId}`
    );

    const user = User.fromStorageString(oidcStorage);

    if (user) {
        return user.access_token;
    }
    return undefined;
};

export const getPreviousAuthPath = (): string => {
    const prevPath = storageSession.get(
        previousPathStorageKey,
        fallbackPreviousAuthPath
    );

    if (!prevPath || prevPath.includes("login-redirect")) {
        return fallbackPreviousAuthPath;
    }
    return prevPath;
};

export const hasPreviousAuthPath = (): boolean => {
    return storageSession.get(previousPathStorageKey) !== null;
};

export const setPreviousAuthPath = () => {
    let prevPath =
        window.location.pathname +
        window.location.search +
        window.location.hash;
    if (prevPath.startsWith(redirectUri)) {
        return;
    }
    if (prevPath.includes("login-redirect")) {
        prevPath = fallbackPreviousAuthPath;
    }
    storageSession.set("auth:previous-path", prevPath);
};
