import { apiBase, env } from "../config";
import { getAccessToken } from "../providers/AuthProvider";
import { consentKeys } from "../utils/consentHelper";
import StorageHandler, { sessionStorage } from "../utils/StorageHandler";

export type Path = `/${string}`;
export const isPath = (s: string): s is Path => s.startsWith("/");
export const normalisePath = (s: string): Path => {
    if (isPath(s)) return s;
    if (env !== "production") {
        console.warn("%s doesn't start with / and thus isn't a valid Path", s);
    }
    return `/${s}`;
};
export const apiResolve = (path: string): URL => {
    if (isPath(path)) {
        return new URL(path.slice(1), apiBase);
    }
    throw new Error("API paths must start with a slash");
};
export const apiResolveStr = (path: Path): string =>
    apiResolve(path).toString();
export const apiResolveStrWeak = (path: string): string =>
    apiResolveStr(normalisePath(path));

const fetchAndLogExceptions = async (req: Request) => {
    const response = await fetch(req);

    if (response.status === 403) {
        void import("@sentry/gatsby").then((Sentry) =>
            Sentry.captureException(
                new Error(
                    `API error: Received ${response.status} in response to ${req.method} ${req.url}`
                ),
                {
                    level: "error",
                    extra: {
                        headers: [...req.headers.entries()].filter(
                            ([k]) => !["authorization", "cookie"].includes(k)
                        ),
                        method: req.method,
                        url: req.url,
                        status: response.status,
                        statusText: response.statusText,
                    },
                }
            )
        );
    }

    return response;
};

export const apiFetch = (request: RequestInfo, init: RequestInit = {}) =>
    fetchAndLogExceptions(
        new Request(
            typeof request === "string"
                ? apiResolveStr(request as Path)
                : new Request({
                      ...request,
                      url: apiResolveStr(request.url as Path),
                  }),
            { mode: "cors", credentials: "include", ...init }
        )
    );

const storage = new StorageHandler();

const transformOrderCheckoutBody = (body: string) => {
    const newBody = JSON.parse(body);
    newBody.parameter = sessionStorage.getItem("app:parameter");
    newBody.referrer = sessionStorage.getItem("app:referrer");
    newBody.consentAnalyse = storage.getBoolean(consentKeys.analytics);
    newBody.consentMarketing = storage.getBoolean(consentKeys.marketing);
    newBody.consentDebugging = {
        client_id: storage.get("client_id"),
        [consentKeys.general]: storage.getBoolean(consentKeys.general),
        [consentKeys.marketingChangedAt]: storage.get(
            consentKeys.marketingChangedAt
        ),
        [consentKeys.youtube]: storage.getBoolean(consentKeys.youtube),
        [consentKeys.marketing]: storage.getBoolean(consentKeys.marketing),
        [consentKeys.analytics]: storage.getBoolean(consentKeys.analytics),
        [consentKeys.analyticsChangedAt]: storage.get(
            consentKeys.analyticsChangedAt
        ),
        [consentKeys.analyticsPreselect]: storage.getBoolean(
            consentKeys.analyticsPreselect
        ),
    };

    return JSON.stringify(newBody);
};

export const apiOrderCheckoutFetch = (
    request: RequestInfo,
    { body, ...init }: RequestInit & { body: string }
) =>
    apiFetch(request, {
        ...init,
        body: transformOrderCheckoutBody(body),
    });

const setupInit = (
    { headers = {}, body, ...otherOptions }: RequestInit = {},
    isFileUpload = false
): RequestInit & { headers: Headers } => {
    const init: RequestInit & { headers: Headers } = {
        ...otherOptions,
        body,
        headers: new Headers(headers),
    };
    init.headers.set("accept", "application/json");

    // if you want to upload files using `new FormData()` the content-type is automatically determined when using fetch
    // if you always set "application/json" here it'll be overwritten resulting in an empty request.
    if (!isFileUpload && body !== undefined && !(body instanceof FormData)) {
        init.headers.set("content-type", "application/json");
    }

    return init;
};

const setupToken = async ({
    headers,
    ...otherOptions
}: RequestInit = {}): Promise<RequestInit & { headers: Headers }> => {
    const init: RequestInit & { headers: Headers } = {
        ...otherOptions,
        headers: new Headers(headers),
    };
    const token = getAccessToken();

    if (token) {
        init.headers.set("authorization", `Bearer ${token}`);
    }
    return init;
};

export type ApiResponse<a> = a & { response: Response };

export class ApiError<e extends Record<string, any>> extends Error {
    constructor(public readonly response: Response, public readonly data: e) {
        super(
            "message" in data && typeof data?.message === "string"
                ? (data.message as string)
                : undefined
        );
    }
}

export const isApiError = (v: unknown): v is ApiError<any> =>
    typeof v === "object" && v instanceof ApiError;
/** @throws any */
export const filterApiError = (e: unknown): ApiError<any> => {
    if (isApiError(e)) return e;
    throw e;
};
export const isClientError = (
    v: ApiError<any>
): v is ApiError<{ message: string; errors?: any }> =>
    v.response.status >= 400 &&
    v.response.status < 500 &&
    typeof v.data.message === "string";
/** @throws ApiError */
const handleResponse = async <a>(
    response: Response
): Promise<ApiResponse<a>> => {
    const data = await response.json();

    if (!response.ok) {
        throw new ApiError(response, data);
    }
    return { response, ...data };
};

/**
 * Fetch a protected route.
 */
export function apiFetchProtected<a>(
    request: RequestInfo,
    init?: RequestInit,
    noJson?: false,
    isFileUpload?: boolean
): Promise<a>;
export function apiFetchProtected(
    request: RequestInfo,
    init: RequestInit | undefined,
    noJson: true,
    isFileUpload?: boolean
): Promise<Response>;
export async function apiFetchProtected(
    request: RequestInfo,
    init: RequestInit = {},
    noJson = false,
    isFileUpload = false
) {
    const res = await apiFetch(
        request,
        await setupToken(setupInit(init, isFileUpload))
    );

    if (noJson) {
        return res;
    }
    return res.json();
}

/** @throws ApiError */
export const errorAwareApiFetchProtected = async <a>(
    request: RequestInfo,
    init: RequestInit = {},
    isFileUpload = false
): Promise<ApiResponse<a>> =>
    handleResponse<a>(
        await apiFetch(request, await setupToken(setupInit(init, isFileUpload)))
    );

/** @throws ApiError */
export const errorAwareApiFetch = async <a>(
    request: RequestInfo,
    init: RequestInit = {},
    isFileUpload = false
): Promise<ApiResponse<a>> =>
    handleResponse<a>(await apiFetch(request, setupInit(init, isFileUpload)));

/** @throws ApiError */
export const errorAwareChekoutFetch = async <a>(
    request: RequestInfo,
    { body, ...init }: RequestInit & { body: string },
    isFileUpload = false
): Promise<ApiResponse<a>> =>
    handleResponse<a>(
        await apiFetch(
            request,
            setupInit(
                { ...init, body: transformOrderCheckoutBody(body) },
                isFileUpload
            )
        )
    );

export const apiDownloadProtected = async (request: RequestInfo) =>
    apiFetchProtected(request, {}, true);
