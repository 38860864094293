import React from "react";

import styled from "@emotion/styled";

const HeadroomIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M15.29291,13.42139,13,15.73236V8.26764l2.29291,2.311a.99441.99441,0,0,0,1.41418,0,1.01341,1.01341,0,0,0,0-1.42535l-3.45971-3.637a1.78326,1.78326,0,0,0-1.2392-.51465L12,5l-.00818.00165a1.72569,1.72569,0,0,0-1.24548.52081L7.29291,9.15326a1.01341,1.01341,0,0,0,0,1.42535.99446.99446,0,0,0,1.41418.00006L11,8.26764v7.46472l-2.29291-2.311a.99435.99435,0,0,0-1.41418.00006,1.01334,1.01334,0,0,0,0,1.42529l3.45343,3.63086a1.72569,1.72569,0,0,0,1.24548.52081L12,19l.00818-.00165a1.78326,1.78326,0,0,0,1.2392-.51465l3.45971-3.637a1.01334,1.01334,0,0,0,0-1.42529A.9943.9943,0,0,0,15.29291,13.42139Z" />
            <path d="M22,3H2A1,1,0,0,1,2,1H22a1,1,0,0,1,0,2Z" />
            <path d="M22,23H2a1,1,0,0,1,0-2H22a1,1,0,0,1,0,2Z" />
        </SVG>
    );
};

export default HeadroomIcon;
