import React from "react";

import styled from "@emotion/styled";

import { iSvgIconProps } from "../types";

const TruckIcon = ({
    color = "#f0f0f0",
    hoverColor = color,
}: iSvgIconProps) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M2.752,10.7,5.186,9.223,7.038,5.57a1.575,1.575,0,0,1,1.453-.984L20.412,4.5h.013a1.571,1.571,0,0,1,1.11.454A1.549,1.549,0,0,1,22,6.065v9.871A1.572,1.572,0,0,1,20.423,17.5h-.561a2.049,2.049,0,0,1-.142,1.773,2.316,2.316,0,0,1-1.8,1.221A2.23,2.23,0,0,1,15.638,17.5H10.362a2.049,2.049,0,0,1-.142,1.773,2.316,2.316,0,0,1-1.8,1.221A2.23,2.23,0,0,1,6.138,17.5H3.577A1.572,1.572,0,0,1,2,15.936v-3.9A1.572,1.572,0,0,1,2.752,10.7ZM17.776,18.75a.5.5,0,1,0-.5-.5A.5.5,0,0,0,17.776,18.75Zm-9.5,0a.5.5,0,1,0-.5-.5A.5.5,0,0,0,8.276,18.75ZM3.97,15.544H20.03V6.459L8.752,6.54,6.7,10.6,3.97,12.258Z" />
        </SVG>
    );
};

export default TruckIcon;
