import React from "react";

import styled from "@emotion/styled";

const CheckIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG
            id="Ebene_1"
            dataName="Ebene 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path
                className="cls-1"
                d="M1.29017,12.08692a.96834.96834,0,0,1,1.40058,0l5.281,5.21938a.6.6,0,0,0,.85444-.01105L21.30925,4.30262a.96158.96158,0,0,1,1.40058,0,1.06418,1.06418,0,0,1,0,1.46066L9.71128,19.19473a1.86125,1.86125,0,0,1-2.7078,0L1.29017,13.54758A1.00679,1.00679,0,0,1,1.29017,12.08692Z"
            />
        </SVG>
    );
};

export default CheckIcon;
