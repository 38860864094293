import React, {
    ComponentProps,
    FC,
    JSXElementConstructor,
    PropsWithChildren,
    ReactElement,
} from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";

export type Breakpoint =
    | "mobile"
    | "tablet"
    | "desktop"
    | "widescreen"
    | "fullhd";

export type ColumnSize =
    | "full"
    | "half"
    | `one-${"third" | "quarter" | "fifth"}`
    | "two-thirds"
    | "three-quarters"
    | `${"two" | "three" | "four"}-fifths`
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12;

export type ColumnProps = Partial<Record<Breakpoint, ColumnSize>> & {
    className?: string;
};

export const Column: FC<ColumnProps> = ({
    mobile,
    tablet = mobile,
    desktop = tablet,
    widescreen = desktop,
    fullhd = widescreen,
    className,
    ...props
}) => (
    <div
        className={classNames(
            "column",
            mobile && `is-${mobile}-mobile`,
            tablet && `is-${tablet}-tablet`,
            desktop && `is-${desktop}-desktop`,
            widescreen && `is-${widescreen}-widescreen`,
            fullhd && `is-${fullhd}-fullhd`,
            className
        )}
        {...props}
    />
);

const preparedColumn = <ks extends Breakpoint>(
    props2: Record<ks, ColumnSize>,
    displayName: string
) => {
    const Column_ = (props: Omit<ColumnProps, ks>) => (
        <Column {...props} {...props2} />
    );
    Object.assign(Column_, { displayName });

    return Column_;
};

export const ColumnFull = preparedColumn({ mobile: "full" }, "ColumnFull");

export const ColumnTwoThirds = preparedColumn(
    {
        mobile: "full",
        tablet: "full",
        desktop: "two-thirds",
    },
    "ColumnTwoThirds"
);

export const ColumnHalf = preparedColumn(
    { mobile: "full", tablet: "half" },
    "ColumnHalf"
);

export const ColumnOneThird = preparedColumn(
    {
        mobile: "full",
        tablet: "full",
        desktop: "one-third",
    },
    "ColumnOneThird"
);

export const ColumnOneQuarter = preparedColumn(
    {
        mobile: "full",
        tablet: "full",
        desktop: "one-quarter",
    },
    "ColumnOneQuarter"
);

export const ColumnLarge = preparedColumn(
    { mobile: "full", tablet: "half", desktop: "two-thirds" },
    "ColumnLarge"
);
/** @deprecated */
export const LargeColumn = ColumnLarge;

export const ColumnSmall = preparedColumn(
    {
        mobile: "full",
        tablet: "half",
        desktop: "one-third",
    },
    "ColumnSmall"
);
/** @deprecated */
export const SmallColumn = ColumnSmall;

export type ColumnsProps<
    renderAs extends
        | keyof JSX.IntrinsicElements
        | JSXElementConstructor<any> = "div"
> = {
    renderAs?: renderAs;
    sameHeight?: boolean;
    centered?: boolean;
    className?: string;
} & ComponentProps<renderAs>;

const RawColumns = <
    renderAs extends
        | keyof JSX.IntrinsicElements
        | JSXElementConstructor<any> = "div"
>({
    renderAs: Component = "div" as any,
    sameHeight,
    centered,
    className,
    ...props
}: PropsWithChildren<ColumnsProps<renderAs>>): ReactElement<
    any,
    any
> | null => (
    <Component
        className={classNames(
            "columns",
            "is-multiline",
            {
                "is-centered": centered,
            },
            className
        )}
        {...props}
    />
);

const Columns = Object.assign(
    styled(RawColumns)`
        ${({ sameHeight }) =>
            sameHeight &&
            css`
                .column > div {
                    height: 100%;
                }
            `}
    `,
    {
        /** @deprecated */
        Column: Column,
        /** @deprecated */
        Full: ColumnFull,
        /** @deprecated */
        TwoThirds: ColumnTwoThirds,
        /** @deprecated */
        Half: ColumnHalf,
        /** @deprecated */
        OneThird: ColumnOneThird,
        /** @deprecated */
        OneQuarter: ColumnOneQuarter,
    }
);
Columns.defaultProps = {
    sameHeight: false,
    centered: false,
};
export default Columns;
