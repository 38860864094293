import React from "react";

import styled from "@emotion/styled";

const Button = (props) => <button type="button" {...props} />;

const Clickable = styled(Button)`
    all: unset;
    cursor: pointer;
`;
export default Clickable;
