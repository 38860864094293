import React from "react";

import styled from "@emotion/styled";

const CloseIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M12.70709,17.42137a.99437.99437,0,0,0-1.41418,0L9,19.73236V2.00788a1,1,0,1,0-2,0V19.73236L4.70709,17.42131a.99443.99443,0,0,0-1.41418.00006,1.0134,1.0134,0,0,0,0,1.42534l3.45342,3.63083a1.7258,1.7258,0,0,0,1.24549.5208L8,23l.00818-.00166a1.78335,1.78335,0,0,0,1.2392-.51463l3.45971-3.637A1.0134,1.0134,0,0,0,12.70709,17.42137Z" />
            <path d="M20.70709,6.57863a.99437.99437,0,0,1-1.41418,0L17,4.26764V21.99212a1,1,0,1,1-2,0V4.26764L12.70709,6.57869a.99443.99443,0,0,1-1.41418-.00006,1.0134,1.0134,0,0,1,0-1.42534l3.45342-3.63083a1.7258,1.7258,0,0,1,1.24549-.5208L16,1l.00818.00166a1.78335,1.78335,0,0,1,1.2392.51463l3.45971,3.637A1.0134,1.0134,0,0,1,20.70709,6.57863Z" />
        </SVG>
    );
};

export default CloseIcon;
