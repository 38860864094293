import React from "react";

import styled from "@emotion/styled";

const ClockIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M12,3a9,9,0,1,0,9,9A9,9,0,0,0,12,3Zm0,16a7,7,0,1,1,7-7A7,7,0,0,1,12,19Z" />
            <path d="M13.5,16a.9969.9969,0,0,1-.68066-.26758L11.13867,14.1709A2.00928,2.00928,0,0,1,10.5,12.70605V9a1,1,0,0,1,2,0v3.706l1.68066,1.56153A1,1,0,0,1,13.5,16Z" />
        </SVG>
    );
};

export default ClockIcon;
