import { Validation, ValidationStr } from "./Validator";

type ValidationName<v extends string> =
    v extends `${string}:${string}:${infer vn}`
        ? vn
        : v extends `${string}:${infer vn}`
        ? vn
        : v;
type ValidationNames =
    | ValidationName<ValidationStr>
    | "default"
    | "gascounterNumber"
    | "eleAmountMaxReached"
    | "streetNameMissing"
    | "houseNumMissing"
    | "delivery_counter_number";
export const validationToValidationName = (
    v: Validation | ValidationStr
): ValidationNames => v.split(":").pop() as ValidationNames;

const getMale = (value: string) => {
    return `Gib bitte einen ${value} ein.`;
};

const getFemale = (value: string) => {
    return `Gib bitte eine ${value} ein.`;
};

const errorMessages: Record<ValidationNames, string> = {
    company: getFemale("Firma"),
    name: getMale("Namen"),
    email: getFemale("E-Mail-Adresse"),
    phone: getFemale("Telefonnummer"),
    fax: getFemale("Faxnummer"),
    street: getFemale("Straße"),
    city: getMale("Ort"),
    zipcode: getFemale("gültige Postleitzahl"),
    amount: "Bitte gebe eine Menge zwischen 500 und 32.000 Liter ein.",
    consumption: "Gebe bitte Deinen Verbrauch ein.",
    up: "Gib bitte eine Zahl zwischen 1 und 10 ein.",
    bic: "Gib bitte eine gültige BIC ein.",
    iban: "Gib bitte eine IBAN ein.",
    pin: "Gib bitte 4 Zahlen ein.",
    gascounterNumber: "Gib bitte eine Zählernummer ein.",
    //electricity-erdgas
    eleAmountMaxReached: "Gib bitte einen Verbrauch von max. 100.000 kWh ein.",
    streetNameMissing: "Wähle bitte eine Straße aus.",
    houseNumMissing: "Gib bitte eine Hausnummer ein.",
    delivery_counter_number: "Gebe bitte Deine Zählernummer ein.",
    date: "Gib bitte ein Datum (tt.mm.jjjj) ein.",
    counterNumber: "Gib gebe eine Zählernummer ein.",
    birthdate: "Gib bitte Dein Geburtsdatum ein.",
    subject: getMale("Betreff"),
    message: getFemale("Nachricht"),
    description: getFemale("Beschreibung"),
    house_number: getFemale("Hausnummer"),
    houseNumber: getFemale("Hausnummer"),
    pelLooseAmount: "Bitte gib eine Menge zwischen 1.000 und 25.000 kg ein.",
    vatNumber: getFemale("Steuer-Nr."),
    vatId: getFemale("Ust-ID"),
    integer: getFemale("Zahl"),
    activationCode: getMale("Aktivierungscode"),
    customerNumber: "Gib bitte eine gültige Kundennummer ein (max 9 Zeichen).",
    counter:
        "Gib bitte eine gültige Zählernummer ein (max 15 stellige Nummer).",
    counterLevel: "Gib bitte einen gültigen Zählerstand ein (z.B. 15354,452).",
    counterLevelInt: "Gib bitte einen gültigen Zählerstand ein (z.B. 15354).",
    address: "Gib bitte eine gültige Adresse an.",
    hoyerGasElectricityCustomerNumber:
        "Gib bitte eine gültige Kundennummer ein",
    isPrivacyPolicyAccepted:
        "Du musst die Datenschutzerklärung akzeptieren, um fortfahren zu können.",
    lPGCustomerNumber:
        "Gib bitte eine oder zwei mit einem / getrennte gültige Kundennummer ein (max 9 Zeichen).",
    default: "Fülle bitte dieses Feld aus.",
    alphanumeric: "Gib bitte einen alphanumerischen Wert ein.",
    numeric: "Gib bitte eine Zahl ein.",
    addressObject: "Gib bitte die Adresse des Objekts an.",
    size: "Gib bitte eine Grundstückgröße an.",
    voucher: "Gib bitte einen Gültigen Gutschein ein (8-12 Zeichen).",
};

export default errorMessages;

export const getValidationErrorMessage = (
    v?: Validation | ValidationStr
): string =>
    v == null
        ? errorMessages.default
        : errorMessages[validationToValidationName(v)];
