import React from "react";

import { SSRProvider } from "react-aria";

import CookieConsentProvider from "../../providers/CookieConsentProvider";
import GraphqlLaravelLighthouseProvider from "../../providers/GraphqlLaravelLighthouse";
import { PageDataProvider } from "../shared/Page";
import ThemeProvider from "../Theme";

const ContextShared = ({ children }) => (
    <SSRProvider>
        <GraphqlLaravelLighthouseProvider>
            <PageDataProvider>
                <CookieConsentProvider>
                    <ThemeProvider>{children}</ThemeProvider>
                </CookieConsentProvider>
            </PageDataProvider>
        </GraphqlLaravelLighthouseProvider>
    </SSRProvider>
);
export default ContextShared;
