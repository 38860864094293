import React from "react";

import styled from "@emotion/styled";

const DownloadIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M21.03335,23H2.96665A1.95661,1.95661,0,0,1,1,21.05877V18.97062a.98341.98341,0,0,1,1.96665,0v2.08815h18.0667V18.97062a.98341.98341,0,0,1,1.96665,0v2.08815A1.95661,1.95661,0,0,1,21.03335,23Z" />
            <path d="M16.70709,13.42137a.99437.99437,0,0,0-1.41418,0L13,15.73236V2.00788a1,1,0,1,0-2,0V15.73236l-2.29291-2.311a.99443.99443,0,0,0-1.41418.00006,1.0134,1.0134,0,0,0,0,1.42534l3.45342,3.63083a1.7258,1.7258,0,0,0,1.24549.5208h.01636a1.78335,1.78335,0,0,0,1.2392-.51463l3.45971-3.637A1.0134,1.0134,0,0,0,16.70709,13.42137Z" />
        </SVG>
    );
};

export default DownloadIcon;
