import React from "react";

import styled from "@emotion/styled";

import { iSvgIconProps } from "../types";

const StarIcon = ({ color = "#f0f0f0", hoverColor = color }: iSvgIconProps) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M13.074,1.7,15.657,7.17a.722.722,0,0,0,.541.411l5.775.877a1.268,1.268,0,0,1,.664,2.136l-4.179,4.259a.773.773,0,0,0-.207.665l.986,6.014a1.21,1.21,0,0,1-1.738,1.32l-5.165-2.839a.691.691,0,0,0-.669,0L6.5,22.853a1.21,1.21,0,0,1-1.738-1.32l.987-6.014a.773.773,0,0,0-.207-.665L1.363,10.595a1.268,1.268,0,0,1,.664-2.136L7.8,7.581a.722.722,0,0,0,.541-.411L10.926,1.7a1.175,1.175,0,0,1,2.148,0Z" />
        </SVG>
    );
};

export default StarIcon;
