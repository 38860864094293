/**
 * Checks a given redirect and resolves it correctly if the page is used in a Webview/Iframe.
 * under the ...hoyer.de/webview/* URL
 * @param path
 */
const resolveWebView = (path: string): string => {
    if (typeof window === "undefined") {
        return path;
    }
    if (
        window.location.href.includes("/webview/") &&
        !path.includes("/webview/")
    ) {
        return "/webview" + path;
    }
    return path;
};

export const isWebView = (): boolean => {
    if (typeof window === "undefined") {
        return false;
    }
    return window.location.href.includes("/webview/");
};

export const getParentReferrerUrl = (): string => {
    if (typeof document === "undefined") {
        return "none";
    }
    return document?.referrer ? document.referrer : "none";
};

export default resolveWebView;
