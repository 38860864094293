import React from "react";

import styled from "@emotion/styled";

const RestaurantIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M20.25684,4.98865A4.98742,4.98742,0,0,0,16.919,3.5766,5.12933,5.12933,0,0,0,12.49707,1,5.19461,5.19461,0,0,0,7.72852,4.28851c-.11622-.00927-.23145-.01367-.34473-.01367A5.4771,5.4771,0,0,0,2,9.82892,5.63489,5.63489,0,0,0,3.82422,13.9953a1.00013,1.00013,0,1,0,1.3457-1.47992A3.63052,3.63052,0,0,1,4,9.82892,3.47567,3.47567,0,0,1,7.38379,6.27478a3.17308,3.17308,0,0,1,.79492.11328l1.01563.25977.21191-1.02637a3.22222,3.22222,0,0,1,3.09082-2.62152,3.08681,3.08681,0,0,1,2.2984,1.05353,5.41669,5.41669,0,0,0-2.00738,1.51721A1.00012,1.00012,0,0,0,14.333,6.84119,3.41177,3.41177,0,0,1,16.94,5.594a2.96186,2.96186,0,0,1,1.934.84A3.73958,3.73958,0,0,1,20,9.13312a3.52181,3.52181,0,0,1-2.71484,3.48431l-.794.16693.02353,7.61294a.6.6,0,0,1-.60084.60185l-7.805-.011a.6.6,0,0,1-.59915-.6V16.76672a1,1,0,0,0-2,0V20.9873A1.99622,1.99622,0,0,0,7.4834,23h9.0332a1.99642,1.99642,0,0,0,1.97461-2.0127V14.34A5.57071,5.57071,0,0,0,22,9.13312,5.75113,5.75113,0,0,0,20.25684,4.98865Z" />
        </SVG>
    );
};

export default RestaurantIcon;
