import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrAfter";
import isSameOrAfter from "dayjs/plugin/isSameOrBefore";
import weekday from "dayjs/plugin/weekday";

// Configure dayjs
import "dayjs/locale/de";

dayjs.locale("de");
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(weekday);
