import React, { useContext, useEffect } from "react";

import { graphql, useStaticQuery } from "gatsby";
import { useIsSSR } from "react-aria";
import Helmet from "react-helmet";
import { IntlProvider as RawIntlProvider } from "react-intl";

import de from "../intl/de.json";
import { DataContext } from "../providers/CookieConsentProvider";
import { getUrlParam } from "../utils/urlParams";
import GlobalStyle from "./GlobalStyle";
import BottomButtons from "./shared/BottomButtons";
import CookieConsent from "./shared/CookieConsent";
import { WithFooter } from "./shared/Footer";
import Robots from "./shared/Meta/Robots";
import { ModalPortalAnchor } from "./shared/Modal/Portal";
import { WithNavigationContainer } from "./shared/Navigation";
import NewsletterModal from "./shared/NewsletterModal";
import useStore from "./shared/OrderUserAttribution/store";
import ScrollToTop from "./shared/ScrollToTop";
import SEO from "./shared/SEO";
import ShoppingCartFloatingButton from "./shared/ShoppingCartFloatingButton";
import Snackbar from "./shared/Snackbar";

export const IntlProvider = ({ children, ...props }) => {
    const intl = { language: "de", defaultLocale: "de", messages: de };

    return (
        <RawIntlProvider
            locale={intl.language}
            defaultLocale={intl.defaultLocale}
            messages={intl.messages}
            formats={{
                date: {
                    short: {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                    },
                    full: {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                    },
                },
            }}
            {...props}
            timeZone="Europe/Berlin"
        >
            {children}
        </RawIntlProvider>
    );
};

const RootLayout = ({ location, children, data }) => {
    const { defaultFooter } = useStaticQuery(
        graphql`
            query {
                defaultFooter: footersYaml(yamlId: { eq: "default" }) {
                    ...Footer
                }
            }
        `
    );

    const [setReferrer, setParameter] = useStore((state) => [
        state.setReferrer,
        state.setParameter,
    ]);

    const { slug, meta = {}, footer = defaultFooter } = data?.page ?? {};

    const isSSR = useIsSSR();

    const isLegacyPage = !data || (data.page?.isLegacyPage ?? false);

    useEffect(() => {
        if (!isSSR) {
            setReferrer();
            setParameter(location);
        }
    }, [isSSR]);
    // View for external usage of some components
    // used via webview/iframe do not Show Navigation or Footer in this Layout.
    if (children?.props?.pageContext?.isWebView === true) {
        return (
            <WebView slug={slug} meta={meta} isLegacyPage={isLegacyPage}>
                <Snackbar />
                {children}
            </WebView>
        );
    }

    return (
        <IntlProvider>
            <Snackbar />
            <ModalPortalAnchor />

            <GlobalStyle />

            <SEO {...{ slug, ...meta }} />

            <Robots />

            {isLegacyPage && (
                <Helmet htmlAttributes={{ class: "legacyPage" }} />
            )}
            {!isSSR && <CookieConsent />}
            <WithNavigationContainer organization={footer.contact.organization}>
                <WithFooter footer={footer}>
                    <main>{children}</main>
                </WithFooter>
            </WithNavigationContainer>
            <ScrollToTop />
            {/*<NewsletterModal />*/}
            <BottomButtons {...{ location }} />
        </IntlProvider>
    );
};
export default RootLayout;

const WebView = React.memo(({ slug, meta, isLegacyPage, children }) => {
    const dataContext = useContext(DataContext);
    const { save, setAcceptedAnalytics } = dataContext.actions;

    const isSSR = useIsSSR();

    // The App passes if the user has accepted analytics via a URL parameter
    useEffect(() => {
        if (!isSSR) {
            if (getUrlParam("analyticsPermissionGranted") === "true") {
                setAcceptedAnalytics(true);
            }
            if (getUrlParam("analyticsPermissionGranted") === "false") {
                setAcceptedAnalytics(false);
            }
            // We have to use this workaround, because the set state is not set synchronous
            setTimeout(() => save(), 50);
        }
    }, [isSSR]);

    return (
        <IntlProvider>
            <Snackbar />
            <GlobalStyle />
            <SEO {...{ slug, ...meta }} />

            <Robots index={false} />

            {isLegacyPage && (
                <Helmet htmlAttributes={{ class: "legacyPage" }} />
            )}
            <main>{children}</main>
            <ShoppingCartFloatingButton />
            <ScrollToTop />
            <ModalPortalAnchor />
        </IntlProvider>
    );
});

export const query = graphql`
    fragment Page on PageBase {
        footer {
            ...Footer
        }
        meta {
            title
            description
            lang
            altLangs {
                hreflang
                href
            }
        }
        slug
    }

    fragment PageItem on PageItemBase {
        footer {
            ...Footer
        }
        meta {
            title
            description
            lang
            altLangs {
                hreflang
                href
            }
        }
    }
`;
