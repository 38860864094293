import React from "react";

import { AuthProvider } from "react-oidc-context";

import oidcConfig from "../../keycloak";

interface KeycloakProviderProps {
    children?: React.ReactNode;
}

const KeycloakProvider = ({ children }: KeycloakProviderProps) => {
    return <AuthProvider {...oidcConfig}>{children}</AuthProvider>;
};

export default KeycloakProvider;
