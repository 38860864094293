import React from "react";

import styled from "@emotion/styled";

const UserIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M11.9995,15A7,7,0,1,1,19,8,7.00757,7.00757,0,0,1,11.9995,15Zm0-11.94481A4.94481,4.94481,0,1,0,16.94481,8,4.95,4.95,0,0,0,11.9995,3.05519Z" />
            <path d="M21.41858,23H2.58044a1.55545,1.55545,0,0,1-1.30022-.65849,1.66431,1.66431,0,0,1-.18728-1.48186l1.4836-4.48982a2.02276,2.02276,0,0,1,2.13518-1.35016l6.63866.9802a5.61538,5.61538,0,0,0,1.29924,0l6.63866-.9802a1.98666,1.98666,0,0,1,2.13518,1.34815l1.48263,4.49082.001.001a1.66431,1.66431,0,0,1-.18728,1.48186A1.55722,1.55722,0,0,1,21.41858,23ZM2.98524,21.52015l-.001.002Zm18.02952,0v0ZM4.27222,20.94108H19.7169a.8.8,0,0,0,.75965-1.05086l-.7315-2.21508a.8.8,0,0,0-.88011-.54l-5.93245.90357a7.42249,7.42249,0,0,1-1.865,0l-5.969-.88133a.8.8,0,0,0-.878.54512l-.70938,2.19231A.8.8,0,0,0,4.27222,20.94108Z" />
        </SVG>
    );
};

export default UserIcon;
