import create from "zustand";

import { errorAwareApiFetch } from "../../../../apiBindings";
import { storageLocal as storage } from "../../../../utils/StorageHandler";
import { getUrlParam } from "../../../../utils/urlParams";

interface iChosenProduct {
    id: number;
    isPremium: boolean;
    deliveryTimeType: string;
    isClimateNeutral: boolean;
}

interface iHeatingOilCalculator {
    zipcode: string;
    amount: number;
    up: number;
    result: [];
    additions: any;
    loading: boolean;
    initialLoadingFinished: boolean;
    loadingError: boolean;
    productChoice: number;
    zipcodeIsValid: boolean;
    checkingForZipcode: boolean;
    checkedZipcode: string;
    amountIsValid: boolean;
    checkingForAmount: boolean;
    shouldCalculate: boolean;
    setZipcode: (zipcode: string) => void;
    setAmount: (amount: number) => void;
    setUp: (up: number) => void;
    setResult: (result: []) => void;
    setAdditions: (additions: []) => void;
    setLoading: (loading: boolean) => void;
    setLoadingError: (loadingError: boolean) => void;
    setProductChoice: (productChoice: number) => void;
    setShouldCalculate: (shouldCalculate: boolean) => void;
    calculate: (
        localZipcode: string,
        localAmount: number,
        localUp: number,
        notify: (
            title: string,
            message: string,
            type?: string,
            userOptions?: object
        ) => void
    ) => void;
    syncProductInfos: (chosenProduct: iChosenProduct) => void;
    checkZipcode: (
        localZipcode: string,
        notify: (
            title: string,
            message: string,
            type?: string,
            userOptions?: object
        ) => void
    ) => void;
    checkAmount: (
        localZipcode: string,
        localAmount: number,
        notify: (
            title: string,
            message: string,
            type?: string,
            userOptions?: object
        ) => void
    ) => void;
    getProductWithPrices: () => number;
}

// Try to overwrite the localstorage if url params are present.
const amount = getUrlParam("quantity");
const zipcode = getUrlParam("zip");
const deliverypoints = getUrlParam("deliverypoints");

if (zipcode) {
    storage.set("hel:zipcode", zipcode);
}
if (amount) {
    storage.set("hel:amount", amount);
}
if (deliverypoints) {
    storage.set("hel:up", deliverypoints);
}

const useStore = create<iHeatingOilCalculator>((set, get) => ({
    zipcode: storage.get("hel:zipcode", ""),
    amount: storage.getInt("hel:amount", 3000),
    up: storage.getInt("hel:up", 1),
    result: [],
    additions: null,
    loading: false,
    initialLoadingFinished: false,
    loadingError: false,
    productChoice: storage.getInt("hel:product-choice", 1),
    zipcodeIsValid: true,
    checkingForZipcode: false,
    checkedZipcode: "",
    amountIsValid: true,
    checkingForAmount: false,
    shouldCalculate: false,

    setZipcode: (zipcode) => {
        set({ zipcode: zipcode });
        storage.set("hel:zipcode", zipcode);
    },

    setAmount: (amount) => {
        set({ amount: amount });
        storage.set("hel:amount", amount);
    },

    setUp: (up) => {
        set({ up: up });
        storage.set("hel:up", up);
    },

    setResult: (result) => {
        set({ result: result });
    },

    setAdditions: (additions) => {
        set({ additions: additions });
    },

    setLoading: (_loading) => {
        const { loading, initialLoadingFinished } = get();
        set({
            loading: _loading,
        });
        if (!initialLoadingFinished && !_loading && loading) {
            set({ initialLoadingFinished: true });
        }
    },

    setLoadingError: (loadingError) => {
        set({ loadingError: loadingError });
    },

    setProductChoice: (productChoice) => {
        set({ productChoice: productChoice });
        storage.set("hel:product-choice", productChoice);
    },

    setShouldCalculate: (shouldCalculate: boolean) => {
        set({ shouldCalculate: shouldCalculate });
    },

    calculate: async (localZipcode, localAmount, localUp, notify) => {
        const { loading, setLoading } = get();
        if (!loading) {
            // do the calculation
            setLoading(true);
            errorAwareApiFetch<any>(
                `/heatingoil/${localZipcode}/${localAmount}/${localUp}`
            )
                .then((response) => {
                    if (response.status === "error") {
                        set({ result: [] });
                        set({ additions: null });
                        set({ loadingError: true });
                        setLoading(false);
                        set({ zipcodeIsValid: false });
                        set({ checkedZipcode: localZipcode });
                        storage.set("hel:calculated", false);
                        notify(
                            "Fehlgeschlagen",
                            "Bitte überprüfe deine Postleitzahl"
                        );
                    } else {
                        set({ checkedZipcode: localZipcode });
                        set({ result: response.products });
                        set({ additions: response.settings });
                        setLoading(false);
                        set({ loadingError: false });
                        storage.set("hel:calculated", true);
                    }
                })
                .catch(({ data }) => {
                    set({ result: [] });
                    set({ additions: null });
                    set({ loadingError: true });
                    setLoading(false);
                    notify("Fehlgeschlagen", data.message);
                    set({ checkedZipcode: localZipcode });
                    storage.set("hel:calculated", false);
                });
        } else {
            // Still loading, do nothing
        }
    },

    syncProductInfos: (chosenProduct) => {
        if (chosenProduct) {
            storage.set("hel:product-is-premium", chosenProduct.isPremium);
            storage.set("hel:product-id", chosenProduct.id);
            storage.set("hel:delivery-time", chosenProduct.deliveryTimeType);
            storage.set("hel:climate-neutral", chosenProduct.isClimateNeutral);
        } else {
            storage.set("hel:product-is-premium", false);
        }
    },

    checkZipcode: async (localZipcode, notify) => {
        if (localZipcode.length < 5) {
            return;
        }
        set({ checkingForZipcode: true });
        set({ checkedZipcode: localZipcode });

        errorAwareApiFetch<any>(`/heatingoil/check-zipcode/${localZipcode}`)
            .then(({ is_valid }) => {
                set({ zipcodeIsValid: is_valid });
                if (is_valid) {
                    set({ loadingError: false });
                }
            })
            .catch(({ data }) => {
                set({ zipcodeIsValid: false });
                notify("Fehlgeschlagen", data.message);
            })
            .finally(() => set({ checkingForZipcode: false }));
    },

    checkAmount: async (localZipcode, localAmount, notify) => {
        if (!localZipcode || !localAmount) {
            return;
        }
        set({ checkingForAmount: true });

        errorAwareApiFetch<any>(
            `/heatingoil/check-amount/${localZipcode}/${localAmount}`
        )
            .then(({ is_valid }) => {
                set({ amountIsValid: is_valid });
            })
            .catch(({ data }) => {
                set({ amountIsValid: false });
                set({ result: [] });
                notify("Fehlgeschlagen", data.message);
            })
            .finally(() => set({ checkingForAmount: false }));
    },

    getProductWithPrices: () => {
        return get().result[get().productChoice];
    },
}));

export default useStore;
