// The environment variables must be referenced directly via `process.env.GATSBY_{name}`, otherwise they won't be available.

export const googleMapsApiKey = process.env
    .GATSBY_GOOGLE_MAPS_API_KEY as string;

export const apiBase = new URL(process.env.GATSBY_API_URL as string);
export const internalBase = new URL(process.env.GATSBY_INTERNAL_URL as string);
export const graphqlLaravelLighthouse = new URL(
    process.env.GATSBY_LARAVEL_LIGHTHOUSE_GRAPHQL_ENDPOINT as string,
);

export const allowIndexing = JSON.parse(
    process.env.GATSBY_ALLOW_INDEXING as string,
) as boolean;

export const algolia = {
    appId: process.env.GATSBY_ALGOLIA_APP_ID as string,
    searchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY as string,
    searchIndex: process.env.GATSBY_ALGOLIA_SEARCH_INDEX as string,
};

export const keycloak = {
    realm: process.env.GATSBY_KEYCLOAK_REALM as string,
    baseUri: new URL(process.env.GATSBY_KEYCLOAK_BASE_URI as string),
    clientId: process.env.GATSBY_KEYCLOAK_CLIENT_ID as string,
};

export const env = process.env.NODE_ENV ?? "production";

const mapKeys = <b, o extends Record<string, any>>(
    f: <k extends keyof o>(v: o[k], k: k, o: o) => b,
    o: o,
): { [k in keyof o]: b } =>
    Object.fromEntries(Object.entries(o).map(([k, v]) => [f(v, k, o), v]));

const missingEnvs = Object.entries({
    googleMapsApiKey,
    apiBase,
    internalBase,
    graphqlLaravelLighthouse,
    allowIndexing,
    env,
    ...mapKeys((_v, k) => `algolia.${k}`, algolia),
    ...mapKeys((_v, k) => `keycloak.${k}`, keycloak),
})
    .filter(([, v]) => v == null)
    .map(([k]) => k);
if (missingEnvs.length) {
    throw new Error(`Missing env vars: ${missingEnvs.join(", ")}`);
}
