import React, { useEffect } from "react";

import { Link } from "gatsby";

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import useStore, {
    cartStorageKey,
} from "../../../../components/pages/Shop/ShoppingCart/store";
import { storageSession } from "../../../../utils/StorageHandler";
import { IconPredefined } from "../../icons/Icon";
import { PredefinedIconType } from "../../icons/Icon/Predefined";

const IconContainer = styled.div`
    position: relative;
    ${({ theme }) => theme.breakpoints.upTo.medium.css`
          margin-top: 0.25em;
    `}
    ${({ theme }) => theme.breakpoints.from.medium.css`
        margin-left: 1em;
    `}
`;

const CartQuantityIndicator = styled.span`
    position: absolute;
    bottom: 0.5em;
    left: 1em;
    display: inline-block;
    width: 1.25em;
    height: 1.25em;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1.25em;
    color: ${({ theme }) => theme.colors.white.toString()};
    text-align: center;
    background-color: ${({ theme }) => theme.colors.red.toString()};
    border-radius: 50%;
`;

const CartNav = styled.div`
    position: relative;
    margin-bottom: 8px;
`;

const ShoppingCart = () => {
    const { cart, fetchCart } = useStore((state) => ({
        cart: state.cart,
        fetchCart: state.fetchCart,
    }));

    useEffect(() => {
        if (!cart && storageSession.get(cartStorageKey)) {
            fetchCart();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Only display the Cart on shop related routes.
    if (!storageSession.get(cartStorageKey)) {
        return null;
    }

    return (
        <>
            <IconContainer>
                <CartIcon quantity={cart?.lineItems.length ?? 0} />
            </IconContainer>
        </>
    );
};

interface iCartIconProps {
    quantity: number;
    linkToCart?: string;
}
export const CartIcon = ({
    quantity = 0,
    linkToCart = "/shop/warenkorb",
}: iCartIconProps) => {
    const theme = useTheme();
    return (
        <Link to={linkToCart}>
            <CartNav>
                <IconPredefined
                    type={PredefinedIconType.shop}
                    size="1.25em"
                    color={theme.colors.red.toString()}
                />
                {quantity > 0 && (
                    <CartQuantityIndicator>{quantity}</CartQuantityIndicator>
                )}
            </CartNav>
        </Link>
    );
};

export default ShoppingCart;
