import React, { FC } from "react";

import * as St from "fp-ts/lib/ReadonlySet";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { useMultiSelectInputContext } from "../../../shared/forms/MultiSelectInput";
import Button, { ButtonProps } from "../../../shared/NewForms/Button";

export type ChipProps<a> = {
    entry: [a, string];
    targetId: string;
} & Omit<
    Extract<ButtonProps, { type: "span"; text?: false }>,
    "htmlFor" | "type" | "text"
>;

const StyledButton = styled(Button)`
    padding: 5px 10px !important;
    font-weight: normal;
    ${({ color, theme }) =>
        color === "redOutline" &&
        css`
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: ${theme.colors.red.toString()};
        `}
`;

const Chip: FC<ChipProps<any>> = ({
    entry: [value, name],
    targetId: _,
    ...props
}) => {
    const ctx = useMultiSelectInputContext<any>();

    if (!ctx) return null;

    const { value: selectValue, Ord } = ctx;

    return (
        <StyledButton
            type="span"
            color={
                St.elem(Ord)(value, selectValue) ? "redOutline" : "blueOutline"
            }
            {...props}
        >
            {name}
        </StyledButton>
    );
};
export default Chip;
