import React, { FC, ImgHTMLAttributes, ReactElement } from "react";

import type { SerializedStyles } from "@emotion/react";

import IconContainer, {
    defaultSize,
    IconContainerProps,
} from "./IconContainer";
import PredefinedIcon, {
    PredefinedIconProps,
    PredefinedIconType,
} from "./Predefined";

export type IconPredefinedProps<t extends PredefinedIconType> =
    PredefinedIconProps<t> & IconContainerProps & { alt?: string };

export const IconPredefined = <t extends PredefinedIconType>({
    type,
    color,
    hoverColor,
    alt,
    ...props
}: IconPredefinedProps<t>): ReactElement<any, any> | null => (
    <IconContainer {...props}>
        <PredefinedIcon {...{ type, color, hoverColor }} />
    </IconContainer>
);

export type IconImageProps = Pick<
    ImgHTMLAttributes<HTMLImageElement>,
    "src" | "srcSet" | "alt"
> &
    IconContainerProps;

export const IconImage: FC<IconImageProps> = ({
    src,
    srcSet,
    alt,
    size = defaultSize,
    sExtraSmall: extraSmall = size,
    sSmall: small = size,
    sMedium: medium = size,
    sNormal: normal = size,
    sLarge: large = size,
    sExtraLarge: extraLarge = size,
    ...props
}) => (
    <IconContainer {...props} {...{ size }}>
        <img width={size} height={size} {...{ src, srcSet, alt }} />
    </IconContainer>
);

/** @deprecated See Icon component */
export type PredefinedIconTypeStr = `${PredefinedIconType}`;
/** @deprecated See Icon component */
type ToPredefinedIconType<
    t extends PredefinedIconType | PredefinedIconTypeStr
> = t extends PredefinedIconType
    ? t
    : {
          "arrow-left": PredefinedIconType.arrowLeft;
          "arrow-right": PredefinedIconType.arrowRight;
          bistro: PredefinedIconType.bistro;
          "burger-menue": PredefinedIconType.burgerMenu;
          cafe: PredefinedIconType.cafe;
          calculator: PredefinedIconType.calculator;
          chevron: PredefinedIconType.chevron;
          clock: PredefinedIconType.clock;
          close: PredefinedIconType.close;
          contruction: PredefinedIconType.contruction;
          delete: PredefinedIconType.delete;
          directions: PredefinedIconType.directions;
          download: PredefinedIconType.download;
          filter: PredefinedIconType.filter;
          headroom: PredefinedIconType.headroom;
          info: PredefinedIconType.info;
          "lkw-parking": PredefinedIconType.lkwParking;
          location: PredefinedIconType.location;
          mail: PredefinedIconType.mail;
          phone: PredefinedIconType.phone;
          restaurant: PredefinedIconType.restaurant;
          route: PredefinedIconType.route;
          search: PredefinedIconType.search;
          shop: PredefinedIconType.shop;
          sort: PredefinedIconType.sort;
          user: PredefinedIconType.user;
          warning: PredefinedIconType.warning;
          ziel: PredefinedIconType.ziel;
          edit: PredefinedIconType.edit;
          dustbin: PredefinedIconType.dustbin;
          world: PredefinedIconType.world;
          star: PredefinedIconType.star;
          check: PredefinedIconType.check;
          truck: PredefinedIconType.truck;
      }[t];
/** @deprecated See Icon component */
const toPredefinedIconType = (
    t: PredefinedIconType | PredefinedIconTypeStr
): PredefinedIconType => t as PredefinedIconType;

/** @deprecated See Icon component */
export type IconProps<
    t extends PredefinedIconType | PredefinedIconTypeStr | null | undefined
> = {
    altText?: string;
    style?: SerializedStyles;
    handleClick?: () => void;
    cursor?: string;
} & (t extends null | undefined
    ? { iconName?: null; iconImg: string } & Omit<IconImageProps, "src">
    : {
          iconName: NonNullable<t>;
      } & Omit<
          IconPredefinedProps<ToPredefinedIconType<NonNullable<t>>>,
          "type" | "alt" | "onClick"
      >);

/**
 * @deprecated Use IconPredefined or IconImage instead; note that their props are
 *             more in line with the standards (type, src, onClick etc); this component
 *             was overloaded and did double duty by supporting both images and predefined
 *             icons.
 */
const Icon = <
    t extends PredefinedIconType | PredefinedIconTypeStr | null | undefined
>({
    cursor,
    handleClick,
    style,
    altText,
    ...props
}: IconProps<t>): ReactElement<any, any> | null => {
    const sharedProps = {
        alt: altText,
        onClick: handleClick,
    };

    if (props.iconName == null) {
        const { iconImg, ...props_ } = props as any as IconProps<
            null | undefined
        >;

        return (
            <IconImage css={style} src={iconImg} {...sharedProps} {...props_} />
        );
    }

    const { iconName, ...props_ } = props as IconProps<NonNullable<t>>;

    return (
        <IconPredefined
            css={style}
            type={toPredefinedIconType(iconName)}
            alt={altText}
            onClick={handleClick}
            {...props_}
        />
    );
};
export default Icon;
