import React from "react";

import styled from "@emotion/styled";

const LKWParkingIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M10.2002,15H2.7998A1.80224,1.80224,0,0,1,1,13.19971V3.7998A1.80214,1.80214,0,0,1,2.7998,2h7.4004A1.80214,1.80214,0,0,1,12,3.7998v9.39991A1.80224,1.80224,0,0,1,10.2002,15ZM3,13h7V4H3Z" />
            <path d="M5.6,9.7h1A2.13428,2.13428,0,0,0,9,7.4V7.3A2.09072,2.09072,0,0,0,6.6,5H4v7H5.6Zm0-3.4h.9c.5,0,.8.2.8.9v.2a.7955.7955,0,0,1-.9.9H5.6Z" />
            <path d="M22.67688,15.029,18.543,11.30157A1.21737,1.21737,0,0,0,17.72974,11H17V7a1,1,0,0,0-1-1H13.5a1,1,0,0,0,0,2H15V18H5V16.5a1,1,0,0,0-2,0V19a1,1,0,0,0,1,1H5.88776a2.04913,2.04913,0,0,0,.14153,1.77291A2.31585,2.31585,0,0,0,7.834,22.994,2.22986,2.22986,0,0,0,10.11224,20h5.52552a2.04913,2.04913,0,0,0,.14153,1.77291A2.31585,2.31585,0,0,0,17.584,22.994,2.22986,2.22986,0,0,0,19.86224,20h2.00135A1.0748,1.0748,0,0,0,23,19V15.72748A.94161.94161,0,0,0,22.67688,15.029ZM8,21.25a.5.5,0,1,1,.5-.5A.5.5,0,0,1,8,21.25Zm9.75,0a.5.5,0,1,1,.5-.5A.5.5,0,0,1,17.75,21.25ZM21,16.209V18H17.01331V13h.43377Z" />
        </SVG>
    );
};

export default LKWParkingIcon;
