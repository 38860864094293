import React, { createContext, FC, useContext, useMemo } from "react";

import { useIsSSR } from "react-aria";

import {
    ApolloClient,
    DocumentNode,
    InMemoryCache,
    NormalizedCacheObject,
    OperationVariables,
    QueryHookOptions,
    QueryResult,
    TypedDocumentNode,
    useQuery,
} from "@apollo/client";

import { graphqlLaravelLighthouse } from "../config";

const ctx = createContext<ApolloClient<NormalizedCacheObject> | undefined>(
    undefined
);
ctx.displayName = "GraphqlLaravelLighthouse";

const GraphqlLaravelLighthouseProvider: FC<Record<string, never>> = ({
    children,
}) => {
    const isSSR = useIsSSR();
    const client = useMemo(
        () =>
            isSSR
                ? undefined
                : new ApolloClient({
                      uri: String(graphqlLaravelLighthouse),
                      cache: new InMemoryCache(),
                  }),
        [isSSR]
    );

    return <ctx.Provider value={client}>{children}</ctx.Provider>;
};
export default GraphqlLaravelLighthouseProvider;

export const useGraphqlLaravelLighthouse = () => useContext(ctx);

export const useLaravelLighthouseQuery = <
    TData,
    TVariables extends OperationVariables = OperationVariables
>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: Omit<QueryHookOptions<TData, TVariables>, "client">
):
    | QueryResult<TData, TVariables>
    | { client: undefined; data: undefined; loading: true } => {
    const client = useGraphqlLaravelLighthouse();

    return client
        ? // Calling it conditionally is fine as the condis whether it's not in SSR
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useQuery<TData, TVariables>(query, { ...options, client })
        : {
              client,
              data: undefined,
              loading: true,
          };
};
