import React from "react";

import styled from "@emotion/styled";

const InfoIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 34.379 124.477"
            width="34.379"
            height="124.477"
        >
            <g transform="translate(-297.672 -386.17)">
                <path
                    d="M297.671,403.5v78.874a3.084,3.084,0,0,0,2.878,3.254h28.622a3.084,3.084,0,0,0,2.878-3.254V403.5H297.671Z"
                    transform="translate(0 25.021)"
                    fill="#e60028"
                />
                <path
                    d="M332.05,418.215V389.047a2.878,2.878,0,0,0-2.878-2.878h-14.13a5.754,5.754,0,0,0-4.682,2.406l-11.075,15.469a8.64,8.64,0,0,0-1.613,5.027v9.144Z"
                    transform="translate(0)"
                    fill="#e60028"
                />
            </g>
        </SVG>
    );
};

export default InfoIcon;
