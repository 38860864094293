import React from "react";

import { GatsbyBrowser } from "gatsby";

import "./src/styles/app.scss";
import "./src/locales";

import ContextBrowser from "./src/components/Context/Browser";
import RootLayout from "./src/components/RootLayout";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
    element,
    props,
}) => <RootLayout {...props}>{element}</RootLayout>;

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
    element,
}) => <ContextBrowser>{element}</ContextBrowser>;
