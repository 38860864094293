import React, { useEffect } from "react";

import { useLocation } from "@gatsbyjs/reach-router";

import { css } from "@emotion/core";
import styled from "@emotion/styled";

import { storageSession } from "../../../utils/StorageHandler";
import resolveWebView from "../../../utils/webViewRedirect";
import useStore, {
    cartStorageKey,
    iShopwareCart,
} from "../../pages/Shop/ShoppingCart/store";
import { CartIcon } from "../Navigation/ShoppingCart";

const FloatingButton = styled.button`
    all: unset;
    position: fixed;
    top: 1.2em;
    right: 1.2em;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75em;
    height: 2.75em;
    font-size: 1rem;
    user-select: none;
    background-color: ${({ theme }) => theme.colors.white.toString()};
    border: 1px solid ${({ theme }) => theme.colors.red.toString()};
    border-radius: 50%;
    box-shadow: 3px 3px 5px ${({ theme }) => theme.colors.darkGrey.toString()};
    opacity: 0.9;
    transition: 0.3s transform linear;
    scale: 1.25;
    -webkit-tap-highlight-color: transparent; /* Safari */
    -webkit-touch-callout: none;

    &:hover {
        cursor: pointer;
    }
`;

const IconWrapper = styled.div`
    padding-top: 3px;
`;

//Determinate if the Cart FAB is shown
const getStyle = (cart: iShopwareCart | null, location: Location) => {
    //Don't show the cart FAB on the following routes:
    const excludedRoutes = [
        "/webview/shop/warenkorb/",
        "/webview/shop/bestellung/",
        "/webview/shop/bestellung/fehler-bezahlung/",
        "/webview/shop/bestellung/erfolgreich/",
    ];

    //Only show the cart FAB if it is not empty and the user is not on an excluded route.
    if (
        !cart?.lineItems ||
        cart?.lineItems.length < 1 ||
        excludedRoutes.includes(location.pathname)
    ) {
        return css`
            overflow: hidden;
            visibility: hidden;
            transform: translateX(100%);
        `;
    } else {
        return css`
            z-index: 10;
            visibility: visible;
            transform: translateX(0);
        `;
    }
};

const ShoppingCartFloatingButton = () => {
    const location = useLocation();
    const { cart, fetchCart } = useStore((state) => ({
        cart: state.cart,
        fetchCart: state.fetchCart,
    }));

    useEffect(() => {
        if (!cart && storageSession.get(cartStorageKey)) {
            fetchCart();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FloatingButton css={getStyle(cart, location)}>
            <IconWrapper>
                <CartIcon
                    quantity={cart?.lineItems.length ?? 0}
                    linkToCart={resolveWebView("/shop/warenkorb")}
                />
            </IconWrapper>
        </FloatingButton>
    );
};

export default ShoppingCartFloatingButton;
