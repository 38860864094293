import React from "react";

import styled from "@emotion/styled";

import colors from "../../../../../utils/colors";
import AnimatedBurgerIcon from "../../../icons/AnimatedBurgerIcon";
import ZielIcon from "../../../icons/ZielIcon";
import { Link } from "../../../LinkComponent";
import ShoppingCart from "../../ShoppingCart";
import TechnicList from "../../TechnicList";
import Phone from "./../../Phone";

const MobileItems = styled.div`
    z-index: 100;
    display: grid;
    align-items: center;
    justify-content: center;
    ${({ theme }) => theme.breakpoints.from.normal.css`
        display: none;
    `}

    grid-auto-flow: column;
    grid-column-gap: 15px;
    color: ${colors.blue};

    ${({ theme }) => theme.breakpoints.upTo.normal.css`
        font-size: 0.95rem;
    `}
`;

const iconContainerState = (props) => ({
    width: props.small ? "20px" : "25px",
});

const IconContainer = styled.div`
    ${iconContainerState};

    display: flex;
`;

const MobileItemsContainer = ({ handleClick, isActive, organization, nav }) => {
    return (
        <MobileItems>
            <div>
                <Phone
                    defaultPhoneNumber={organization.telephone}
                    telName={nav.telName}
                />
            </div>

            {nav.mobileLinks.map(({ name, href, icon }, i) => (
                <Link key={i} to={href} title={name}>
                    <IconContainer>
                        {
                            {
                                marker: <ZielIcon color={colors.red} />,
                            }[icon]
                        }
                    </IconContainer>
                </Link>
            ))}
            <ShoppingCart />
            <TechnicList />
            <AnimatedBurgerIcon isActive={isActive} handleClick={handleClick} />
        </MobileItems>
    );
};

export default MobileItemsContainer;
