import React, { useState } from "react";

import NewInput from "../../forms/NewInput";
import { navigate } from "../../LinkComponent";
import useStore from "../store";

const SearchInput = () => {
    const { setOpen } = useStore();
    const [searchTerm, setSearchTerm] = useState("");

    const onSearch = (event) => {
        if (
            event.type === "click" ||
            (event.type === "keypress" && event.key === "Enter")
        ) {
            void navigate(`/suche/${searchTerm}`);
            setSearchTerm("");
            setOpen(null);
        }
    };

    const onLocalChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <NewInput
            id="global-search"
            placeholder="Suche"
            value={searchTerm}
            onChange={onLocalChange}
            fullWidth
            handleClick={onSearch}
            onKeyPress={onSearch}
        />
    );
};

export default SearchInput;
