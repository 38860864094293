import React, { useMemo } from "react";

import * as Ord from "fp-ts/Ord";

import Chip from "../../../../pages/GasStations/Finder/Chip";
import ColMultiSelect, {
    ColMultiSelectProps,
} from "../../../../pages/GasStations/Finder/ColMultiSelect";
import * as styles from "../../../../pages/GasStations/Finder/style.module.scss";

// Values for all available newsletter topics.
export enum NewsletterTopic {
    PRIVATKUNDE = "privatkunde",
    ENERGIEVERSORGUNG = "energieversorgung",
    ENTSORGUNG_UND_RECYCLING = "entsorgung_und_recycling",
    LANDWIRTSCHAFT = "landwirtschaft",
    HANDEL = "handel",
    INDUSTRIE = "industrie",
    VERKEHR_UND_LOGISTIK = "verkehr_und_logistik",
    AUTO_UND_WERKSTATT = "auto_und_werkstatt",
    BAU_UND_HANDWERK = "bau_und_handwerk",
}

//Newsletter topics: Value, Label
const availableTopics: [NewsletterTopic, string][] = [
    [NewsletterTopic.PRIVATKUNDE, "Privatkunde"],
    [NewsletterTopic.ENERGIEVERSORGUNG, "Energieversorgung"],
    [NewsletterTopic.ENTSORGUNG_UND_RECYCLING, "Entsorgung & Recycling"],
    [NewsletterTopic.LANDWIRTSCHAFT, "Landwirtschaft"],
    [NewsletterTopic.HANDEL, "Handel"],
    [NewsletterTopic.INDUSTRIE, "Industrie"],
    [NewsletterTopic.VERKEHR_UND_LOGISTIK, "Verkehr & Logistik"],
    [NewsletterTopic.AUTO_UND_WERKSTATT, "Auto & Werkstatt"],
    [NewsletterTopic.BAU_UND_HANDWERK, "Bau & Handwerk"],
];

type NewsletterTopicsProps = {
    selectedTopics: ReadonlySet<NewsletterTopic>;
    onTopicsChange: (selectedTopics: ReadonlySet<NewsletterTopic>) => void;
};

const NewsletterTopicPicker: React.FC<NewsletterTopicsProps> = ({
    selectedTopics,
    onTopicsChange,
}) => {
    // The ColMulti select Set needs to implement some kind of sorting algorithm otherwise it won't work.
    // Provide the array of the default topics and use the index in the array.
    const topicOrd: Ord.Ord<NewsletterTopic> = useMemo(() => {
        return {
            equals: (a, b) => a === b,
            compare: (a, b) =>
                Math.sign(
                    availableTopics.findIndex((topic) => topic[0] === a) -
                        availableTopics.findIndex((topic) => topic[0] === b)
                ) as any,
        };
    }, [availableTopics]);

    const handleTopicToggle = (newSelection: ReadonlySet<NewsletterTopic>) => {
        onTopicsChange(newSelection);
    };

    //Configuration for the ColMultiSelect
    const newsletterTopicOptions: ColMultiSelectProps<NewsletterTopic> = {
        id: "newsletter-topics-selector",
        colSize: 4,
        mobileColSize: 12,
        value: selectedTopics,
        values: new Map(availableTopics),
        gtag: "newsletter-topics-selection",
        centeredColumns: false,
        noInput: true,
        Ord: topicOrd,
        isNarrow: true,
        Option: Chip,
        onChange: handleTopicToggle,
        mkOptionValue: (topicName: NewsletterTopic) => `topic-${topicName}`,
    };

    return (
        <ColMultiSelect<NewsletterTopic>
            optionClassName={styles.multiSelectOptionButton}
            {...newsletterTopicOptions}
        />
    );
};

export default NewsletterTopicPicker;
