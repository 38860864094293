import React from "react";

import { useIsSSR } from "react-aria";
import { createPortal } from "react-dom";

const id = "Modal-PortalAnchor";

const ModalPortal = ({ children }) => {
    const isSSR = useIsSSR();

    if (isSSR) return null;

    const target = document.getElementById(id);

    return target ? createPortal(children, target) : null;
};
export default ModalPortal;

export const ModalPortalAnchor = (props) => <div {...{ ...props, id }} />;
