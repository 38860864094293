import React from "react";

import styled from "@emotion/styled";

import { iSvgIconProps } from "../types";

const PlusIcon = ({ color = "#f0f0f0", hoverColor = color }: iSvgIconProps) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG
            id="Ebene_2"
            data-name="Ebene 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <defs></defs>
            <polygon
                className="cls-1"
                points="20.74 10.15 14.32 10.15 14.32 3.48 10.32 3.48 10.32 10.15 3.74 10.15 3.74 14.15 10.32 14.15 10.32 20.65 14.32 20.65 14.32 14.15 20.74 14.15 20.74 10.15"
            />
        </SVG>
    );
};

export default PlusIcon;
