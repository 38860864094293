import React from "react";

import styled from "@emotion/styled";

const CloseIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M22.01575,4h-13.2A2.98208,2.98208,0,0,0,3.18427,4h-1.2a1.00013,1.00013,0,0,0,0,2H3.18433a2.982,2.982,0,0,0,5.6314,0h13.2a1.00013,1.00013,0,0,0,0-2ZM6,6a1.00007,1.00007,0,1,1,.98535-1.07245c.001.025.01007.04736.01007.07263,0,.02484-.009.04688-.00989.07141A.99362.99362,0,0,1,6,6Z" />
            <path d="M22.01575,11H20.81567a2.982,2.982,0,0,0-5.63134,0H1.98425a1.00013,1.00013,0,0,0,0,2h13.2a2.98213,2.98213,0,0,0,5.63153,0h1.2a1.00013,1.00013,0,0,0,0-2ZM18,12.99982a.99985.99985,0,1,1,1-.99958A1.00063,1.00063,0,0,1,18,12.99982Z" />
            <path d="M22.01575,17h-10.2a2.98208,2.98208,0,0,0-5.63146,0h-4.2a1.00013,1.00013,0,0,0,0,2H6.18433a2.982,2.982,0,0,0,5.6314,0h10.2a1.00013,1.00013,0,0,0,0-2ZM9,19a1,1,0,1,1,1-1A1.00067,1.00067,0,0,1,9,19Z" />
        </SVG>
    );
};

export default CloseIcon;
