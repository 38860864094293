import dayjs from "dayjs";

export type KnownValidator =
    // Validation for empty or alphanumeric
    | "EmptyOrAlphanumeric"
    | "CounterNumber"
    // Validation for alphanumeric
    | "Alphanumeric"
    // Validation for alphas
    | "Alpha"
    // Validation for email
    | "Email"
    // Vlaidation for phone
    | "Phone"
    // Validation for street
    | "Street"
    // Validation for city
    | "City"
    // Validation for zipcode
    | "Zipcode"
    // Validation for integer
    | "Integer"
    // IBAN Validator
    | "Iban"

    // BIC Validator
    | "Bic"
    // Date Validator
    | "Date"
    // Validation for company
    | "Company"
    // Validation for message
    | "Message"
    // Validation for HouseNumber
    | "HouseNumber"
    | "VatId"
    | "VatNumber"
    | "ActivationCode"
    | "CustomerNumber"
    | "LPGCustomerNumber"
    | "CounterNumber"
    | "CounterLevel"
    | "CounterLevelInt"
    | "Pin"
    | "Voucher"
    | "DecimalNumber";

export type BaseValidator = Readonly<Record<`reg${KnownValidator}`, RegExp>> &
    Record<`isValid${KnownValidator}`, (value: string) => boolean>;

export enum Validation {
    name = "name",
    street = "street",
    company = "company",
    city = "city",
    phone = "phone",
    fax = "fax",
    email = "email",
    zipcode = "zipcode",
    iban = "iban",
    bic = "bic",
    "hel:amount" = "hel:amount",
    "pel:pelLooseAmount" = "pel:pelLooseAmount",
    consumption = "consumption",
    counterNumber = "counterNumber",
    hoyerGasElectricityCustomerNumber = "hoyerGasElectricityCustomerNumber",
    "hel:up" = "hel:up",
    date = "date",
    birthdate = "birthdate",
    subject = "subject",
    message = "message",
    description = "description",
    "promotion-code" = "promotion-code",
    "house_number" = "house_number",
    "houseNumber" = "houseNumber",
    vatNumber = "vatNumber",
    vatId = "vatId",
    integer = "integer",
    activationCode = "activationCode",
    customerNumber = "customerNumber",
    lPGCustomerNumber = "lPGCustomerNumber",
    counterLevel = "counterLevel",
    counterLevelInt = "counterLevelInt",
    pin = "pin",
    counter = "counter",
    address = "address",
    isPrivacyPolicyAccepted = "isPrivacyPolicyAccepted",
    job = "job",
    isTrue = "isTrue",
    alphanumeric = "alphanumeric",
    numeric = "numeric",
    addressObject = "addressObject",
    size = "size",
    voucher = "voucher",
    decimalNumber = "decimalNumber",
}
export type ValidationStr = `${Validation}`;

class Validator implements BaseValidator {
    // Validation for empty or alphanumeric
    readonly regEmptyOrAlphanumeric = /^$|[A-Za-z0-9ßöäüÖÄÜ\-\s]{2,}$/;
    readonly regCounter = /^$|[A-Za-z0-9ßöäüÖÄÜ\-._\s]{2,}$/;
    // Validation for alphanumeric
    readonly regAlphanumeric = /^[A-Za-z0-9ßöäüÖÄÜ\-\s]{2,}$/;
    // Validation for alphas
    readonly regAlpha = /^[A-Za-zßöäüÖÄÜ\-\s]{2,}$/;
    // Validation for email
    readonly regEmail =
        /^(?:[\wß!#$%&;'*+\-/=?^`{|}~]+\.)*[\wß!#$%&;'*+\-/=?^`{|}~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/i;
    // Vlaidation for phone
    readonly regPhone =
        /^(?:([+][0-9]{1,2})+[ .-]*)?([(]{1}[0-9]{1,6}[)])?([0-9 .-/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/;
    // Validation for street
    readonly regStreet = /^[A-Za-z0-9ßöäüÖÄÜ.\s-]{2,}$/;
    // Validation for city
    readonly regCity = /^[A-Za-z0-9ßöäüÖÄÜ.\s-]{2,}$/;
    // Validation for zipcode
    readonly regZipcode = /^[0-9]{5}$/;
    // Validation for integer
    readonly regInteger = /^[0-9]+$/;
    // IBAN Validator
    readonly regIban =
        /^[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?:[ ]?[0-9]{1,2})?$/;

    // BIC Validator
    readonly regBic =
        /([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/g;
    // Date Validator
    readonly regDate = /(\d{2})\.(\d{2})\.(\d{4})/;
    // Validation for company
    readonly regCompany = /^[A-Za-z0-9ßöäüÖÄÜ&.,\s-]{2,}$/;
    // Validation for message
    readonly regMessage = /^[A-Za-z0-9ßöäüÖÄÜ&.?!€$()%"':;,\s-]{2,}$/;
    // Validation for HouseNumber
    readonly regHouseNumber = /^(?!0+)[0-9a-zA-Z \- \\ /]{1,10}$/;
    readonly regVatId = /^(DE)?[0-9]{9}$/;
    readonly regVatNumber = /^[/0-9]{10,13}$/;
    readonly regActivationCode = /^[A-Za-z0-9]{6}$/;
    readonly regCustomerNumber = /^[-a-zA-Z0-9]{1,9}$/;
    readonly regLPGCustomerNumber =
        /^([-a-zA-Z0-9]{1,9})([/]+[-a-zA-Z0-9]{1,9})?$/;
    readonly regCounterNumber = /^[a-zA-Z0-9]{1,15}$/;
    readonly regCounterLevel = /^([0-9]{1,5})(?:,[0-9]{1,3})?$/;
    readonly regCounterLevelInt = /^[0-9]{1,10}$/;
    readonly regPin = /^[0-9]{4}$/;
    readonly regVoucher = /^[a-zA-Z0-9]{8,12}$/;
    readonly regDecimalNumber = /^-?\d+(,\d+)*(\.\d+(e\d+)?)?$/;

    /**
     * Validate the given value for alpha
     */
    isValidAlpha(value: string) {
        return this.regAlpha.test(value);
    }

    /**
     * Validate the given value for alphanumeric
     */
    isValidAlphanumeric(value: string) {
        return this.regAlphanumeric.test(value);
    }

    /**
     * Validate the given value for empty or alphanumeric
     */
    isValidEmptyOrAlphanumeric(value: string) {
        return this.regEmptyOrAlphanumeric.test(value);
    }

    /**
     * Validate the given value for empty or alphanumeric
     */
    isValidEmptyAlphanumeric(value: string) {
        return this.isValidEmptyOrAlphanumeric(value);
    }

    /**
     * Validate the given email.
     */
    isValidEmail(value: string) {
        return this.regEmail.test(value);
    }

    /**
     * Validate the given phone.
     *
     * @param {*} value
     */
    isValidPhone(value: string): boolean {
        if (this.regPhone.test(value)) {
            // We should have at least have 5 numbers.
            return this.hasMinimumOfNumbers(value, 5);
        }
        return false;
    }

    /**
     * Validate the given street.
     *
     * @param {*} value
     */
    isValidStreet(value: string): boolean {
        return this.regStreet.test(value);
    }

    /**
     * Validate the given street.
     *
     * @param {*} value
     */
    isValidCity(value: string): boolean {
        return this.regCity.test(value);
    }

    /**
     * Validate the given number
     */
    isValidInteger(value: string): boolean {
        return this.regInteger.test(value);
    }

    /**
     * Validate the given zipcode.
     *
     * @param {*} value
     */
    isValidZipcode(value: string): boolean {
        return this.regZipcode.test(value);
    }

    /**
     *
     * Validate IBAN
     * @returns {boolean}
     */
    isValidIban(value: string): boolean {
        return this.regIban.test(value);
    }

    /**
     *
     * Validate BIC
     * @returns {boolean}
     */
    isValidBic(value: string): boolean {
        return this.regBic.test(value);
    }

    /**
     * Valid Zählernummer( Counter number)
     */
    isValidCounterNumber(value: string): boolean {
        if (this.regCounterNumber.test(value)) {
            // We should have at least have 5 numbers.
            return this.hasMinimumOfNumbers(value, 5);
        }
        return false;
    }

    /**
     * is Valid Date
     */
    isValidDate(value: string): boolean {
        return this.regDate.test(value);
    }

    /**
     * Validate company
     */
    isValidCompany(value: string): boolean {
        return this.regCompany.test(value);
    }

    /**
     * Validate message
     */
    isValidMessage(value: string): boolean {
        return this.regMessage.test(value);
    }

    /**
     * Validate house number.
     */
    isValidHouseNumber(value: string): boolean {
        return this.regHouseNumber.test(value);
    }

    /**
     * Is valid vat number.
     */
    isValidVatNumber(value: string): boolean {
        return this.regVatNumber.test(value);
    }

    /**
     * Is valid vat id
     */
    isValidVatId(value: string): boolean {
        return this.regVatId.test(value);
    }

    /**
     * Is valid activation code.
     */
    isValidActivationCode(value: string): boolean {
        return this.regActivationCode.test(value);
    }

    isValidCustomerNumber(value: string): boolean {
        return this.regCustomerNumber.test(value);
    }

    isValidLPGCustomerNumber(value: string): boolean {
        return this.regLPGCustomerNumber.test(value);
    }

    isValidCounterLevel(value: string): boolean {
        return this.regCounterLevel.test(value);
    }

    isValidCounterLevelInt(value: string): boolean {
        return this.regCounterLevelInt.test(value);
    }

    isValidPin(value: string): boolean {
        return this.regPin.test(value);
    }

    isValidCounter(value: string): boolean {
        return this.regCounterNumber.test(value);
    }

    isValidAddress(value: string): boolean {
        return value.length > 4;
    }

    /**
     * Checks if the given value has at least the number numbers of given count.
     */
    hasMinimumOfNumbers(value: string, count: number): boolean {
        return value.replace(/^\D+/g, "").length >= count;
    }

    isValidAddressObject(value: string): boolean {
        return this.regMessage.test(value);
    }

    isValidSize(value: string): boolean {
        return this.regMessage.test(value);
    }

    /**
     * Check if the given value is valid for the given validation.
     */
    isValid(validation: Validation | ValidationStr, value: string) {
        return this.getValidator(validation, value)();
    }

    isValidVoucher(value: string): boolean {
        return this.regVoucher.test(value);
    }

    isTrue(value: string): string {
        return value;
    }

    isValidDecimalNumber(value: string): boolean {
        return this.regDecimalNumber.test(value);
    }
    isDecimalNumber(value: string): boolean {
        return this.isValidDecimalNumber(value);
    }

    /**
     * Returns a function, which returns wether the given value is valid or not.
     */
    getValidator(
        validation: Validation | ValidationStr,
        value: string,
        required = false
    ): () => boolean {
        // If the value is empty and the field is not required.
        if (!required && value === "") {
            return () => true;
        }

        // If the value is empty and the field is required.
        if (required && value === "") {
            return () => false;
        }

        switch (validation as Validation) {
            case Validation.name:
                return () => this.isValidAlpha(value);
            case Validation.street:
                return () => this.isValidStreet(value);
            case Validation.company:
                return () => this.isValidCompany(value);
            case Validation.city:
                return () => this.isValidCity(value);
            case Validation.phone:
            case Validation.fax:
                return () => this.isValidPhone(value);
            case Validation.email:
                return () => this.isValidEmail(value);
            case Validation.zipcode:
                return () => this.isValidZipcode(value);
            case Validation.iban:
                return () => this.isValidIban(value);
            case Validation.bic:
                return () => this.isValidBic(value);
            case Validation["hel:amount"]:
                return () => {
                    if (
                        !this.isValidInteger(value) ||
                        parseInt(value) < 500 ||
                        parseInt(value) > 32000
                    ) {
                        return false;
                    }
                    return true;
                };
            case Validation["pel:pelLooseAmount"]:
                return () => {
                    if (
                        !this.isValidInteger(value) ||
                        parseInt(value) < 1000 ||
                        parseInt(value) > 25000
                    ) {
                        return false;
                    }
                    return true;
                };
            case Validation.consumption:
                return () => this.isValidInteger(value);
            case Validation.counterNumber:
                return () => this.isValidCounterNumber(value);
            case Validation.hoyerGasElectricityCustomerNumber:
                return () => this.isValidCounterNumber(value);
            case Validation["hel:up"]:
                return () => {
                    if (
                        !this.isValidInteger(value) ||
                        parseInt(value) < 1 ||
                        parseInt(value) > 10
                    ) {
                        return false;
                    }
                    return true;
                };
            case Validation.date:
                return () => this.isValidDate(value);
            case Validation.birthdate:
                return () => {
                    if (!this.isValidDate(value)) {
                        return false;
                    }

                    return dayjs()
                        .subtract(18, "years")
                        .endOf("day")
                        .isAfter(dayjs(value, "DD.MM.YYYY").startOf("day"));
                };
            case Validation.subject:
                return () => this.isValidAlphanumeric(value);
            case Validation.message:
                return () => {
                    // We want to allow multiple lines in the message field.
                    // So we treat every line as a single one.
                    const parts = value.split("\n");
                    let valid = true;
                    parts.forEach((item) => {
                        if (item !== "" && !this.isValidMessage(item)) {
                            valid = false;
                        }
                    });
                    return valid;
                };
            case Validation.description:
                return () => this.isValidMessage(value);
            case Validation["promotion-code"]:
                return () => this.isValidAlphanumeric(value);
            case Validation.house_number || Validation.houseNumber:
                return () => this.isValidHouseNumber(value);
            case Validation.vatNumber:
                return () => this.isValidVatNumber(value);
            case Validation.vatId:
                return () => this.isValidVatId(value);
            case Validation.integer:
                return () => this.isValidInteger(value);
            case Validation.activationCode:
                return () => this.isValidActivationCode(value);
            case Validation.customerNumber:
                return () => this.isValidCustomerNumber(value);
            case Validation.lPGCustomerNumber:
                return () => this.isValidLPGCustomerNumber(value);
            case Validation.counterLevel:
                return () => this.isValidCounterLevel(value);
            case Validation.counterLevelInt:
                return () => this.isValidCounterLevelInt(value);
            case Validation.pin:
                return () => this.isValidPin(value);
            case Validation.counter:
                return () => this.isValidCounter(value);
            case Validation.address:
                return () => this.isValidAddress(value);
            case Validation.isPrivacyPolicyAccepted:
                return () => value === true;
            case Validation.job:
                return () => this.isValidAlpha(value);
            case Validation.isTrue:
                return () => this.isTrue(value);
            case Validation.alphanumeric:
                return () => this.isValidAlphanumeric(value);
            case Validation.numeric:
                return () => !isNaN(value);
            case Validation.addressObject:
                return () => this.isValidAddressObject(value);
            case Validation.size:
                return () => this.isValidSize(value);
            case Validation.voucher:
                return () => this.isValidVoucher(value);
            case Validation.decimalNumber:
                return () => this.isDecimalNumber(value);
            default:
                return () => {
                    console.log(
                        `no matching validator found for: ${validation}`
                    );
                    return () => true;
                };
        }
    }
}
export default Validator;
