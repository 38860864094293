import React, { createContext, useContext, useEffect, useMemo } from "react";

import Helmet from "react-helmet";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";
import createStore from "zustand";

export const blur = ({ eles, extraFilter = "" }) => css`
    filter: blur(0) ${extraFilter};
    transition: filter 500ms;

    ${eles.map((ele) => `.blur.blur--${ele} &`).join(", ")} {
        z-index: 49;
        filter: blur(4px) ${extraFilter};
    }
`;

const eles = new Set(["Nav", "Page", "BottomButtons", "Footer", "ScrollToTop"]);
const relativeComplement = (as, bs) => {
    const as_ = new Set(as);
    bs.forEach((b) => as_.delete(b));
    return as_;
};

const Blur = ({ blur = [], except = [] }) => {
    const blurred = useMemo(
        () => [
            ...relativeComplement(blur ? new Set(blur) : eles, new Set(except)),
        ],
        [blur, except]
    );

    return (
        <Helmet
            htmlAttributes={{
                class: classNames([
                    "blur",
                    ...blurred.map((n) => `blur--${n}`),
                ]),
            }}
        />
    );
};
export default Blur;

const useGlobalBlurStore = createStore((set, get) => ({
    knownEles: new Set([]),
    addEles: (eles) =>
        set({
            knownEles: new Set([...get().knownEles, ...eles]),
        }),
}));

const BlurredContainer = styled.div``;

const BlurredContext = createContext({ eles: [] });
BlurredContext.displayName = "BlurredContext";
export const Blurred = ({ as = "div", eles, ...props }) => {
    const { eles: ctx } = useContext(BlurredContext);
    const newEles = useMemo(() => [...ctx, ...eles], [ctx, eles]);
    const { addEles } = useGlobalBlurStore(({ addEles }) => ({ addEles }));

    useEffect(() => addEles(eles), [addEles, eles]);

    return (
        <BlurredContext.Provider value={{ eles: newEles }}>
            <BlurredContainer css={blur({ eles })} {...{ as, ...props }} />
        </BlurredContext.Provider>
    );
};

export const BlurOther = ({ blur = [], except = [] }) => {
    const { eles } = useContext(BlurredContext);
    const { knownEles } = useGlobalBlurStore(({ knownEles }) => ({
        knownEles,
    }));

    const except_ = useMemo(
        () => relativeComplement([...except, ...eles], blur),
        [except, eles, blur]
    );

    return <Blur blur={knownEles} except={except_} />;
};
