import { subWeeks } from "date-fns";

import { storageLocal } from "./StorageHandler";

export class NewsletterPopupManager {
    public static readonly CLOSED_KEY = "newsletter-closed-at";
    public static readonly REGISTERED_KEY = "newsletter-registered-at";
    protected static readonly blacklistUrls = [
        "/impressum/",
        "/datenschutz/",
        "/sicherheitshinweise/",
        "/agb/",
        "/widerrufsbelehrung/",
        "/codeofconduct/",
        "/marine/agb/",
        "/marine-trading/agb/",
        "/thg/avb/",
        "/thg/datenschutz/",
    ];

    private static getTwoWeeksAgoTimestamp(): number {
        return subWeeks(new Date(), 2).getTime();
    }

    private static isUrlBlacklisted(location: { pathname: string }): boolean {
        const currentPath = location.pathname;
        return this.blacklistUrls.some((url) => currentPath === url);
    }

    /**
     * Decides if the user shall see the popup:
     * - Don't show if the user has a successfully registered timestamp.
     * - Don't show if the url is blacklisted from the popup.
     * - Show when the user has not yet seen or closed the popup.
     * - Don't show the popup if the user has closed it in the past two weeks.
     */
    public static shouldShowPopup(location: { pathname: string }): boolean {
        const registeredAt = storageLocal.getInt(this.REGISTERED_KEY);
        if (registeredAt || this.isUrlBlacklisted(location)) {
            return false;
        }

        const closedAt = storageLocal.getInt(this.CLOSED_KEY);
        if (!closedAt) {
            return true;
        }

        return closedAt < this.getTwoWeeksAgoTimestamp();
    }

    /**
     * Set a timestamp when the user closes the popup
     */
    public static markAsClosed(): void {
        storageLocal.set(this.CLOSED_KEY, Date.now().toString());
    }

    /**
     * Set a timestamp if a user has successfully registered for the newsletter.
     */
    public static markAsRegistered(): void {
        storageLocal.set(this.REGISTERED_KEY, Date.now().toString());
    }
}
