import create from "zustand";

import pushToAnalytics from "../../../utils/analytics";
import { NewsletterPopupManager } from "../../../utils/NewsletterPopupManager";
import { storageLocal } from "../../../utils/StorageHandler";

interface NewsletterModalStore {
    isNewsletterModalOpen: boolean;
    openNewsletterModal: () => void;
    closeNewsletterModal: () => void;
}

const useNewsletterModalStore = create<NewsletterModalStore>((set) => ({
    isNewsletterModalOpen: false,
    openNewsletterModal: () => {
        pushToAnalytics("PopupNewsletter:start", []);
        return set({ isNewsletterModalOpen: true });
    },
    closeNewsletterModal: () => {
        NewsletterPopupManager.markAsClosed();
        //Only track the cancel event if the user has not successfully submitted the popup yet.
        if (!storageLocal.get(NewsletterPopupManager.REGISTERED_KEY)) {
            pushToAnalytics("PopupNewsletter:cancel", []);
        }
        return set({ isNewsletterModalOpen: false });
    },
}));

export default useNewsletterModalStore;
