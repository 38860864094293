import create from "zustand";

import { sessionStorage } from "../../../utils/StorageHandler";

interface iOrderUserAttribution {
    referrer: string;
    parameter: string;
    setParameter: (location: Location) => void;
    setReferrer: () => void;
}

const useStore = create<iOrderUserAttribution>((set) => ({
    referrer: "none",
    parameter: "none",

    setParameter: (location) => {
        if (!sessionStorage.getItem("app:parameter") || location.search) {
            const params = location?.search ? location.search : "none";
            sessionStorage.setItem("app:parameter", params);
        }
    },
    setReferrer: () => {
        if (!sessionStorage.getItem("app:referrer")) {
            const referrer = document?.referrer ? document.referrer : "none";
            sessionStorage.setItem("app:referrer", referrer);
            set({ referrer: referrer });
        }
    },
}));

export default useStore;
