import create from "zustand";

import { errorAwareApiFetch } from "../../../apiBindings";
import hoyerCard from "../../../images/hoyer_card.jpg";
import { storageLocal as storage } from "../../../utils/StorageHandler";
import { getUrlParam } from "../../../utils/urlParams";

export type iProductGroup = {
    id: number;
    name: string;
    displayName: string;
    parentId: number;
    articles?: iProduct[];
    children?: iProductGroup[];
    createdAt: string;
    updatedAt: string;
};

export type iProduct = {
    articleNumber: string;
    name: string;
    description: string;
    listPrice: string;
    quantityUnit: string;
    packagingUnit: string;
    taxRate: string;
    additionalTexts: any;
    group?: iProductGroup;
    images?: iProductImage[];
    createdAt: string;
    updatedAt: string;
};

export type iProductImage = {
    id: number;
    articleNumber: string;
    path: string;
    url: string;
    filename: string;
    originalFilename: string;
    mime?: string;
    size?: number;
    rankOrder: number;
    createdAt: string;
    updatedAt: string;
};

export enum Day {
    "none" = "keine Angabe",
    "mon" = "Montag",
    "tue" = "Dienstag",
    "wed" = "Mittwoch",
    "thu" = "Donnerstag",
    "fri" = "Freitag",
}

export enum Time {
    "none" = "keine Angabe",
    "7to9" = "7 - 9 Uhr",
    "7to12" = "7 - 12 Uhr",
    "12to16" = "12 - 16 Uhr",
    "16to18" = "16 - 18 Uhr",
}

type iPaginationLinks = {
    first: string;
    last: string;
    next: string | null;
    prev: string | null;
};

type iPaginationMeta = {
    current_page: number;
    last_page: number;
    from: number;
    to: number;
    total: number;
    per_page: number;
    links: [
        {
            url: string;
            active: boolean;
            label: string;
        }
    ];
};

export type iPaginateEnvelope<T> = {
    data: T;
    links: iPaginationLinks;
    meta: iPaginationMeta;
};

interface iTechnicCatalogStore {
    loading: boolean;
    productGroups: iProductGroup[] | [];
    products: iProduct[] | [];
    page: number;
    group: string;
    search: string;
    selectedGroupTree: number[] | null;
    paginationMeta: iPaginationMeta | null;
    paginationLinks: iPaginationLinks | null;
    savedProducts: iProduct[] | [];
    selectedProductGroupId: number | null;
    getData: () => void;
    setPage: (page: number) => void;
    setGroup: (group: string) => void;
    setSearch: (search: string) => void;
    setProductToSaved: (product: iProduct) => void;
    deleteProductFromSaved: (product: iProduct) => void;
    setSelectedProductGroupId: (value: number | null) => void;
    clearSavedProducts: () => void;
    getMainImage: (product: iProduct) => string | null;
}

type iApiResponse = {
    productGroups: Array<iProductGroup>;
    products: iPaginateEnvelope<Array<iProduct>>;
};

const findParents = (
    groups: iProductGroup[],
    targetId: number,
    parents: number[] = []
): null | number[] => {
    for (const product of groups) {
        if (product.id === targetId) {
            return parents.concat(product.id);
        }

        if (product.children && product.children.length > 0) {
            const result = findParents(
                product.children,
                targetId,
                parents.concat(product.id)
            );
            if (result) {
                return result;
            }
        }
    }

    return null;
};

export const getSelectedGroupTree = (
    groups: iProductGroup[],
    selectedProductGroupId: number | null
) => {
    if (!selectedProductGroupId) {
        return [];
    }
    const parents = findParents(groups, selectedProductGroupId);
    if (parents === null) {
        return [selectedProductGroupId];
    }
    return parents;
};

export const getGroupName = (
    productGroups: iProductGroup[],
    selectedProductGroupId: number | null
) => {
    let group: iProductGroup | null = null;
    let groupName = "";
    const selectedTree = getSelectedGroupTree(
        productGroups,
        selectedProductGroupId
    );

    selectedTree.forEach((id) => {
        group =
            (group === null ? productGroups : group.children ?? []).find(
                (child) => child.id === id
            ) ?? null;
        if (group) {
            groupName = `${groupName}${groupName !== "" ? " - " : ""}${
                group.displayName
            }`;
        }
    });
    return groupName;
};

const pageParam = getUrlParam("page");
const groupParam = getUrlParam("group");
const searchParam = getUrlParam("search");
const page = pageParam !== null ? parseInt(pageParam) : 1;

const useStore = create<iTechnicCatalogStore>((set, get) => ({
    loading: false,
    productGroups: [],
    products: [],
    page: page,
    group: groupParam ?? "",
    search: searchParam ?? "",
    selectedGroupTree: null,
    paginationMeta: null,
    paginationLinks: null,
    savedProducts: storage.getJson("technic:saved-products", []),
    selectedProductGroupId: null,
    selectedSubProductGroup: null,

    getData: () => {
        set({ loading: true });
        const data = get();
        const page = data.page;
        const search = data.search;
        let group = data.group;
        errorAwareApiFetch<iApiResponse>(
            `/technic-shop?page=${page}&group=${group}&search=${search}`,
            {
                method: "GET",
            }
        )
            .then((response) => {
                if (search === "" && group === "") {
                    group = response.productGroups[0].id.toString();
                }
                const tree = getSelectedGroupTree(
                    response.productGroups,
                    Number(group)
                );
                set({
                    productGroups: response.productGroups,
                    products: response.products.data,
                    paginationMeta: response.products.meta,
                    paginationLinks: response.products.links,
                    selectedProductGroupId: Number(group),
                    selectedGroupTree: tree,
                });
            })
            .catch((errors) => {
                console.log(errors);
            })
            .finally(() => set({ loading: false }));
    },

    setPage: (page) => {
        set({ page });
    },

    setGroup: (group) => {
        set({ group });
    },

    setSearch: (search) => {
        set({ search });
    },

    setSelectedProductGroupId: (value) => {
        set({
            selectedProductGroupId: value,
            group: value ? value.toString() : "",
        });
    },

    setProductToSaved: (product) => {
        const tempProducts: iProduct[] = get().savedProducts;
        const isProductAlreadySaved = tempProducts.some(
            (savedProduct) =>
                savedProduct.articleNumber === product.articleNumber
        );
        if (!isProductAlreadySaved) {
            tempProducts.push(product);
            storage.setJson("technic:saved-products", tempProducts);
            set({ savedProducts: tempProducts });
        }
    },

    deleteProductFromSaved: (product) => {
        const tempProducts = get().savedProducts.filter(
            (tempProduct) => tempProduct.articleNumber !== product.articleNumber
        );
        storage.setJson("technic:saved-products", tempProducts);
        set({ savedProducts: tempProducts });
    },

    clearSavedProducts: () => {
        storage.setJson("technic:saved-products", []);
        set({ savedProducts: [] });
    },

    getMainImage: (product) => {
        if (product?.images && product.images.length > 0) {
            return product.images[0].url;
        }
        return null;
    },
}));

export default useStore;
