import React from "react";

import styled from "@emotion/styled";

const DeleteIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
                d="M8.176,5.472h0a.90132.90132,0,0,1,.90132.90132V9.97865A.90133.90133,0,0,1,8.176,10.88h0a.90132.90132,0,0,1-.90132-.90132V6.37334A.90132.90132,0,0,1,8.176,5.472Z"
                transform="translate(-3.38661 8.17598) rotate(-44.9999)"
            />
            <path
                d="M4.78939,11.09867H19.21062A.90132.90132,0,0,1,20.11194,12v0a.90133.90133,0,0,1-.90133.90133H4.78938A.90132.90132,0,0,1,3.88806,12v0a.90133.90133,0,0,1,.90133-.90133Z"
                transform="translate(-4.97056 12.00015) rotate(-45.00049)"
            />
            <path
                d="M15.824,13.12h0a.90132.90132,0,0,1,.90132.90132v3.60531a.90133.90133,0,0,1-.90133.90133h0a.90132.90132,0,0,1-.90132-.90132V14.02135A.90133.90133,0,0,1,15.824,13.12Z"
                transform="translate(-6.55452 15.82404) rotate(-45.00008)"
            />
            <path d="M12,23.5A11.5,11.5,0,1,1,23.5,12,11.51341,11.51341,0,0,1,12,23.5Zm0-22A10.5,10.5,0,1,0,22.5,12,10.5118,10.5118,0,0,0,12,1.5Z" />
        </SVG>
    );
};

export default DeleteIcon;
