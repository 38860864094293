import React from "react";

import styled from "@emotion/styled";

const BurgermenueIcon = ({ color = "#f0f0f0", hoverColor = color }) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M13.5,3.5h8A1.5,1.5,0,0,1,23,5V5a1.5,1.5,0,0,1-1.5,1.5h-8A1.5,1.5,0,0,1,12,5V5a1.5,1.5,0,0,1,1.5-1.5Z" />
            <path d="M8.5,10.5h13A1.5,1.5,0,0,1,23,12v0a1.5,1.5,0,0,1-1.5,1.5H8.5A1.5,1.5,0,0,1,7,12v0a1.5,1.5,0,0,1,1.5-1.5Z" />
            <path d="M2.5,17.5h19A1.5,1.5,0,0,1,23,19v0a1.5,1.5,0,0,1-1.5,1.5H2.5A1.5,1.5,0,0,1,1,19v0a1.5,1.5,0,0,1,1.5-1.5Z" />
        </SVG>
    );
};

export default BurgermenueIcon;
