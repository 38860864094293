import React from "react";

import styled from "@emotion/styled";

const LocationIcon = ({
    color = "#f0f0f0",
    hoverColor = color,
    innerColor = "white",
}) => {
    const SVG = styled.svg`
        fill: ${color};

        &:hover {
            fill: ${hoverColor};
        }
    `;
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M8.55945,18.20587a7.10353,7.10353,0,0,1-2.825-2.8797.98214.98214,0,1,0-1.73487.92121,9.06738,9.06738,0,0,0,3.60413,3.67614.98281.98281,0,1,0,.95569-1.71765Z" />
            <path d="M22.0174,10.99951H21.01A9.07624,9.07624,0,0,0,13,2.99475V1.9826a1.00015,1.00015,0,0,0-2,0v1.014A9.07593,9.07593,0,0,0,3.00232,11.001l-1.01972.00024a.9999.9999,0,0,0,0,1.99951l1.9441-.001a.98746.98746,0,0,0,.97711-.97217c.0003-.00965.00549-.01764.00549-.02735a7.097,7.097,0,1,1,7.09693,7.09711.97876.97876,0,0,0-.90949.62.958.958,0,0,0-.09051.4v1.9a1.00015,1.00015,0,0,0,2,0V21.00421a9.0759,9.0759,0,0,0,8.004-8.0047H22.0174a1.00016,1.00016,0,0,0,0-2Z" />
            <circle cx="12" cy="12" r="3" />
        </SVG>
    );
};

export default LocationIcon;
