import { DependencyList, useEffect } from "react";

export const pushToDataLayer = (
    eventName: string,
    payload: Record<string, any>,
) => {
    pushToGoogleTagManager(eventName, payload);
    pushToMatomo(eventName, payload);
};

export const pushToGoogleTagManager = (
    eventName: string,
    payload: Record<string, any>,
) => {
    (window as any).dataLayer.push({
        event: eventName,
        ...payload,
    });
};

export type MatomoEvent = { event: string; [k: string]: any };
declare global {
    interface Window {
        _mtm: MatomoEvent[];
    }
}
if (typeof window !== "undefined") {
    window._mtm ??= [];
}
export const pushToMatomo = (
    eventName: MatomoEvent["event"],
    payload: Omit<MatomoEvent, "event">,
) => {
    window._mtm.push({
        event: eventName,
        ...payload,
    });
};

const pushToAnalytics = pushToDataLayer;

export const usePushToDataLayer = (
    ...[eventName, payload, dependencies]:
        | Parameters<typeof pushToDataLayer>
        | [...Parameters<typeof pushToDataLayer>, DependencyList]
) =>
    useEffect(
        () => pushToDataLayer(eventName, payload),
        [eventName, payload, ...(dependencies ?? [])],
    );

export default pushToAnalytics;
